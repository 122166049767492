import React, { useState, useEffect } from "react";
import Index from "../Index";
import PropTypes from "prop-types";

import {
  GetGraphTypeAction,
  GetGraphStyleAction,
  setTimeFrame,
  setChartView,
} from "../../redux/user/action";
import { MenuList, Slider } from "@mui/material";
import { manageGridSpacing, manageGridBorder } from "../../redux/user/action";
import { doGet } from "../../service/UserIndex";

const NotificationTab = (props) => {
  const {token} = Index.useSelector((state) => state.UserReducer);

  const [notification, setNotifications] = useState([]);
  const [page, setPage] = useState(1); // Current page
  const [pageSize, setPageSize] = useState(10); // Number of items per page

  const fetchData = async () => {
    try {
      const params = {
        page: 1,
        dataPerPage: 10,
        sortDate: -1
      }
      // const data = await doGet("/user/get-notifications", token);
      const response = await Index.DataService(token).get(Index.Api.user.getNotifications, {params});
      setNotifications(response?.data?.data?.notifications ?? []);
      props.setIsNotificationData(!props.isNotificationData)
    } catch (error) {
    }
  };

  useEffect(() => {
    fetchData();
  }, [token]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const navigate = Index.useNavigate()

  const viewAll = () => {
    navigate('/user/notifications')
    props.setNotificationModalOpen(false)
  }

  return (
    <>
      <Index.Box className="tab-content-main">
        <Index.Box className="noti-popup-box">
          {!!notification?.length ? notification?.map((notificationObj) => {
            const status =
              notificationObj?.status?.toLowerCase() == "target"
                ? "targetAchived"
                : "stopLoss";
            return (
            <Index.Box className="single-message-box default-cursor" key={notificationObj.id}>
              <Index.Typography
                className={`notification-message-text ${status}`}
              >
                {`${notificationObj.message}`}
              </Index.Typography>
              <Index.Typography
                className="notification-time-text"
              >
                {`${Index.moment(notificationObj.createdAt).format("hh:mm:ss A  DD/MM/YYYY")}`}
              </Index.Typography>
            </Index.Box>
            )
          }) : (
            <Index.Box className="single-message-box default-cursor no-record">
              <Index.Typography
                className=""
              >
                No record found
              </Index.Typography>
            </Index.Box>
          )}
          <Index.Box className="noti-popup-btn">
            <Index.Button onClick={viewAll}>View all</Index.Button>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </>
  );
};

export default NotificationTab;

import React, { useEffect, useRef, useState } from "react";
import Index from "../../Index";
import {
  manageActiveChartHoverData,
  manageDownloadChartData,
} from "../../../redux/user/action";
import * as htmlToImage from "html-to-image";
import { jsPDF } from "jspdf";
import usePreviousDateCalculator from "../../../component/common/previous-date-calculator/usePreviousDateCalculator";
import Constants from "../../../component/common/constants";

const setDatefun = (data) => {
  let d = new Date(data);
  let h = d.getHours() + 5;
  let m = d.getMinutes() + 30;
  d.setHours(h, m, 0, 0);
  return d.getTime();
};

const OneColumn = (props) => {
  const [headMenu, setHeadMenu] = useState(false);
  const [currenChart, setCurrenChart] = useState("");

  const dispatch = Index.useDispatch();
  const {
    index,
    headerData,
    length,
    data,
    live,
    active,
    selectedId,
    loopIndex,
    activeCursor,
    fromDate,
    openDrawingChartModal,
    handleUpdateAnnotation,
    drawingChartData,
  } = props;

  const [chartData, setChartData] = useState([]);
  const [chartLiveData, setChartLiveData] = useState();
  const [tags, setTags] = useState("");
  const [previousClosePrice, setPreviousClosePrice] = useState(null);
  const abortControllers = useRef([]);
  const {
    gridView,
    token,
    timeFrame,
    chartView,
    chartDuration,
    graphType,
    pageSetting,
    gridBorder,
    gridColor,
    activeChartHover,
    downloadChartToken,
    gridSpacing,
    wishListData,
    currentPageRedux,
    theme,
  } = Index.useSelector((state) => state.UserReducer);

  const themeTitle = localStorage.getItem("default-theme");

  useEffect(() => {
    if(props?.socket){

      props.socket.emit("joinRoom", `token_${JSON.parse(headerData?.token)}`);
      
      props.socket.on(`token_${JSON.parse(headerData?.token)}`, (data) => {
        setChartLiveData(data);
      });
      
      return () => {
        props.socket.emit("leaveRoom", `token_${headerData?.token}`);
      };
    }
  }, [props?.socket, chartView]);

  function getGrid() {
    if (length < 3) {
      return {
        grid: 12 / gridView?.row || 1,
        height: gridView?.column,
        class: "main-chart-box",
      };
    } else if (length < 6) {
      return {
        grid: 12 / gridView?.row || 4,
        height: gridView?.column,
        class: "small-chart-box",
      };
    } else if (length < 9) {
      return {
        grid: 12 / gridView?.row || 6,
        height: gridView?.column,
        class: "hide-chart-header",
      };
    } else if (length >= 9) {
      return {
        grid: 12 / gridView?.row || 9,
        height: gridView?.column,
        class: "hide-chart-header",
      };
    }
    // if (gridView?.row == 1 && gridView?.column < 3) {
    //   return {
    //     grid: 12 / gridView?.row || 1,
    //     height: gridView?.column,
    //     class: "main-chart-box",
    //   };
    // } else if (
    //   gridView?.row == 1 &&
    //   gridView?.column > 2 &&
    //   gridView?.column < 6
    // ) {
    //   return {
    //     grid: 12 / gridView?.row || 1,
    //     height: gridView?.column,
    //     class: "small-chart-box",
    //   };
    // } else if (gridView?.row == 1 && gridView?.column > 5) {
    //   return {
    //     grid: 12 / gridView?.row || 1,
    //     height: gridView?.column,
    //     class: "hide-chart-header",
    //   };
    // } else if (gridView?.row <= 2 && gridView?.column === 1) {
    //   return {
    //     grid: 12 / gridView?.row || 1,
    //     height: gridView?.column,
    //     class: "main-chart-box",
    //   };
    // } else if (gridView?.row <= 2 && gridView?.column <= 2) {
    //   return {
    //     grid: 12 / gridView?.row || 4,
    //     height: gridView?.column,
    //     class: "small-chart-box",
    //   };
    // } else if (gridView?.row > 2 && gridView?.column > 1) {
    //   return {
    //     grid: 12 / gridView?.row || 6,
    //     height: gridView?.column,
    //     class: "hide-chart-header",
    //   };
    // } else if (gridView?.row > 2 && gridView?.column > 2) {
    //   return {
    //     grid: 12 / gridView?.row || 9,
    //     height: gridView?.column,
    //     class: "hide-chart-header",
    //   };
    // } else {
    //   return {
    //     grid: 12 / gridView?.row || 9,
    //     height: gridView?.column,
    //     class: "hide-chart-header",
    //   };
    // }
  }

  const previousDate = usePreviousDateCalculator(1);

  const getAllHistoryData = async () => {
    setChartData([]);
    const controller = new AbortController();
    abortControllers.current.push(controller);
    const signal = { signal: controller.signal };
    const intervalData = Constants.chartInterval[chartDuration[chartView]?.interval];
    const minutesToLess = intervalData?.value * 60000;

    const formData = {
      tokens: [{ token: props?.token, type: props?.marketType }],
      interval: intervalData?.interval,
      fromDate,
      toDate:
        Index.moment()
          .format("YYYY-MM-DD"),
      offset: intervalData?.offset,
    };
    const formDataIntraday = {
      tokens: [{ token: props?.token, type: props?.marketType }],
      interval: intervalData?.interval,
      fromDate:
        Index.moment()
          .format("YYYY-MM-DD"),
      toDate:
        Index.moment()
          .format("YYYY-MM-DD"),
      offset: intervalData?.offset,
      previousDate,
    };
    try {
      const apiCalls = live
        ? [
            Index.DataService(token).post(
              Index.Api.user.getIntradayData,
              formDataIntraday,
              signal
            ),
          ]
        : [
            Index.DataService(token).post(
              Index.Api.user.getIntradayData,
              formDataIntraday,
              signal
            ),
            Index.DataService(token).post(
              Index.Api.user.getAllHistoricalData,
              formData,
              signal
            ),
          ];
      const [intradayData, historicalData] = await Promise.all(apiCalls);

      const previousDateData = intradayData?.data?.data?.prevCloseData;
      if (previousDateData && previousDateData[props?.token]) {
        setPreviousClosePrice(previousDateData[props?.token]);
      }

      const combinedData = [
        ...(historicalData?.data?.data || []),
        ...(intradayData?.data?.data?.stockData || []),
      ]?.reduce((acc, item) => {
        const key = Object.keys(item)[0];
        if (!acc[key]) {
          acc[key] = item[key];
        } else {
          acc[key] = [...acc[key], ...item[key]];
        }
        return acc;
      }, {});

      const formattedCombinedData = Object.keys(combinedData)?.map((key) => ({
        [key]: combinedData[key],
      }));

      if (
        formattedCombinedData.length &&
        formattedCombinedData[0]?.[props?.token] &&
        formattedCombinedData[0][props?.token]?.length
      ) {
        const format = formattedCombinedData[0][props?.token].map((el) => {
          const timestamp =
            Index.moment(el[0]).startOf("minute").valueOf() - minutesToLess;
          return [timestamp, +el[1], +el[2], +el[3], +el[4], +el[4]];
        });
        setChartData(format);
      }
    } catch (error) {}
  };

  useEffect(() => {
    setTimeout(() => {
      getAllHistoryData();
    }, 1000);
  }, [index, chartDuration[chartView], chartView, graphType]);

  useEffect(() => {
    return () => {
      abortControllers.current.forEach((controller) => controller.abort());
    };
  }, [wishListData, chartDuration, chartView, currentPageRedux]);

  //Render Data

  const handleHoverActiveDeactive = (data) => {
    let index = activeChartHover?.some((ele) => +ele === +data?.token);
    let allToken;
    if (!index) {
      allToken = [...activeChartHover, data?.token];
    } else {
      const removeExistData = activeChartHover?.filter(
        (ele) => +ele !== +data?.token
      );
      allToken = removeExistData;
    }

    dispatch(manageActiveChartHoverData(allToken));
  };

  return (
    <Index.Grid
      item
      xs={getGrid()?.grid}
      // spacing={0}
      className={`grid-main ${
        gridView?.row > 3 ? "grid-small" : ""
      }`}
    >
      {/* <Index.Button onClick={()=>{
        dispatch(viewWishListData({token:headerData.token,type:1}))
        alert("intraday"+headerData.token)
      }}>Intraday</Index.Button>
      <Index.Button onClick={()=>{
        dispatch(viewWishListData({token:headerData.token,type:0}))
        alert("Positional"+headerData.token)
      }}>Positional</Index.Button> */}
      <Index.Box
        className={`anychart-download ${
          downloadChartToken.includes(headerData?.token) &&
          getGrid()?.class === "hide-chart-header"
            ? "small-chart-box"
            : getGrid()?.class
        } ${active ? "active-chart" : ""}`}
        id={`${selectedId !== null ? "active-chart-" + selectedId : ""}`}
        data={headerData?.token}
        sx={{
          border: `${active ? +gridBorder + "px solid " + gridColor : 0}`,
          // transform:
          //   pageSetting?.page > 1 &&
          //   `translateY(-${
          //     mainGridHeight * gridView?.column * pageSetting?.page -
          //     mainGridHeight * gridView?.column
          //   }px)`,
          // transition: "transform 0.5s ease",
        }}
        onDoubleClick={() => handleHoverActiveDeactive(headerData)}
      >
        <Index.Box
          className={`snapshot-chart-box ${
            downloadChartToken.includes(headerData?.token) &&
            "download-chart-header"
          }`}
          id={`print-${JSON.parse(headerData?.token)}`}
        >
          <Index.ChartHeader
            headerData={headerData}
            chartData={chartData}
            live={live}
            data={data}
            tags={tags}
            setTags={setTags}
            allTags={Constants.allTags}
            index={data?.token || index}
            currenChart={currenChart}
            downloadsType={Constants.downloadsType}
            activeChart={loopIndex === activeCursor}
            loopIndex={loopIndex}
            chartLiveData={chartLiveData}
            fromDate={props?.fromDate}
            previousClosePrice={previousClosePrice}
            socket={props?.socket}
            drawingChartData={drawingChartData}
          />
          <Index.Box className="chart-body">
            {graphType[theme][chartView] === 0 && (
              <Index.Box className="line-chart-body">
                <Index.CustomLineChart
                  shareData={chartData}
                  // shareData={headerData?.historicalStockData}
                  chartLiveData={chartLiveData}
                  index={headerData?.token?.token || headerData?.token}
                  DivideHeight={getGrid()?.height}
                  borderRadiusClass={"border-radius-graph-bottom"}
                  setCurrenChart={setCurrenChart}
                  token={data?.token || props?.token}
                  setDrawingChart={props?.setDrawingChart}
                  live={live}
                  activeChart={loopIndex === activeCursor}
                  activeCursor={activeCursor}
                  isMCXMarket ={headerData?.exch_seg === "MCX"}
                  openDrawingChartModal={openDrawingChartModal}
                  handleUpdateAnnotation={handleUpdateAnnotation}
                />
                {/* {getGrid()?.class === "hide-chart-header" && ( */}
                {getGrid()?.class === "hide-chart-header" && (
                  <HoverSection
                    chartLiveData={chartLiveData}
                    headMenu={headMenu}
                    setHeadMenu={setHeadMenu}
                    headerData={headerData}
                    chartData={chartData}
                    // chartData={headerData?.historicalStockData}
                    live={live}
                    tags={tags}
                    setTags={setTags}
                    allTags={Constants.allTags}
                    index={data?.token || index}
                    downloadsType={Constants.downloadsType}
                    currenChart={currenChart}
                    fromDate={props?.fromDate}
                    previousClosePrice={previousClosePrice}
                    socket={props.socket}
                  />
                )}
              </Index.Box>
            )}
            {graphType[theme][chartView] === 1 && (
              <Index.Box className="line-chart-body">
                <Index.CustomAreaChart
                  shareData={chartData}
                  // shareData={headerData?.historicalStockData}
                  chartLiveData={chartLiveData}
                  index={headerData?.token?.token || headerData?.token}
                  // index={index}
                  DivideHeight={getGrid()?.height}
                  borderRadiusClass={"border-radius-graph-bottom"}
                  setCurrenChart={setCurrenChart}
                  // token={data?.token}
                  token={data?.token || props?.token}
                  setDrawingChart={props?.setDrawingChart}
                  live={live}
                  activeChart={loopIndex === activeCursor}
                  isMCXMarket = {headerData?.exch_seg === "MCX"}
                  openDrawingChartModal={openDrawingChartModal}
                  handleUpdateAnnotation={handleUpdateAnnotation}
                />
                {getGrid()?.class === "hide-chart-header" && (
                  <HoverSection
                    chartLiveData={chartLiveData}
                    headMenu={headMenu}
                    setHeadMenu={setHeadMenu}
                    headerData={headerData}
                    chartData={chartData}
                    // chartData={headerData?.historicalStockData}
                    live={live}
                    tags={tags}
                    setTags={setTags}
                    allTags={Constants.allTags}
                    index={data?.token || index}
                    downloadsType={Constants.downloadsType}
                    currenChart={currenChart}
                    fromDate={props?.fromDate}
                    previousClosePrice={previousClosePrice}
                    socket={props.socket}
                  />
                )}
              </Index.Box>
            )}
            {graphType[theme][chartView] === 2 && (
              <Index.Box className="line-chart-body">
                <Index.DashedChart
                  shareData={chartData}
                  // shareData={headerData?.historicalStockData}
                  chartLiveData={chartLiveData}
                  index={headerData?.token?.token || headerData?.token}
                  // index={index}
                  DivideHeight={getGrid()?.height}
                  borderRadiusClass={"border-radius-graph-bottom"}
                  setCurrenChart={setCurrenChart}
                  // token={data?.token}
                  token={data?.token || props?.token}
                  setDrawingChart={props?.setDrawingChart}
                  live={live}
                  activeChart={loopIndex === activeCursor}
                  isMCXMarket = {headerData?.exch_seg === "MCX"}
                  openDrawingChartModal={openDrawingChartModal}
                  handleUpdateAnnotation={handleUpdateAnnotation}
                />
                {getGrid()?.class === "hide-chart-header" && (
                  <HoverSection
                    chartLiveData={chartLiveData}
                    headMenu={headMenu}
                    setHeadMenu={setHeadMenu}
                    headerData={headerData}
                    chartData={chartData}
                    // chartData={headerData?.historicalStockData}
                    live={live}
                    tags={tags}
                    setTags={setTags}
                    allTags={Constants.allTags}
                    index={data?.token || index}
                    downloadsType={Constants.downloadsType}
                    currenChart={currenChart}
                    fromDate={props?.fromDate}
                    previousClosePrice={previousClosePrice}
                    socket={props.socket}
                  />
                )}
              </Index.Box>
            )}
            {graphType[theme][chartView] === 3 && (
              <Index.Box className="line-chart-body">
                <Index.CustomCandlestickChart
                  shareData={chartData}
                  // shareData={headerData?.historicalStockData}
                  chartLiveData={chartLiveData}
                  index={headerData?.token?.token || headerData?.token}
                  DivideHeight={getGrid()?.height}
                  borderRadiusClass={"border-radius-graph-bottom"}
                  setCurrenChart={setCurrenChart}
                  token={data?.token || props?.token}
                  setDrawingChart={props?.setDrawingChart}
                  live={live}
                  activeChart={loopIndex === activeCursor}
                  isMCXMarket = {headerData?.exch_seg === "MCX"}
                  openDrawingChartModal={openDrawingChartModal}
                  handleUpdateAnnotation={handleUpdateAnnotation}
                />
                {getGrid()?.class === "hide-chart-header" && (
                  <HoverSection
                    chartLiveData={chartLiveData}
                    headMenu={headMenu}
                    setHeadMenu={setHeadMenu}
                    headerData={headerData}
                    chartData={chartData}
                    // chartData={headerData?.historicalStockData}
                    live={live}
                    tags={tags}
                    setTags={setTags}
                    allTags={Constants.allTags}
                    index={data?.token || index}
                    downloadsType={Constants.downloadsType}
                    currenChart={currenChart}
                    fromDate={props?.fromDate}
                    previousClosePrice={previousClosePrice}
                    socket={props.socket}
                  />
                )}
              </Index.Box>
            )}
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </Index.Grid>
  );
};

export default OneColumn;

const HoverSection = (props) => {
  const dispatch = Index.useDispatch();
  const {
    headMenu,
    setHeadMenu,
    headerData,
    currenChart,
    chartData,
    tags,
    setTags,
    index,
    downloadsType,
    chartLiveData,
    previousClosePrice,
  } = props;

  const currentPrice =
    chartData?.[chartData?.length - 1] && chartData?.[chartData?.length - 1][5];
  const {
    chartDuration,
    chartView,
    token: userToken,
    timeFrame,
    activeChartHover,
    gridView,
    displaySettings,
    selectedScript,
    userLoginDetail,
  } = Index.useSelector((state) => state.UserReducer);

  let isWeekendDay = [6, 7]?.includes(Index.moment().isoWeekday());
  let prevCloseDate = usePreviousDateCalculator(isWeekendDay ? 2 : 1);

  const lastDateData =
    chartData?.length > 0 &&
    chartData.filter(
      (data) =>
        Index.moment(new Date(data[0])).format("YYYY-MM-DD") ==
        Index.moment(prevCloseDate).format("YYYY-MM-DD")
    );

  const prevClosePrice =
    lastDateData.length > 0 ? lastDateData[lastDateData.length - 1][4] : null;

  let lastTradePrice =
    props?.live && chartLiveData
      ? chartLiveData?.tick[5]
      : chartData?.[0] && +chartData?.[chartData?.length - 1][4];

  let closePrice = props?.live
    ? chartLiveData
      ? chartLiveData?.tick[6]
      : previousClosePrice
    : prevClosePrice;

  let updatedDateAndTime =
    props?.live && chartLiveData
      ? Index.moment(+chartLiveData?.timestamp).format("DD-MM-YYYY HH:mm:ss")
      : chartData?.length &&
        Index.moment(new Date(+chartData?.[chartData?.length - 1][0]))
          .subtract(5, "hours")
          .subtract(30, "minutes")
          .format("DD-MM-YYYY HH:mm:ss");

  useEffect(() => {
    if (chartView === "Intraday") {
      lastTradePrice = chartLiveData?.tick[5];
      closePrice = chartLiveData?.tick[6];
      updatedDateAndTime = Index.moment(chartLiveData?.timestamp).format(
        "DD-MM-YYYY HH:mm:ss"
      );
    }
  }, [chartLiveData?.tick]);

  const changePointPercent = Math.abs(
    (((lastTradePrice - closePrice) / closePrice) * 100)?.toFixed(2)
  );

  const changePointValue = (lastTradePrice - closePrice)?.toFixed(2);
  const [downloadMenu, setDownloadMenu] = useState(false);
  const menuRef = useRef(null);
  // modal auto close code of download start
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setDownloadMenu(false);
      }
    };
    if (downloadMenu) {
      window.addEventListener("mousedown", handleOutsideClick);
    }
    return () => {
      window.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [downloadMenu]);
  // modal auto close code of download end

  async function updateTokenTags(t) {
    let formattedDate = Index.moment().startOf("minute").valueOf();
    await Index.DataService(userToken)
      .post(`/user/stock/stockTagAddAndUpdate`, {
        tag: t,
        previousTag: tags,
        script: selectedScript?.script?.toString(),
        stock_name: headerData?.stock_name || headerData?.symbol,
        lastTradedPrice: currentPrice,
        target: 12,
        stopLoss: 12,
        entryPrice: lastTradePrice,
        // exitPrice: 3,
        token: index,
        tradeType: chartView,
        timeFrame: Constants.shortInterval[chartDuration[chartView]?.interval],
        timeStamp: setDatefun(formattedDate),
        chartView,
        positionalTimeFrame: chartView === "Positional" ? chartDuration[chartView]?.timeFrame : null,
        interval: chartDuration[chartView]?.interval,
        intervalValue: Constants.intervalsValue[chartDuration[chartView]?.interval],
      })
      .then(({ data }) => {
        // toast.success("Tag added.");
      })
      .catch((e) => {
        // toast.success("Tag updated");
      });

      if (props?.socket) {
        props?.socket.emit("tagging_action", userLoginDetail?._id);
      }
  }

  async function removeStockTag(t) {
    try {
      let data = {
        token: JSON.parse(index),
        tag: t,
        lastTradedPrice: lastTradePrice,
        chartView,
        timeFrame: chartDuration[chartView]?.timeFrame,
        interval: chartDuration[chartView]?.interval,
      };
      await Index.DataService(userToken).post(Index.Api.user.removeStock, data);

      if (props?.socket) {
        props?.socket.emit("tagging_action", userLoginDetail?._id);
      }
      // toast.success("Tag removed successfully");
    } catch (error) {
      // toast.success("Tag updated");
    }
  }

  //Download chart
  const printImage = (ext) => {
    const filter = (node) => {
      const exclusionClasses = ["anychart-credits"];
      return !exclusionClasses.some((classname) =>
        node.classList?.contains(classname)
      );
    };

    if (ext?.toLowerCase() === "pdf") {
      const element = document.getElementById(`print-${JSON.parse(index)}`);
      htmlToImage
        .toPng(element, {
          filter: filter,
        })
        .then((dataUrl) => {
          const doc = new jsPDF({
            orientation: "portrait",
            unit: "in",
            format: "a4",
          });
          const scaleFactor = doc.internal.pageSize.width / element.offsetWidth;
          const x =
            (doc.internal.pageSize.width - element.offsetWidth * scaleFactor) /
            2;
          const y =
            (doc.internal.pageSize.height -
              element.offsetHeight * scaleFactor) /
            2;
          doc.addImage(
            dataUrl,
            "PNG",
            x,
            y,
            element.offsetWidth * scaleFactor,
            element.offsetHeight * scaleFactor
          );

          doc.save(
            `${headerData?.stock_name || headerData?.name}-${JSON.parse(
              index
            )}.${ext?.toLowerCase()}`
          );
          dispatch(manageDownloadChartData([]));
        })
        .catch((error) => {
          dispatch(manageDownloadChartData([]));
          console.error("Error converting element to image:", error);
        });
    } else {
      let toImageFn;
      switch (ext?.toLowerCase()) {
        case "svg":
          toImageFn = htmlToImage.toSvg;
          break;
        case "png":
          toImageFn = htmlToImage.toPng;
          break;
        case "jpg":
          toImageFn = htmlToImage.toJpeg;
          break;
        default:
          console.error("Invalid file format");
          return;
      }

      let element = document.getElementById(`print-${JSON.parse(index)}`);
      toImageFn(document.getElementById(`print-${JSON.parse(index)}`), {
        filter: filter,
      })
        .then(function (dataUrl) {
          var link = document.createElement("a");
          link.download = `${
            headerData?.stock_name || headerData?.name
          }-${JSON.parse(index)}.${ext?.toLowerCase()}`;
          link.href = dataUrl;
          link.click();
          dispatch(manageDownloadChartData([]));
        })
        .catch((e) => {
          console.error(e, "DownloadChart error");
          dispatch(manageDownloadChartData([]));
        });
    }
  };

  const getColorClassByTag = () => {
    switch (tags.toLowerCase()) {
      case "buy":
        return "green-btn";
      case "sell":
        return "red-btn";
      case "exit":
        return "red-btn";
      case "watch":
        return "blue-btn";
      default:
        return "";
    }
  };

  return (
    <Index.Box
      // className="chart-hover-sec"
      className={`chart-hover-sec ${
        activeChartHover?.includes(headerData?.token) && "active-chart-hover"
      }`}
    >
      <Index.Box className="watch-dot-btn-sec">
        <Index.Box className="dots-btn-sec">
          {tags && (
            <Index.Box className="buy-min-btn-sec">
              <Index.RedButton
                className={`buy-sell-btn ${
                  tags?.toLowerCase() === "buy"
                    ? "green-btn buy-btn"
                    : tags?.toLowerCase() === "sell" ||
                      tags?.toLowerCase() === "exit"
                    ? "red-btn buy-btn"
                    : tags?.toLowerCase() === "watch"
                    ? "blue-btn buy-btn"
                    : "blue-btn buy-btn"
                }`}
                btnLabel={tags}
              />
              {Constants.shortInterval[chartDuration[chartView]?.interval] && (
                <Index.RedButton
                  className={`buy-btn min-btn ${getColorClassByTag()}`}
                  btnLabel={Constants.shortInterval[chartDuration[chartView]?.interval]}
                />
              )}
            </Index.Box>
          )}
          <img
            className="white-dots-img"
            onClick={() => setHeadMenu(!headMenu)}
            alt="threeDotsWhite"
            src={Index.Svg.threeDotsWhite}
          />
          {headMenu && (
            <Index.Box
              className="head-menu"
              sx={{ display: "inherit !important" }}
            >
              <Index.List className="ul">
                {Constants.allTags
                  ?.filter((ele) => {
                    if (!tags) {
                      return !["exit", "reverse", "remove"]?.includes(
                        ele?.toLowerCase()
                      );
                    } else if (tags?.toLowerCase() === "exit") {
                      return !["watch", "reverse", "remove"]?.includes(
                        ele?.toLowerCase()
                      );
                    } else if (tags?.toLowerCase() === "watch") {
                      return !["exit", "reverse"]?.includes(ele?.toLowerCase());
                    } else if (
                      tags?.toLowerCase() === "buy" ||
                      tags?.toLowerCase() === "sell" ||
                      tags?.toLowerCase() === "exit"
                    ) {
                      return !["watch", "remove"]?.includes(ele?.toLowerCase());
                    }
                    return ele;
                  })
                  ?.map((e) => {
                    return (
                      <Index.ListItem
                        className={`li ${e === tags ? "active" : ""}`}
                        onClick={() => {
                          if (e === "Reverse") {
                            setTags(tags === "Sell" ? "Buy" : "Sell");
                            updateTokenTags(e);
                          } else if (e === "Remove") {
                            setTags("");
                            removeStockTag("Watch");
                          } else if (e === "Exit") {
                            setTags("");
                            removeStockTag(tags);
                          } else {
                            setTags(e);
                            updateTokenTags(e);
                          }
                          setHeadMenu((e) => !e);
                        }}
                      >
                        <Index.Typography
                          className="mint-text"
                          variant="p"
                          component="p"
                        >
                          {e}
                        </Index.Typography>
                      </Index.ListItem>
                    );
                  })}
              </Index.List>
            </Index.Box>
          )}
        </Index.Box>
      </Index.Box>
      <Index.Box className="hover-detail-sec">
        <Index.Box className="chart-header-left">
          <Index.Typography
            className="chart-title chart-text"
            variant="p"
            component="p"
            sx={{
              fontSize: `${20.5 - gridView?.column}px !important`,
            }}
          >
            {headerData?.stock_name || headerData?.symbol}
          </Index.Typography>

          <Index.Box className="d-flex">
            {isFinite(changePointValue) && (
              <>
                <Index.Box
                  sx={{
                    fontSize: `${
                      19 - gridView?.column
                    }px !important`,
                  }}
                  className={`chart-text ${
                    +closePrice > +lastTradePrice ? "red-color" : "green-color"
                  }`}
                >
                  ₹{lastTradePrice || closePrice}{" "}
                  <img
                    className="red-down-aroow"
                    alt="downRedArrow"
                    src={
                      +closePrice > +lastTradePrice
                        ? Index.Svg.downRedArrow
                        : Index.Svg.upGreenArrow
                    }
                  />
                </Index.Box>
                <Index.Typography
                  sx={{
                    fontSize: `${
                      19 - gridView?.column
                    }px !important`,
                  }}
                  className={`chart-text ${
                    +closePrice > +lastTradePrice ? "red-color" : "green-color"
                  }`}
                  variant="p"
                  component="p"
                >
                  {displaySettings?.change === "Rs" ? (
                    isFinite(changePointValue) ? (
                      changePointValue
                    ) : (
                      ""
                    )
                  ) : (
                    <span
                      sx={{
                        fontSize: `${
                          19 - gridView?.column
                        }px !important`,
                      }}
                      className={`${
                        +closePrice > +lastTradePrice
                          ? "red-color"
                          : "green-color"
                      }`}
                    >
                      {isFinite(changePointPercent)
                        ? `(${changePointPercent}%)`
                        : ""}
                    </span>
                  )}
                </Index.Typography>
              </>
            )}
          </Index.Box>
          {displaySettings?.LastUpdatedTime == "on" && (
            <Index.Box className="chart-header-time">
              <Index.Typography
                className="chart-text chart-updated-time-text"
                variant="p"
                component="p"
              >
                {updatedDateAndTime ? updatedDateAndTime : null}
              </Index.Typography>
            </Index.Box>
          )}
        </Index.Box>
        {/* <Index.Box
          className="cus-center one-min-bg"
          sx={{
            fontSize: `${17 - gridView?.column}px !important`,
            marginTop: `${14 - gridView?.column}px !important`,
          }}
        >
          {chartDuration === "ONE_MINUTE" && "1 M"}
          {chartDuration === "FIVE_MINUTE" && "5 M"}
          {chartDuration === "TEN_MINUTE" && "10 M"}
          {chartDuration === "FIFTEEN_MINUTE" && "15 M"}
          {chartDuration === "THIRTY_MINUTE" && "30 M"}
          {chartDuration === "ONE_HOUR" && "1 H"}
          {chartDuration === "ONE_DAY" && "1 D"}
        </Index.Box> */}
        <Index.RoundButton
          className="round-btn download-btn hov-dow-btn mr-0"
          // onClick={() => setDownloadMenu((e) => !e)}
          onClick={() => setDownloadMenu((prev) => !prev)}
          imgsrc={Index.Svg.download}
        />

        {/* <Index.Checkbox className="round-btn download-btn hov-dow-btn" /> */}
      </Index.Box>
      <Index.Box
        className={downloadMenu ? "head-menu active" : "head-menu"}
        ref={menuRef}
      >
        <Index.List className="ul">
          {downloadsType?.map((e, i) => {
            return (
              <Index.ListItem
                className={`li`}
                key={`0${i + 1}${e.type}`}
                onClick={() => {
                  dispatch(manageDownloadChartData([headerData?.token]));
                  // currenChart[e.saveAs]({
                  //   paperSize: "A4",
                  //   landscape: false,
                  //   filename: `My Chart - ${
                  //     headerData?.stock_name || headerData?.name
                  //   } ${e.type}`,
                  // });
                  setTimeout(() => {
                    printImage(e.type);
                  }, 500);
                  // setDownloadMenu((e) => !e);
                  setDownloadMenu(false);
                }}
              >
                <Index.Typography
                  className={`mint-text`}
                  variant="p"
                  component="p"
                >
                  {e.type}
                </Index.Typography>
              </Index.ListItem>
            );
          })}
        </Index.List>
      </Index.Box>
    </Index.Box>
  );
};

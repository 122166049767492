import React, { useEffect, useState } from "react";
import Index from "../Index";
import Modal from "../modal/Modal";
import AutoWidthHeight from "../common/AutoWidthHeight";
import { changeChartView, manageUserSetting, setGridViewStore } from "../../redux/user/action";

const GridSettings = (props) => {
  const dispatch = Index.useDispatch();
  const reduxData = Index.useSelector((state) => state.UserReducer);
  const { chartView, token, theme } = Index.useSelector((state) => state.UserReducer);
  const { height, width } = AutoWidthHeight();
  const { setgridSettings, gridSettings } = props;
  const [gridModal, setgridModal] = useState(false);
  const [opacity, setOpacity] = useState(1);
  const themeTitle = localStorage.getItem("default-theme");
  const [grid, setGrid] = useState([
    [true, false, false, false, false, false, false, false, false],
    [false, false, false, false, false, false, false, false, false],
    [false, false, false, false, false, false, false, false, false],
    [false, false, false, false, false, false, false, false, false],
    [false, false, false, false, false, false, false, false, false],
    [false, false, false, false, false, false, false, false, false],
    [false, false, false, false, false, false, false, false, false],
  ]);
  const [gridView, setGridView] = useState(reduxData?.gridView);

  const handleModel = () => {
    props.setgridModal(!props.gridModal);
  };
  useEffect(() => {
    // dispatch(setGridViewStore(gridView));
  }, [gridView]);

  useEffect(() => {
    setGrid((e) => {
      let arr = [...e];
      for (let main = 0; main < arr.length; main++) {
        for (
          let child = 0;
          child < arr[reduxData?.gridView?.column]?.length;
          child++
        ) {
          arr[main][child] = false;
        }
      }
      for (
        let main = 0;
        main < reduxData?.gridView?.column;
        main++
      ) {
        for (
          let child = 0;
          child < reduxData?.gridView?.row;
          child++
        ) {
          arr[main][child] = true;
          setGridView({
              column: main + 1,
              row: child + 1,
            });
        }
      }
      return arr;
    });
  }, []);

  const handleMouseEnter = (i, index) => {
    setGrid((e) => {
      let arr = [...e];
      for (let main = 0; main < arr.length; main++) {
        for (let child = 0; child < arr[main].length; child++) {
          arr[main][child] = main <= i && child <= index;
        }
      }
      return arr;
    });
    setGridView({
      column: i + 1,
      row: index + 1
    })
  };

  const handleMouseLeave = () => {
    setGridView(reduxData?.gridView)
    setGrid((e) => {
      let arr = [...e];
      for (let main = 0; main < arr.length; main++) {
        for (let child = 0; child < arr[main].length; child++) {
          arr[main][child] =
            main < reduxData?.gridView?.column &&
            child < reduxData?.gridView?.row;
        }
      }
      return arr;
    });
  };

  return (
    <>
      <Index.Box className="grid-setting-wrapper">
        <Index.Box className="grid-main">
          <Index.Box className="grid-col">
            {grid.map((e, i) => {
              return (
                <div className="grid-sec" key={`grid-setting-row-${i}`}>
                  {e.length
                    ? e.map((el, index) => {
                        return (
                          <Index.Box
                            key={`grid-setting-col-${index}`}
                            onClick={() => {
                              setGrid((e) => {
                                let arr = [...e];
                                for (let main = 0; main < arr.length; main++) {
                                  for (
                                    let child = 0;
                                    child < arr[i].length;
                                    child++
                                  ) {
                                    arr[main][child] = false;
                                  }
                                }
                                for (let main = 0; main <= i; main++) {
                                  for (let child = 0; child <= index; child++) {
                                    arr[main][child] = true;
                                    setGridView({
                                        column: main + 1,
                                        row: child + 1,
                                      });
                                    dispatch(
                                      setGridViewStore({
                                          column: main + 1,
                                          row: child + 1,
                                        })
                                    );
                                    dispatch(manageUserSetting(token, "gridView", {
                                      column: main + 1,
                                      row: child + 1,
                                    }))
                                    props?.setShow(!props?.show);
                                  }
                                }
                                return arr;
                              });
                              dispatch(changeChartView());
                            }}
                            onMouseEnter={() => handleMouseEnter(i, index)}
                            onMouseLeave={handleMouseLeave}
                            className={`grid-box ${el ? "active" : ""}`}
                          ></Index.Box>
                        );
                      })
                    : ""}
                </div>
              );
            })}

            {/* <Index.Box className="grid-box active"></Index.Box>
            <Index.Box className="grid-box active"></Index.Box>
            <Index.Box className="grid-box active"></Index.Box>
            <Index.Box className="grid-box active"></Index.Box>
            <Index.Box className="grid-box"></Index.Box>
            <Index.Box className="grid-box"></Index.Box>
            <Index.Box className="grid-box"></Index.Box>
            <Index.Box className="grid-box"></Index.Box>
            <Index.Box className="grid-box"></Index.Box>
            <Index.Box className="grid-box"></Index.Box>
            <Index.Box className="grid-box active"></Index.Box>
            <Index.Box className="grid-box active"></Index.Box>
            <Index.Box className="grid-box active"></Index.Box>
            <Index.Box className="grid-box active"></Index.Box>
            <Index.Box className="grid-box"></Index.Box>
            <Index.Box className="grid-box"></Index.Box>
            <Index.Box className="grid-box"></Index.Box>
            <Index.Box className="grid-box"></Index.Box>
            <Index.Box className="grid-box"></Index.Box>
            <Index.Box className="grid-box"></Index.Box>
            <Index.Box className="grid-box active"></Index.Box>
            <Index.Box className="grid-box active"></Index.Box>
            <Index.Box className="grid-box active"></Index.Box>
            <Index.Box className="grid-box active"></Index.Box>
            <Index.Box className="grid-box"></Index.Box>
            <Index.Box className="grid-box"></Index.Box>
            <Index.Box className="grid-box"></Index.Box>
            <Index.Box className="grid-box"></Index.Box>
            <Index.Box className="grid-box"></Index.Box>
            <Index.Box className="grid-box"></Index.Box>
            <Index.Box className="grid-box active"></Index.Box>
            <Index.Box className="grid-box active"></Index.Box>
            <Index.Box className="grid-box active"></Index.Box>
            <Index.Box className="grid-box active"></Index.Box>
            <Index.Box className="grid-box"></Index.Box>
            <Index.Box className="grid-box"></Index.Box>
            <Index.Box className="grid-box"></Index.Box>
            <Index.Box className="grid-box"></Index.Box>
            <Index.Box className="grid-box"></Index.Box>
            <Index.Box className="grid-box"></Index.Box>
            <Index.Box className="grid-box"></Index.Box>
            <Index.Box className="grid-box"></Index.Box>
            <Index.Box className="grid-box"></Index.Box>
            <Index.Box className="grid-box"></Index.Box>
            <Index.Box className="grid-box"></Index.Box>
            <Index.Box className="grid-box"></Index.Box>
            <Index.Box className="grid-box"></Index.Box>
            <Index.Box className="grid-box"></Index.Box>
            <Index.Box className="grid-box"></Index.Box>
            <Index.Box className="grid-box"></Index.Box>
            <Index.Box className="grid-box"></Index.Box>
            <Index.Box className="grid-box"></Index.Box>
            <Index.Box className="grid-box"></Index.Box>
            <Index.Box className="grid-box"></Index.Box>
            <Index.Box className="grid-box"></Index.Box>
            <Index.Box className="grid-box"></Index.Box>
            <Index.Box className="grid-box"></Index.Box>
            <Index.Box className="grid-box"></Index.Box>
            <Index.Box className="grid-box"></Index.Box>
            <Index.Box className="grid-box"></Index.Box> */}
          </Index.Box>
          <Index.Box className="grid-size">
            <Index.Typography className="four-text" variant="p" component="p">
              {gridView?.column} X {gridView?.row}
            </Index.Typography>
            <img
              className={`setting-white-img ${
                theme === "Light" && "custom-dark-setting"
              }`}
              alt="settingWhiteIc"
              src={Index.Svg.settingWhiteIc}
              onClick={(e) => {
                props.handleElementClick(e, 550, -250);
                handleModel();
              }}
            />
          </Index.Box>
        </Index.Box>
        {/* <Index.Box className='gap-thickness-color-sec'>
                    <Index.Box className='gap-sec'>
                        <Index.Box className="cus-input-grp ">
                            <Index.Typography
                                className="cus-lable light-font"
                                variant="p"
                                component="p"
                            >
                                Grid Gap
                            </Index.Typography>
                            <Index.Box className="form-group">
                                <Index.TextField
                                    fullWidth
                                    id="fullWidth"
                                    className="form-control"
                                    placeholder="20"
                                    autoComplete="off"
                                />
                            </Index.Box>
                        </Index.Box>
                    </Index.Box>
                    <Index.Box className='gap-sec line-thickness'>
                        <Index.Box className="cus-input-grp ">
                            <Index.Typography
                                className="cus-lable light-font"
                                variant="p"
                                component="p"
                            >
                                Line Thickness
                            </Index.Typography>
                            <Index.Box className="form-group">
                                <Index.TextField
                                    fullWidth
                                    id="fullWidth"
                                    className="form-control"
                                    placeholder="2"
                                    autoComplete="off"
                                />
                            </Index.Box>
                        </Index.Box>
                    </Index.Box>
                </Index.Box> */}
      </Index.Box>
    </>
  );
};

export default GridSettings;

const timeFrames = {
  ONE_MINUTE: "1Minute",
  TWO_MINUTE: "2Minute",
  THREE_MINUTE: "3Minute",
  FOUR_MINUTE: "4Minute",
  FIVE_MINUTE: "5Minute",
  TEN_MINUTE: "10Minute",
  FIFTEEN_MINUTE: "15Minute",
  THIRTY_MINUTE: "30Minute",
  ONE_HOUR: "1Hour",
  ONE_DAY: "1Day",
  TWO_DAY: "2Day",
  THREE_DAY: "3Day",
  ONE_WEEK: "1Week",
  TWO_WEEK: "2Week",
  THREE_WEEK: "3Week",
};

const annotationTimeFrames = {
  ONE_MINUTE: "1Minute",
  TWO_MINUTE: "2Minute",
  THREE_MINUTE: "3Minute",
  FOUR_MINUTE: "4Minute",
  FIVE_MINUTE: "5Minute",
  TEN_MINUTE: "10Minute",
  FIFTEEN_MINUTE: "15Minute",
  THIRTY_MINUTE: "30Minute",
  ONE_HOUR: "1Hour",
  FOUR_HOUR: "4Hour",
  ONE_DAY: "1Day",
  TWO_DAY: "2Day",
  THREE_DAY: "3Day",
  ONE_WEEK: "1Week",
  TWO_WEEK: "2Week",
  THREE_WEEK: "3Week",
};

const shortInterval = {
  ONE_MINUTE: "1 M",
  TWO_MINUTE: "2 M",
  THREE_MINUTE: "3 M",
  FOUR_MINUTE: "4 M",
  FIVE_MINUTE: "5 M",
  TEN_MINUTE: "10 M",
  FIFTEEN_MINUTE: "15 M",
  THIRTY_MINUTE: "30 M",
  ONE_HOUR: "1 H",
  FOUR_HOUR: "4 H",
  ONE_DAY: "1 D",
  TWO_DAY: "2 D",
  THREE_DAY: "3 D",
  ONE_WEEK: "1 W",
  TWO_WEEK: "2 W",
  THREE_WEEK: "3 W",
};

const chartInterval = {
  ONE_MINUTE: { interval: "1m", value: 1, offset: "15m" },
  TWO_MINUTE: { interval: "2m", value: 2, offset: "15m" },
  THREE_MINUTE: { interval: "3m", value: 3, offset: "15m" },
  FOUR_MINUTE: { interval: "4m", value: 4, offset: "15m" },
  FIVE_MINUTE: { interval: "5m", value: 5, offset: "15m" },
  TEN_MINUTE: { interval: "10m", value: 10, offset: "15m" },
  FIFTEEN_MINUTE: { interval: "15m", value: 15, offset: "15m" },
  THIRTY_MINUTE: { interval: "30m", value: 30, offset: "15m" },
  ONE_HOUR: { interval: "1h", value: 60, offset: "15m" },
  FOUR_HOUR: { interval: "4h", value: 180, offset: "15m" },
  ONE_DAY: { interval: "1d", value: 1440, offset: "0m" },
  TWO_DAY: { interval: "2d", value: 2880, offset: "0m" },
  THREE_DAY: { interval: "3d", value: 4320, offset: "0m" },
  ONE_WEEK: { interval: "1w", value: 10080, offset: "0m" },
  TWO_WEEK: { interval: "2w", value: 20160, offset: "0m" },
  THREE_WEEK: { interval: "3w", value: 30240, offset: "0m" },
};

const intervalsValue = {
  ONE_MINUTE: 1,
  TWO_MINUTE: 2,
  THREE_MINUTE: 3,
  FOUR_MINUTE: 4,
  FIVE_MINUTE: 5,
  TEN_MINUTE: 10,
  FIFTEEN_MINUTE: 15,
  THIRTY_MINUTE: 30,
  ONE_HOUR: 60,
  FOUR_HOUR: 240,
  ONE_DAY: 1440,
  TWO_DAY: 2880,
  THREE_DAY: 4320,
  ONE_WEEK: 10080,
  TWO_WEEK: 20160,
  THREE_WEEK: 30240,
};

const downloadsType = [
  {
    type: "SVG",
    saveAs: "saveAsSvg",
  },
  {
    type: "PNG",
    saveAs: "saveAsPng",
  },
  {
    type: "JPG",
    saveAs: "saveAsJpg",
  },
  {
    type: "PDF",
    saveAs: "saveAsPdf",
  },
];

const allTags = ["Exit", "Watch", "Buy", "Sell", "Reverse", "Remove"];

const defaultOtherSettings = {
  Light: {
    crosshair: { color: "#000000", lineType: "4 3", thickness: 1 },
    stopLoss: { color: "#ff0000", lineType: "0", thickness: 1 },
    target: { color: "#00FF00", lineType: "0", thickness: 1 },
    scale: { color: "#000000", lineType: "0", thickness: 1 },
    daySplitter: { color: "#000000", lineType: "0", thickness: 1 },
  },
  Medium: {
    crosshair: { color: "#ffffff", lineType: "4 3", thickness: 1 },
    stopLoss: { color: "#ff0000", lineType: "0", thickness: 1 },
    target: { color: "#00FF00", lineType: "0", thickness: 1 },
    scale: { color: "#ffffff", lineType: "0", thickness: 1 },
    daySplitter: { color: "#ffffff", lineType: "0", thickness: 1 },
  },
  Dark: {
    crosshair: { color: "#ffffff", lineType: "4 3", thickness: 1 },
    stopLoss: { color: "#ff0000", lineType: "0", thickness: 1 },
    target: { color: "#00FF00", lineType: "0", thickness: 1 },
    scale: { color: "#ffffff", lineType: "0", thickness: 1 },
    daySplitter: { color: "#ffffff", lineType: "0", thickness: 1 },
  },
};

const defaultGraphStyle = {
  Light: {
    lineGraph: { color: "#02b8e6", thickness: 1, thicknessColor: "#02b8e6",
    },
    areaGraph: { color: "#02b8e6", thickness: 1, thicknessColor: "#02b8e6",
    },
    dottedGraph: { color: "#02b8e6", thickness: 1, thicknessColor: "",
    },
    candleStickGraph: { color: "#02b8e6", thickness: 1, thicknessColor: "", fallingColor: "#FF3737", risingColor: "#34C759",
    },
  },
  Medium: {
    lineGraph: { color: "#ffffff", thickness: 1, thicknessColor: "#ffffff",
    },
    areaGraph: { color: "#ffffff", thickness: 1, thicknessColor: "#ffffff",
    },
    dottedGraph: { color: "#ffffff", thickness: 1, thicknessColor: "",
    },
    candleStickGraph: { color: "#ffffff", thickness: 1, thicknessColor: "", fallingColor: "#FF3737", risingColor: "#34C759",
    },
  },
  Dark: {
    lineGraph: { color: "#ffffff", thickness: 1, thicknessColor: "#ffffff",
    },
    areaGraph: { color: "#ffffff", thickness: 1, thicknessColor: "#ffffff",
    },
    dottedGraph: { color: "#ffffff", thickness: 1, thicknessColor: "",
    },
    candleStickGraph: { color: "#ffffff", thickness: 1, thicknessColor: "", fallingColor: "#FF3737", risingColor: "#34C759",
    },
  },
};

const defaultGraphType = {
  Light: {Intraday:0, Positional:0},
  Medium: {Intraday:0, Positional:0},
  Dark: {Intraday:0, Positional:0},
};

const defaultSorting = {
  Dashboard: {
    order: 0,
    tags: [],
    timeFrame: 0,
    reset: 1,
    isOrderSort: true,
    isTagsSort: true,
    isTimeFrameSort: false,
  },
  TrackingWindow: {
    order: 0,
    tags: [],
    timeFrame: 0,
    reset: 1,
    isOrderSort: true,
    isTagsSort: true,
    isTimeFrameSort: true,
  },
  UserManagement: {
    order: 0,
    tags: [],
    timeFrame: 0,
    reset: 1,
    isOrderSort: true,
    isTagsSort: false,
    isTimeFrameSort: false,
  },
  UsersTaggedScripts: {
    order: 0,
    tags: [],
    timeFrame: 0,
    reset: 1,
    isOrderSort: true,
    isTagsSort: true,
    isTimeFrameSort: true,
  },
  PerformanceReport: {
    order: 0,
    tags: [],
    timeFrame: 0,
    reset: 1,
    isOrderSort: true,
    isTagsSort: true,
    isTimeFrameSort: true,
  },
  IndexManagement: {
    order: 0,
    tags: [],
    timeFrame: 0,
    reset: 1,
    isOrderSort: true,
    isTagsSort: false,
    isTimeFrameSort: false,
  },
  IndexManagementDetail: {
    order: 0,
    tags: [],
    timeFrame: 0,
    reset: 1,
    isOrderSort: true,
    isTagsSort: false,
    isTimeFrameSort: false,
  },
  Notification: {
    order: 0,
    tags: [],
    timeFrame: 0,
    reset: 1,
    isOrderSort: true,
    isTagsSort: true,
    isTimeFrameSort: false,
  },
};

const sortingPath = {
  "/user/dashboard":"Dashboard",
  "/user/analysis-window":"Dashboard",
  "/user/tracking-window-charts":"TrackingWindow",
  "/user/tracking-window":"TrackingWindow",
  "/user/user-management":"UserManagement",
  "/user/users-tagged-scripts":"UsersTaggedScripts",
  "/user/performance-report":"PerformanceReport",
  "/user/index-management":"IndexManagement",
  "/user/index-management/detail": "IndexManagementDetail",
  "/user/notifications": "Notification",
}

const defaultAutoSquareTime = {
  NSE: {
    title: "Equity",
    name: "NSE",
    time: "2024-12-05T15:30",
    cronTime: "30 15 * * *",
  },
  NFO: {
    title: "F&O",
    name: "NFO",
    time: "2024-12-05T15:30",
    cronTime: "30 15 * * *",
  },
  MCX: {
    title: "Commodity",
    name: "MCX",
    time: "2024-12-05T23:30",
    cronTime: "30 23 * * *",
  },
}

const Constants = {
  timeFrames,
  shortInterval,
  chartInterval,
  downloadsType,
  allTags,
  defaultOtherSettings,
  defaultGraphStyle,
  defaultGraphType,
  intervalsValue,
  defaultSorting,
  sortingPath,
  annotationTimeFrames,
  defaultAutoSquareTime,
};

export default Constants;

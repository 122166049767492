import AnyChart from "anychart-react";
import anychart from "anychart";
import React, { useEffect, useState, useRef, memo, useCallback } from "react";
import Index from "../../Index";
import AutoWidthHeight from "../../common/AutoWidthHeight";
import { toast } from "react-toastify";
import useCurrentInterval from "../../common/current-interval/useCurrentInterval";
import { DataService } from "../../../config/DataService";
import {
  manageChartDrawingData,
  manageChartInstance,
  manageDrawingChartToken,
} from "../../../redux/user/action";
import Constants from "../../common/constants";

const dataTableLine = {};

const setDatefun = (data) => {
  let d = new Date(data);
  let h = d.getHours() + 5;
  let m = d.getMinutes() + 30;
  d.setHours(h, m, 0, 0);
  return d.getTime();
};

const CustomCendalStickChartSingle = (props) => {
  const dispatch = Index.useDispatch();
  const [chartData, setChartData] = useState([]);
  const {
    token: userToken,
    wishListData,
    otherSettings,
    chartDuration,
    displaySettings,
    chartView,
    isFlip,
    gridSpacing,
    gridBorder,
    twoSideArrowZoom,
    chartDrawingData,
    isDrawingButtonActive,
    drawingStockToken,
    theme,
  } = Index.useSelector((state) => state.UserReducer);
  const themeTitle = localStorage.getItem("default-theme");
  const candleStickGraph = Index.useSelector(
    (store) => store.UserReducer.GraphStyle[theme].candleStickGraph
  );
  const [exchangeType, setExchangeType] = useState();
  const [orderToken, setOrderToken] = useState();
  const [open, setOpen] = useState(0);
  const [high, setHigh] = useState(0);
  const [low, setLow] = useState(0);
  const [close, setClose] = useState(0);
  const [parentWidth, setParentWidth] = useState(null);
  const {
    headerData,
    index,
    stockToken,
    draw,
    stockTag,
    exchange_timestamp,
    setCurrenChart,
    topLossAt,
    targetAt,
    updateOrder,
    setUpdateOrder,
    openModel,
    setOrderCurrentChart,
    setTargetOrTopLoss,
    stockData,
    isMarketOpen,
    chartLiveData,
    DivideHeight,
    activeChart,
    isModalChart,
    setDrawingChart,
    isStopLossHit,
    isMCXMarket,
    handleUpdateAnnotation,
    isDrawingChart,
    openDrawingChartModal,
  } = props;

  const exch = wishListData?.filter((data) => {
    // if (data?.token == parseInt(stockToken)) {
      // }
      if (JSON.parse(stockToken) == data?.token) {
      return data;
    }
  });

  const { height, width } = AutoWidthHeight();
  // State declaration region
  console.log(headerData?.interval,"headerData?.interval")
  const [data1, setData1] = useState([]);
  const [currentInterval, nextInterval] = useCurrentInterval(
    +Constants.chartInterval[headerData?.interval]?.value,
    chartLiveData,
    isMCXMarket
  );
  const [loading, setLoading] = useState(false);
  const [chartHeight, setChartHeight] = useState(null);
  const chartId = `candlestick_chart_${headerData?._id}${isModalChart && "_"}${index}`

  // Hooks declaration region

  const getHistorylist = () => {
    const formData = new URLSearchParams();
    formData.append(
      "exchange",
      exch?.[0]?.Exch || exch?.[0]?.exch_seg || "MCX"
    );
    formData.append("symboltoken", JSON.parse(stockToken));
    formData.append("interval", "ONE_MINUTE");
    formData.append(
      "fromdate",
      Index.moment().set({ hour: 9, minute: 15 }).format("YYYY-MM-DD HH:mm")
    );
    formData.append(
      "todate",
      Index.moment()
        .add(1, "d")
        .set({ hour: 3, minute: 30 })
        .format("YYYY-MM-DD HH:mm")
    );
    Index.DataService(userToken)
      .post(Index.Api.user.angelGetAllHistory, formData)
      .then(async (res) => {
        if (res.data.data) {
          let format = res.data.data.map((el) => {
            return [setDatefun(el[0]), el[1], el[2], el[3], el[4]];
          });
          setData1(format);
        }
      })
      .catch((err) => {});
  };

  useEffect(() => {
    if (stockData?.length) {
      setData1(stockData);
    }
  }, [stockData]);

  const updateTagStatus = (args, lastPrice, position, color) => {
    // Index.DataService(userToken)
    //   .post("/user/stocks-tag-status", {
    //     targetTag: args,
    //     lastPrice,
    //     timeStamp: +setDatefun(+exchange_timestamp),
    //     token: JSON.parse(stockToken),
    //     position,
    //     color,
    //   })
    //   .then(() => {
    //     toast.success("status updated");
    //   });
  };

  const getTagData = () => {
    Index.DataService(userToken)
      .get(`/user/get-stock-tag-details?token=${JSON.parse(stockToken)}`)
      .then(({ data }) => {
        setUpdateOrder({
          target: data?.data?.targetAt?.price,
          stopLoss: data?.data?.topLossAt?.price,
        });
      });
  };

  const getTagColor = (tag, currentPrice, lastPrice, call) => {
    let { color, position } =
      // tag === "Sell"
      //   ? lastPrice < currentPrice
      //     ? { color: "#00FF00", position: "arrow-down" }
      //     : { color: "#ff0000", position: "arrow-up" }
      //   : lastPrice < currentPrice
      //   ? { color: "#ff0000", position: "arrow-down" }
      //   : { color: "#00FF00", position: "arrow-up" };
      tag === "Buy"
        ? lastPrice < currentPrice
          ? {
              color: otherSettings?.[theme]?.["target"]?.color || "#00FF00",
              position: "arrow-down",
            }
          : {
              color: otherSettings?.[theme]?.["stopLoss"]?.color || "#ff0000",
              position: "arrow-up",
            }
        : lastPrice < currentPrice
        ? {
            color: otherSettings?.[theme]?.["stopLoss"]?.color || "#ff0000",
            position: "arrow-down",
          }
        : {
            color: otherSettings?.[theme]?.["target"]?.color || "#00FF00",
            position: "arrow-up",
          };
    currentPrice = currentPrice.toFixed(2);
    if (
      (color?.toLowerCase() ==
        otherSettings?.[theme]?.["target"]?.color?.toLowerCase() ||
        color == "#00FF00") &&
      tag === "Sell"
    ) {
      updateTagStatus("targetAt", currentPrice, position, color);
      setTargetOrTopLoss("targetAt");
      setOrderToken(stockToken);
      props?.setEditOrderData &&
        props?.setEditOrderData({
          targetAt: {
            targetTag: "targetAt",
            price: currentPrice,
            position,
            color,
            token: stockToken,
          },
          token: stockToken,
        });
    }
    if (
      (color?.toLowerCase() ==
        otherSettings?.[theme]?.["stopLoss"]?.color?.toLowerCase() ||
        color == "#FF0000") &&
      tag === "Sell"
    ) {
      updateTagStatus("topLossAt", currentPrice, position, color);
      setTargetOrTopLoss("topLossAt");
      setOrderToken(stockToken);
      props?.setEditOrderData &&
        props?.setEditOrderData({
          topLossAt: {
            targetTag: "topLossAt",
            price: currentPrice,
            position,
            color,
            token: stockToken,
          },
          token: stockToken,
        });
    }
    if (
      (color?.toLowerCase() ==
        otherSettings?.[theme]?.["target"]?.color?.toLowerCase() ||
        color == "#00FF00") &&
      tag === "Buy"
    ) {
      updateTagStatus("targetAt", currentPrice, position, color);
      setTargetOrTopLoss("targetAt");
      setOrderToken(stockToken);
      props?.setEditOrderData &&
        props?.setEditOrderData({
          targetAt: {
            targetTag: "targetAt",
            price: currentPrice,
            position,
            color,
            token: stockToken,
          },
          token: stockToken,
        });
    }
    if (
      (color?.toLowerCase() ==
        otherSettings?.[theme]?.["stopLoss"]?.color?.toLowerCase() ||
        color == "#FF0000") &&
      tag === "Buy"
    ) {
      updateTagStatus("topLossAt", currentPrice, position, color);
      setTargetOrTopLoss("topLossAt");
      setOrderToken(stockToken);
      props?.setEditOrderData &&
        props?.setEditOrderData({
          topLossAt: {
            targetTag: "topLossAt",
            price: currentPrice,
            position,
            color,
            token: stockToken,
          },
          token: stockToken,
        });
    }
    return { color, position };
  };

  // useEffect(() => {
  //   getHistorylist();
  // }, []);

  useEffect(() => {
    charts();
  }, [
    data1,
    otherSettings?.[theme],
    candleStickGraph,
    displaySettings?.StopLossTargetPrice,
    displaySettings?.PriceScale,
    displaySettings?.TimeScale,
    otherSettings?.[theme]?.scale,
    targetAt,
    topLossAt,
    isFlip,
    gridSpacing,
    gridBorder,
    activeChart,
    DivideHeight,
    document?.getElementsByClassName("order-header-main")?.[0]?.clientHeight,
    loading,
    twoSideArrowZoom,
    isDrawingButtonActive,
  ]);

  useEffect(()=>{
    if(!openDrawingChartModal && drawingStockToken == stockToken && !isDrawingChart){
      charts()
      dispatch(manageDrawingChartToken(null))
    }
  },[openDrawingChartModal])

  useEffect(() => {
    if (chartView === "Intraday") {
      addPoint();
    }
  }, [chartLiveData]);

  useEffect(() => {
    if (props?.openModel && openModel[JSON.parse(stockToken)]) {
      getTagData();
    }
  }, [openModel]);

  // Function declaration region
  const addPoint = () => {
    if (stockData?.length > 0 && chartLiveData?.length) {
      dataTableLine[index].addData(stockData);
      let data = data1;
      const liveTimestamp = Index.moment(chartLiveData[0])
        .subtract(5, "hours")
        .subtract(30, "minutes")
        .valueOf();

      if (liveTimestamp >= currentInterval && liveTimestamp < nextInterval) {
        data.pop();
        data.push([
          Index.moment(currentInterval)
            .add(5, "hours")
            .add(30, "minutes")
            .valueOf(),
          chartLiveData[1],
          chartLiveData[2],
          chartLiveData[3],
          chartLiveData[4],
          chartLiveData[4],
        ]);
      } else {
        data?.push(chartLiveData);
      }

      setData1(data);
    }
  };

  // Chart drawing function declaration start
  const charts = () => {
    const container = document.getElementById(chartId);
    container?.firstElementChild?.remove();

    if (data1?.length) {
      setLoading(false);
    } else {
      setLoading(true);
    }

    var chart = anychart.stock();
    chart.top(12);
    chart.left(-1);
    chart.right(displaySettings?.PriceScale === "on" ? 60 : 0);
    dataTableLine[index] = anychart.data.table();
    dataTableLine[index].addData(data1);
    // create a line series and set the data
    // dataTableLine[index].removeFirst(300)

    let series2 = dataTableLine[index].mapAs({
      open: 1,
      high: 2,
      low: 3,
      close: 4,
      value: chartView === "Positional" ? 4 : 5,
    });

    // Enable mouse wheel zoom.
    var interactivity = chart.interactivity();
    interactivity.zoomOnMouseWheel(true);

    var plot = chart.plot(0);
    plot.xAxis().enabled(displaySettings?.TimeScale === "on");
    theme == "Light"
      ? plot.xAxis().background("#fff")
      : theme == "Dark"
      ? plot.xAxis().background("#212121")
      : plot.xAxis().background("#283142");
    plot.yGrid().enabled(true);
    plot.xGrid().enabled(true);
    plot.yGrid().stroke({ color: "#fff" });
    plot.xGrid().stroke({ color: "#fff" });

    // plot.xAxis().enabled(true);
    plot.yAxis().enabled(true);
    plot.annotations(true);

    //Flip chart
    plot.yScale().inverted(isFlip);

    var extraYAxis = plot.yAxis();
    extraYAxis.orientation("right");

    chart.plot(0).yAxis().enabled(true);
    chart
      .plot(0)
      .yAxis()
      .stroke(
        otherSettings?.[theme]?.scale?.color,
        otherSettings?.[theme]?.scale?.thickness,
        otherSettings?.[theme]?.scale?.lineType
      );
    chart
      .plot(0)
      .yAxis(0)
      .ticks()
      .stroke(otherSettings?.[theme]?.scale?.color, otherSettings?.[theme]?.scale?.thickness);

    //Price Indicator
    if (props?.live && displaySettings?.PriceIndicator === "on") {
      let indicator1 = plot.priceIndicator();
      indicator1.value("last-visible");
      indicator1.stroke("white", 1, "6 4");
      // indicator1.fallingStroke('#EF9A9A',1, "6 4");
      indicator1.fallingLabel({ background: candleStickGraph.fallingColor });
      // indicator1.risingStroke('#4CAF50',1, "6 4");
      indicator1.risingLabel({ background: candleStickGraph.risingColor });
    }

    // create a line series
    var crosshair = chart.crosshair();

    crosshair.xLabel().format(function (e) {
      const date = Index.moment(e.tickValue)
        .subtract(5, "hours")
        .subtract(30, "minutes");
      return date.hour() === 0 && date.minute() === 0
        ? date.format("DD MMM 'YY")
        : date.format("ddd DD MMM 'YY   HH:mm");
      // return anychart.format.dateTime(e["tickValue"], "YYYY MMM dd hh:mm");
    });

    crosshair.yLabel().format(function (e) {
      return this?.tickValue ? this?.tickValue : 0
    });
    
    crosshair.enabled(true);
    // crosshair.displayMode("float");

    if (theme == "Light") {
      crosshair.xStroke(
        otherSettings?.[theme]?.crosshair?.color || "#212121",
        otherSettings?.[theme]?.crosshair?.thickness || 1,
        otherSettings?.[theme]?.crosshair?.lineType || "4 3",
        "round"
      );
      crosshair.yStroke(
        otherSettings?.[theme]?.crosshair?.color || "#212121",
        otherSettings?.[theme]?.crosshair?.thickness || 1,
        otherSettings?.[theme]?.crosshair?.lineType || "4 3",
        "round"
      );
    } else {
      crosshair.xStroke(
        otherSettings?.[theme]?.crosshair?.color || "#ffffff",
        otherSettings?.[theme]?.crosshair?.thickness || 1,
        otherSettings?.[theme]?.crosshair?.lineType || "4 3",
        "round"
      );
      crosshair.yStroke(
        otherSettings?.[theme]?.crosshair?.color || "#ffffff",
        otherSettings?.[theme]?.crosshair?.thickness || 1,
        otherSettings?.[theme]?.crosshair?.lineType || "4 3",
        "round"
      );
    }

    crosshair.xLabel().enabled(true);
    crosshair.yLabel().enabled(true);
    var tooltip = chart.tooltip();
    tooltip.width(0);
    tooltip.height(0);
    tooltip.separator(false);
    // set chart padding
    chart.padding([0, 0, 0, 0]);
    // set chart background
    chart.background().fill({
      src: "",
      mode: "",
    });
    var background = chart.background();
    background.cornerType("round");
    background.fill(
      theme == "Light"
        ? "#fff"
        : theme == "Dark"
        ? "#212121"
        : "#283142"
    );
    plot.xGrid().enabled(false);
    plot.xGrid().stroke("#ffffff05");
    plot.yGrid().stroke("#ffffff05");
    theme == "Light"
      ? plot.xGrid().palette(["#fff"])
      : theme == "Dark"
      ? plot.xGrid().palette(["#212121"])
      : plot.xGrid().palette(["#283142"]);
    plot.yGrid().enabled(false);
    theme == "Light"
      ? plot.xGrid().palette(["#fff"])
      : theme == "Dark"
      ? plot.xGrid().palette(["#212121"])
      : plot.xGrid().palette(["#283142"]);
    chart.scroller().enabled(false);
    var lineSeries = plot.candlestick(series2);
    lineSeries.risingFill(candleStickGraph.risingColor);
    lineSeries.fallingFill(candleStickGraph.fallingColor);
    lineSeries.risingStroke(
      candleStickGraph.risingColor,
      candleStickGraph.thickness
    );
    lineSeries.fallingStroke(
      candleStickGraph.fallingColor,
      candleStickGraph.thickness
    );
    // lineSeries.stroke(function () {
    //   return {
    //     keys: [lineGraph.color],
    //     thickness: lineGraph.thickness,
    //   };
    // });
    // lineSeries.fill({
    //   keys: [lineGraph.color],
    //   fill: 2,
    // });

    chart.tooltip().titleFormat(function () {
      // save hovered date to variable
      setOpen(this.formattedValues[0].split("  ")[1].split(" ")[1]);
      setHigh(this.formattedValues[0].split("  ")[2].split(" ")[1]);
      setLow(this.formattedValues[0].split("  ")[3].split(" ")[1]);
      setClose(this.formattedValues[0].split("  ")[4].split(" ")[1]);
    });

    // var stage = anychart.graphics.create(
    //   `candlestick_chart${index}`,
    //   props.width,
    //   props.height
    // );

    let mainGridHeight = document.getElementsByClassName(
      "dashboard-chart-main"
    )?.[0]?.clientHeight;

    let chartHeaderHeight =
      document.getElementsByClassName("order-header-main")?.[0]?.clientHeight;

    let trackingSubHeader =
      document.getElementsByClassName("tracking-chart-sub-header")?.[0]?.clientHeight ?? 0;

    setChartHeight(
      (mainGridHeight -
        chartHeaderHeight * DivideHeight -
        (gridSpacing * 8 * (DivideHeight - 1) + 8) -
        (activeChart ? parseInt(gridBorder) * 4 : 0)) /
        DivideHeight
    );

    var stage = anychart.graphics.create(
      chartId,
      props?.isModalChart ? props?.width : "100%",
      props?.isModalChart
        ? props?.height
        : (mainGridHeight -
            chartHeaderHeight * DivideHeight -
            (gridSpacing * 8 * (DivideHeight - 1) + 8) -
            (activeChart ? parseInt(gridBorder) * 4 : 0)) /
            DivideHeight
    );

    let chartBodySize =
    document.getElementsByClassName("chart-body")?.[0]?.clientWidth;

    setParentWidth(chartBodySize);

    plot.legend(false);

    var priceTestMarker = plot.textMarker(0);
    priceTestMarker.enabled(false);

    const plotPriceMarker = (id, price, color, index) => {
      plot
        .textMarker(id)
        .enabled(displaySettings?.StopLossTargetPrice === "on")
        .text(price)
        .value(price)
        .align("right")
        .padding(3, 5)
        .anchor("left-center")
        .fontColor("#FFFFFF")
        .fontSize(10)
        .background(color)
        .zIndex(index);
    };

    if (draw && !isDrawingButtonActive && isStopLossHit) {
      if (topLossAt) {
        var controller = plot.annotations();
        var horizontalLine1 = controller.horizontalLine();

        horizontalLine1.valueAnchor(+topLossAt.price);

        horizontalLine1
          .normal()
          .stroke(
            otherSettings?.[theme]?.stopLoss?.color,
            otherSettings?.[theme]?.stopLoss?.thickness,
            otherSettings?.[theme]?.stopLoss?.lineType
          );

        horizontalLine1.id = controller.getAnnotationsCount() - 1;
        horizontalLine1.allowEdit(false);

        plotPriceMarker(
          controller.getAnnotationsCount() - 1,
          +topLossAt.price,
          "#FF0000"
        );
      }
      if (targetAt) {
        var controller = plot.annotations();
        var horizontalLine2 = controller.horizontalLine();

        horizontalLine2.valueAnchor(+targetAt.price);

        horizontalLine2
          .normal()
          .stroke(
            otherSettings?.[theme]?.target?.color,
            otherSettings?.[theme]?.target?.thickness,
            otherSettings?.[theme]?.target?.lineType
          );

        horizontalLine2.id = controller.getAnnotationsCount() - 1;
        horizontalLine2.allowEdit(false);

        plotPriceMarker(
          controller.getAnnotationsCount() - 1,
          +targetAt.price,
          "#00a12b"
        );
      }

      var markerController = chart.plot(0).annotations();
      if (props?.markerData?.tag?.toLowerCase() === "buy") {
        let annotation = markerController
          .marker({
            xAnchor: +props?.markerData?.timeStamp,
            valueAnchor: +props?.markerData?.price,
            color: "green",
            size: 15,
          })
          .markerType("arrowUp");
        annotation.allowEdit(false);
      }
      if (props?.markerData?.tag?.toLowerCase() === "sell") {
        let annotation = markerController
          .marker({
            xAnchor: +props?.markerData?.timeStamp,
            valueAnchor: +props?.markerData?.price,
            color: "red",
            size: 15,
          })
          .markerType("arrowDown");
        annotation.allowEdit(false);
      }
    }

    if (draw && !isDrawingButtonActive && !isStopLossHit) {
      let drawLineValue;
      let lastPrice = props?.liveData?.length
        ? props?.liveData?.[5]
        : props?.liveData?.[4];
      // let lastPrice = props?.liveData[JSON.parse(stockToken)];
      // lastPrice = lastPrice && lastPrice[lastPrice?.length - 1][1] || 0;
      var markerController = chart.plot(0).annotations();
      // var mymarker = markerController.horizontalLine();
      // mymarker.valueAnchor(1547.55);
      if (props?.markerData?.tag?.toLowerCase() === "buy") {
        let annotation = markerController
          .marker({
            xAnchor: +props?.markerData?.timeStamp,
            valueAnchor: +props?.markerData?.price,
            color: "green",
            size: 15,
          })
          .markerType("arrowUp");
        annotation.allowEdit(false);
      }
      if (props?.markerData?.tag?.toLowerCase() === "sell") {
        let annotation = markerController
          .marker({
            xAnchor: +props?.markerData?.timeStamp,
            valueAnchor: +props?.markerData?.price,
            color: "red",
            size: 15,
          })
          .markerType("arrowDown");
        annotation.allowEdit(false);
      }
      if (topLossAt) {
        var controller = plot.annotations();
        var horizontalLine1 = controller.horizontalLine();

        // set the position of the second annotation
        horizontalLine1.valueAnchor(+topLossAt.price);

        // configure the visual settings of the second annotation
        horizontalLine1
          .normal()
          .stroke(
            otherSettings?.[theme]?.stopLoss?.color,
            otherSettings?.[theme]?.stopLoss?.thickness,
            otherSettings?.[theme]?.stopLoss?.lineType
          );
        // horizontalLine1.hovered().stroke("#00b300", 2, "10 2");
        // horizontalLine1.selected().stroke("#00b300", 4, "10 2");
        horizontalLine1.id = controller.getAnnotationsCount() - 1;

        plotPriceMarker(
          controller.getAnnotationsCount() - 1,
          +topLossAt.price,
          "#FF0000"
        );
      }

      if (targetAt) {
        // create the second Horizontal Line annotation
        var controller = plot.annotations();
        var horizontalLine2 = controller.horizontalLine();

        // set the position of the second annotation
        horizontalLine2.valueAnchor(+targetAt.price);

        // configure the visual settings of the second annotation
        horizontalLine2
          .normal()
          .stroke(
            otherSettings?.[theme]?.target?.color,
            otherSettings?.[theme]?.target?.thickness,
            otherSettings?.[theme]?.target?.lineType
          );
        // horizontalLine2.hovered().stroke("#00b300", 2, "10 2");
        // horizontalLine2.selected().stroke("#00b300", 4, "10 2");
        horizontalLine2.id = controller.getAnnotationsCount() - 1;

        plotPriceMarker(
          controller.getAnnotationsCount() - 1,
          +targetAt.price,
          "#00a12b"
        );
      }
      chart.listen("click", function (e) {
        const annotationCount = plot
          .annotations()
          .toJson()
          ?.annotationsList?.filter((ele) => ele?.type !== "marker");

        // if (plot.annotations().getAnnotationsCount() < 2) {
        if (annotationCount?.length < 2) {
          drawLineValue = plot
            .annotations()
            .startDrawing("horizontal-line")
            .color("#000000");
          drawLineValue.id = plot.annotations().getAnnotationsCount() - 1;
          // drawLineValue.id = annotationCount?.length - 1;
        }
        props?.setCurrenChart && setCurrenChart(plot);
        props?.setOrderCurrentChart && setOrderCurrentChart(chart);
      });

      chart.listen("annotationUnselect", function (e) {
        let line = plot
          .annotations()
          .getAnnotationAt(e.annotation.id)
          .color(
            getTagColor(stockTag, e.annotation.Oa.valueAnchor, lastPrice).color
          );

        let markerColor =
          line.color()?.toLowerCase() === "#00ff00" ? "#00a12b" : line.color();
        plotPriceMarker(
          e.annotation.id,
          e.annotation.Oa.valueAnchor.toFixed(2),
          markerColor,
          99
        );
      });

      chart.listen("annotationDrawingFinish", function (e) {
        const { color, position } = getTagColor(
          stockTag,
          e.annotation.Oa.valueAnchor,
          lastPrice,
          true
        );
        plot.annotations().getAnnotationAt(e.annotation.id).color(color);
        props?.setCurrenChart && setCurrenChart(plot);
        props?.setOrderCurrentChart && setOrderCurrentChart(chart);

        let markerColor =
          color?.toLowerCase() === "#00ff00" ? "#00a12b" : color;
        plotPriceMarker(
          e.annotation.id,
          e.annotation.Oa.valueAnchor.toFixed(2),
          markerColor,
          999
        );
      });

      chart.listen("annotationChangeStart", function (e) {
        let line = plot
          .annotations()
          .getAnnotationAt(e.annotation.id)
          .color(
            getTagColor(stockTag, e.annotation.Oa.valueAnchor, lastPrice).color
          );
        props?.setCurrenChart && setCurrenChart(plot);
        props?.setOrderCurrentChart && setOrderCurrentChart(chart);

        let markerColor =
          line.color()?.toLowerCase() === "#00ff00" ? "#00a12b" : line.color();
        plotPriceMarker(
          e.annotation.id,
          e.annotation.Oa.valueAnchor.toFixed(2),
          markerColor,
          999
        );
      });

      chart.listen("annotationChange", function (e) {
        let line = plot
          .annotations()
          .getAnnotationAt(e.annotation.id)
          .color(
            getTagColor(stockTag, e.annotation.Oa.valueAnchor, lastPrice).color
          );
        props?.setCurrenChart && setCurrenChart(plot);
        props?.setOrderCurrentChart && setOrderCurrentChart(chart);

        let markerColor =
          line.color()?.toLowerCase() === "#00ff00" ? "#00a12b" : line.color();
        plotPriceMarker(
          e.annotation.id,
          e.annotation.Oa.valueAnchor.toFixed(2),
          markerColor,
          999
        );
      });

      chart.listen("annotationChangeFinish", function (e) {
        let line = plot.annotations().getAnnotationAt(e.annotation.id).color(
          // getTagColor(stockTag, e.annotation.Oa.valueAnchor, lastPrice, true)//today changes
          getTagColor(stockTag, e.annotation.Oa.valueAnchor, lastPrice).color
        );
        props?.setCurrenChart && setCurrenChart(plot);
        props?.setOrderCurrentChart && setOrderCurrentChart(chart);

        props?.setOpenModel &&
          props?.setOpenModel({ [JSON.parse(stockToken)]: true });

        let markerColor =
          line.color()?.toLowerCase() === "#00ff00" ? "#00a12b" : line.color();
        plotPriceMarker(
          e.annotation.id,
          e.annotation.Oa.valueAnchor.toFixed(2),
          markerColor,
          999
        );
      });
      props?.setCurrenChart && setCurrenChart(plot);
      props?.setOrderCurrentChart && setOrderCurrentChart(chart);
    }

    if (isDrawingButtonActive || isDrawingChart) {
      //select chart for drawing on click
      chart.listen("click", function (e) {
        props?.setDrawingChart && setDrawingChart({ chart, token: stockToken, interval: headerData?.interval });
      });

      if(activeChart || isDrawingChart){
        props?.setDrawingChart && setDrawingChart({ chart, token: stockToken, interval: headerData?.interval });
      }
    }

    if (!loading) {
      chart.container(stage).draw();
      if (isDrawingButtonActive || isDrawingChart) {
        drawingAnnotationLogic(chart);
        drawAnnotationsWithData(chart);
      }
      return chart;
    }
  };

  const drawingAnnotationLogic = (chart) => {
    if (chart) {
      //<<<<<<<<<<<<<<<<<<< Annotation Select >>>>>>>>>>>>>>>>>
      chart.listen("annotationSelect", function (event) {
        let selectedAnnotation = event?.annotation;
        props?.setDrawingChart && setDrawingChart({ chart, token: stockToken, interval: headerData?.interval });
      });

      // //<<<<<<<<<<<<<<<<<<< Annotation Unselect >>>>>>>>>>>>>>>>>
      chart.listen("annotationUnselect", function (event) {
        var unselectAnnotation = event.annotation;
        dispatch(manageChartInstance({ chart, token: JSON.parse(stockToken), interval: headerData?.interval }));
        // props?.setDrawingChart && setDrawingChart(null);
      });

      //<<<<<<<<<<<<<<<<<<< Annotation Change >>>>>>>>>>>>>>>>>
      chart.listen("annotationChange", function (event) {
        let changeAnnotation = event.annotation;
      });

      //<<<<<<<<<<<<<<<<<<< Annotation Change Start >>>>>>>>>>>>>>>>>
      chart.listen("annotationChangeStart", function (event) {
        let changeStartAnnotation = event.annotation;
      });

      // //<<<<<<<<<<<<<<<<<<< Annotation Change Finish >>>>>>>>>>>>>>>>>
      chart.listen("annotationChangeFinish", function (event) {
        dispatch(manageChartInstance({ chart, token: stockToken, interval: headerData?.interval }));
        let finishAnnotationChange = event.annotation;

        let myData = chart.plot(0).annotations().toJson();
        let dataToStore = myData?.annotationsList;

        if (stockToken) {
          let annotationData = {
            token: JSON.parse(stockToken),
            data: dataToStore,
            chartType: chartView,
            interval: Constants.annotationTimeFrames[headerData?.interval],
          };
          handleUpdateAnnotation(annotationData);
        }
      });

      // //<<<<<<<<<<<<<<<<<<< Annotation Drawing Finish >>>>>>>>>>>>>>>>>
      chart.listen("annotationDrawingFinish", function (event) {
        let finishAnnotationDrawing = event.annotation;

        let myData = chart.plot(0).annotations().toJson();
        let dataToStore = myData?.annotationsList;
        if (stockToken) {
          let annotationData = {
            token: +stockToken,
            data: dataToStore,
            chartType: chartView,
            interval: Constants.annotationTimeFrames[headerData?.interval],
          };

          handleUpdateAnnotation(annotationData);
        }
      });
    }
  };

  //Draw saved data
  function drawAnnotationsWithData(chart) {
    if (chart) {
      let savedData;

      if (chartDrawingData?.length > 0) {
        savedData = chartDrawingData?.find(
          (item) => item?.token == +stockToken && item?.chartType == chartView
        );
      }

      const controller = chart.plot(0).annotations();

      if (
        savedData &&
        savedData?.data[Constants.annotationTimeFrames[headerData?.interval]]
          ?.length > 0
      ) {
        savedData?.data[
          Constants.annotationTimeFrames[headerData?.interval]
        ]?.forEach((annotationData) => {
          if (annotationData?.type == "ellipse") {
            controller.ellipse(annotationData);
          }
          if (annotationData?.type == "triangle") {
            controller.triangle(annotationData);
          }
          if (annotationData?.type == "trend-channel") {
            controller.trendChannel(annotationData);
          }
          if (annotationData?.type == "line") {
            controller.line(annotationData);
          }
          if (annotationData?.type == "andrews-pitchfork") {
            controller.andrewsPitchfork(annotationData);
          }
          if (annotationData?.type == "horizontal-line") {
            controller.horizontalLine(annotationData);
          }
          if (annotationData?.type == "fibonacci-fan") {
            controller.fibonacciFan(annotationData);
          }
          if (annotationData?.type == "vertical-line") {
            controller.verticalLine(annotationData);
          }
          if (annotationData?.type == "fibonacci-arc") {
            controller.fibonacciArc(annotationData);
          }
          if (annotationData?.type == "infinite-line") {
            controller.infiniteLine(annotationData);
          }
          if (annotationData?.type == "fibonacci-retracement") {
            controller.fibonacciRetracement(annotationData);
          }
          if (annotationData?.type == "ray") {
            controller.ray(annotationData);
          }
          if (annotationData?.type == "fibonacci-timezones") {
            controller.fibonacciTimezones(annotationData);
          }
          if (annotationData?.type == "marker") {
            controller.marker(annotationData);
          }
          if (annotationData?.type == "rectangle") {
            controller.rectangle(annotationData);
          }
        });
      }
    }
  }

  return (
    <>
    <Index.Box className="custom_candlestick_chart">
    {displaySettings?.OHLCValues === "on" && (
          <Index.Stack direction="row" className="chart_ohcl">
            <Index.Typography
              sx={{ fontSize: parentWidth * 0.03 }}
              variant="caption"
              color="#5A76B4"
            >
              O : {(+open)?.toFixed(2)?.toString().trim()}; &nbsp;
            </Index.Typography>
            <Index.Typography
              sx={{ fontSize: parentWidth * 0.03 }}
              variant="caption"
              color="#00FF00"
            >
              H : {(+high)?.toFixed(2)?.toString().trim()}; &nbsp;
            </Index.Typography>
            <Index.Typography
              sx={{ fontSize: parentWidth * 0.03 }}
              variant="caption"
              color="#FF0000"
            >
              L : {(+low)?.toFixed(2)?.toString().trim()}; &nbsp;
            </Index.Typography>
            <Index.Typography
              sx={{ fontSize: parentWidth * 0.03 }}
              variant="caption"
              color="#5A76B4"
            >
              C : {(+close)?.toFixed(2)?.toString().trim()};
            </Index.Typography>
          </Index.Stack>
        )}
      {loading ? (
        <Index.Skeleton
          animation="wave"
          sx={{ bgcolor: "grey.1000" }}
          variant="rectangular"
          width={"100%"}
          height={isModalChart ? props?.height : chartHeight}
        />
      ) : (
        <AnyChart
          id={chartId}
          data={data1}
        />
      )}
    </Index.Box>
    </>
  );
};

export default memo(CustomCendalStickChartSingle);

import React, { useEffect, useState } from "react";
import {
  BrowserRouter,
  Routes,
  Route,
  Outlet,
  Navigate,
} from "react-router-dom";
import Login from "../container/user/auth/Login";
import Register from "../container/user/auth/Register";
import ForgotPassword from "../container/user/auth/ForgotPassword";
import OtpVerification from "../container/user/auth/OtpVerification";
import ResetPassword from "../container/user/auth/ResetPassword";
import CreateNewPassword from "../container/user/auth/CreateNewPassword";
import Dashboard from "../container/user/pages/Dashboard";
import OneColumn from "../container/user/pages/OneColumn";
import TwoColumn from "../container/user/pages/TwoColumn";
import AnalysisWindow from "../container/user/pages/AnalysisWindow";
import OpenOrderList from "../container/user/pages/OpenOrderList";
import TrackingWindow from "../container/user/pages/TrackingWindow";
import IntradayTab from "../container/user/pages/IntradayTab";
import Profile from "../container/user/pages/Profile";
import SixColumn from "../container/user/pages/SixColumn";
import NineColumn from "../container/user/pages/NineColumn";
import PerformanceReport from "../container/user/pages/PerformanceReport";
import { ToastContainer } from "react-toastify";
import Header from "../component/DefaultLayout/Header";
import PrivateLayout from "./private/PrivateLayout";
import PublicLayout from "./public/PublicLayout";
import SideBar from "../component/DefaultLayout/SideBar";

// import UseKeyPress from "../component/common/keyboard-shortcuts/UseKeyPress";
// import KeyBoardShortcuts from "../component/common/keyboard-shortcuts/KeyBoardShortcuts";
// import UseKeyPress from "../component/common/keyboard-shortcuts/UseKeyPressEvent";
import UseKeyPressEvent from "../component/common/keyboard-shortcuts/UseKeyPressEvent";

import UserList from "../container/user/pages/userManagement/UserList";
import AddEditUser from "../container/user/pages/userManagement/AddEditUser";
import UserTaggedScripts from "../container/user/pages/userTaggedScripts/UserTaggedScripts";
import Notification from "../container/user/pages/Notification";
import Report from "../container/user/pages/Report/Report";
import SingleStockView from "../container/user/pages/SingleStockView";
import FavoriteList from "../container/user/pages/favourite/FavoriteList";
import TrackingWindowCharts from "../container/user/pages/TrackingWindowCharts";
import IndexManagement from "../container/user/pages/index-management/IndexManagement";
import IndexStocksList from "../container/user/pages/index-management/IndexStocksList";

const Routers = (props) => {
  const Layout = (props) => {
    return (
      <>
        <Header socket={props.socket} />
        <SideBar />

        <Outlet />  
      </>
    );
  };

  return (
    <>
      <ToastContainer />
      <BrowserRouter>
        <UseKeyPressEvent>
          {/* <KeyBoardShortcuts> */}
          <Routes>
            <Route element={<PublicLayout />}>
              <Route path="/" element={<Login />} />
              <Route path="/register" element={<Register />} />
              <Route path="forgot-password" element={<ForgotPassword />} />
              <Route path="otp-verification" element={<OtpVerification />} />
              <Route path="reset-password" element={<ResetPassword />} />
            </Route>

            {/* start user */}

            <Route element={<PrivateLayout />}>
              <Route path="user" element={<Layout socket={props.socket} />}>
                <Route index element={<Navigate to="dashboard" replace />} />
                <Route
                  path="dashboard"
                  element={<Dashboard socket={props.socket} />}
                />
                <Route path="one-column" element={<OneColumn />} />
                <Route path="report" element={<Report />} />
                <Route path="two-column" element={<TwoColumn />} />
                <Route
                  path="analysis-window"
                  element={<AnalysisWindow socket={props.socket} />}
                />
                <Route path="open-order-list" element={<OpenOrderList />} />
                <Route path="tracking-window" element={<TrackingWindow socket={props.socket} />} />
                <Route
                  path="tracking-window-charts"
                  element={<TrackingWindowCharts socket={props.socket} />}
                />
                <Route path="intraday-tab" element={<IntradayTab />} />
                <Route path="profile" element={<Profile />} />
                <Route path="six-column" element={<SixColumn />} />
                <Route path="nine-column" element={<NineColumn />} />
                <Route path="add" element={<AddEditUser />} />
                <Route path="edit" element={<AddEditUser />} />
                <Route path="add-edit/:id" element={<AddEditUser />} />
                <Route path="user-management" element={<UserList />} />
                <Route path="notifications" element={<Notification />} />
                <Route
                  path="users-tagged-scripts"
                  element={<UserTaggedScripts />}
                />
                <Route
                  path="users-tagged-scripts/:id"
                  element={<UserTaggedScripts />}
                />
                <Route
                  path="create-new-password"
                  element={<CreateNewPassword />}
                />
                <Route
                  path="performance-report"
                  element={<PerformanceReport />}
                />
                <Route path="view-single-chart" element={<SingleStockView />} />
                <Route path="favorite" element={<FavoriteList />} />
                <Route path="index-management/">
                  <Route path="" element={<IndexManagement />} />
                  <Route path="detail" element={<IndexStocksList />} />
                </Route>
              </Route>
            </Route>

            {/* end user */}
            <Route path="*" element={<h4>No Page found</h4>} />
          </Routes>
          {/* </KeyBoardShortcuts> */}
        </UseKeyPressEvent>
      </BrowserRouter>
    </>
  );
};

export default Routers;

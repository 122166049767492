export default {
  ADMIN_ERROR_HANDLER: "ADMIN_ERROR_HANDLER",

  ADMIN_LOGIN_SUCCESS: "ADMIN_LOGIN_SUCCESS",

  USER_ERROR_HANDLER: "USER_ERROR_HANDLER",

  USER_LOGIN_SUCCESS: "USER_LOGIN_SUCCESS",
  USERLOGOUT: "USERLOGOUT",
  fetchUserLoginSuccess: "fetchAdminLoginSuccess",
  UserlogOutSuccess: "AdminlogOutSuccess",
  graphType: "GraphType",
  graphStyle: "GraphStyle",

  EDIT_PROFILE: "EDIT_PROFILE",
  angelListData: "AngelListData",
  wishListData: "WishListData",
  setChartDuration: "SetChartDuration",
  timeFrame: "TimeFrame",
  headerSearchBar: "HeaderSearchBar",
  defaultSettingModal: "DefaultSettingModal",
  chartView: "ChartView",
  liveData: "LiveData",
  gridView: "GridView",
  pageSetting: "PageSetting",

  GETALLSHORTCUTS: "getAllShortcuts",
  activeShortCut:"activeShortCut",
  currentPagePlus:"currentPagePlus",
  currentPageMinus:"currentPageMinus",
  wishListDataRemove:"wishListDataRemove",
  wishListDataView:"wishListDataView",
  sortingWishList:"sortingWishList",
  getTagListAll:"getTagListAll",
  MANAGE_CURRENT_TAG:"MANAGE_CURRENT_TAG",
  MANAGE_GRID_SPACING:"MANAGE_GRID_SPACING",
  MANAGE_GRID_BORDER:"MANAGE_GRID_BORDER",
  MANAGE_GRID_COLOR:"MANAGE_GRID_COLOR",
  SELECTED_SCRIPT:"SELECTED_SCRIPT",
  OTHER_SETTING:"OTHER_SETTING",
  DISPLAY_SETTINGS:"DISPLAY_SETTINGS",
  DRAW_CHART:"DRAW_CHART",
  POSITIONAL_DRAW_CHART:"POSITIONAL_DRAW_CHART",
  INTRADAY_DRAW_CHART:"INTRADAY_DRAW_CHART",
  ACTIVE_CHART_HOVER:"ACTIVE_CHART_HOVER",
  TRACKING_ACTIVE_CHART_HOVER:"TRACKING_ACTIVE_CHART_HOVER",
  DOWNLOAD_CHART_TOKEN:"DOWNLOAD_CHART_TOKEN",
  MINI_CHART_SIZE:"MINI_CHART_SIZE",
  SHOW_HIDE_DRAWING_BUTTON:"SHOW_HIDE_DRAWING_BUTTON",
  DRAWING_TOOL_ACTIVE_DEACTIVE:"DRAWING_TOOL_ACTIVE_DEACTIVE",
  DRAWING_TOOLS_LIST:"DRAWING_TOOLS_LIST",
  TOOL_ORIENTATION:"TOOL_ORIENTATION",
  CHART_INSTANCE:"CHART_INSTANCE",
  RESET_STATE:"RESET_STATE",
  FLIP_STATE:"FLIP_STATE",
  ROWS_PER_PAGE:"ROWS_PER_PAGE",
  CATEGORY_FILTER:"CATEGORY_FILTER",
  CHART_VIEW_STATE: "CHART_VIEW_STATE",
  DEFAULT_SETTINGS: "DEFAULT_SETTINGS",
  SORTING_TAGS: "SORTING_TAGS",
  CUSTOM_SCRIPTS: "CUSTOM_SCRIPTS",
  EXIT_ALL_MODAL: "EXIT_ALL_MODAL",
  LIST_SETTINGS: "LIST_SETTINGS",
  TAGGED_STOCKS_COUNT: "TAGGED_STOCKS_COUNT",
  SIDEBAR_VISIBILITY: "SIDEBAR VISIBILITY",
  MODAL_PARAMETERS:"MODAL PARAMETERS",
  TWO_SIDE_ARROW_ZOOM:"TWO_SIDE_ARROW_ZOOM",
  ERASE_DRAWING:"ERASE_DRAWING",
  CURRENT_PAGE:"CURRENT_PAGE",
  USERS_DISPLAY_SETTING:"USERS_DISPLAY_SETTING",
  SORTING:"SORTING",
  TRACKING_WINDOW_STOCKS:"TRACKING_WINDOW_STOCKS",
  DRAWING_CHART_TOKEN:"DRAWING_CHART_TOKEN",
  MANAGE_PREVIOUS_GRID_BORDER:"MANAGE_PREVIOUS_GRID_BORDER",
  NOTIFICATION_COUNT:"NOTIFICATION_COUNT",
  DEFAULT_THEME:"DEFAULT_THEME",
  MANAGE_SETTINGS:"MANAGE_SETTINGS",
  MANAGE_AUTO_SQUARE_TIME:"MANAGE_AUTO_SQUARE_TIME",
};

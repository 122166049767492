import React, { useEffect, useState } from "react";

import Index from "../../../Index";
import { Formik } from "formik";

import * as yup from "yup";

import {
  addUser,
  getSingleUser,
  updateUser,
} from "../../../../service/UserIndex";
import { toast } from "react-toastify";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { activeShortCut } from "../../../../redux/user/action";

const AddEditUser = () => {
  const { displaySettings, twoSideArrowZoom } = Index.useSelector(
    (state) => state.UserReducer
  );
  const { state } = useLocation();
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const navigate = useNavigate();

  const addEditUser = (values, { resetForm }) => {
    if (state.row._id) {
      const data = {
        ...values,
        id: state.row._id,
      };
      updateUser(data)
        .then((res) => {
          if (res?.status == 200) {
            // toast.success(res?.message);
            navigate("/user/user-management");
            resetForm();
          } else {
            toast.error(res?.message);
            resetForm();
          }
        })
        .catch((e) => toast.error(e?.message));
    } else {
      addUser(values)
        .then((res) => {
          if (res?.status == 200) {
            // toast.success(res?.message);
            navigate("/user/user-management");
            resetForm();
          } else {
            toast.error(res?.message);
            // resetForm();
          }
        })
        .catch((e) => toast.error(e?.message));
    }
  };

  const dispatch = useDispatch();

  const initialValues = {
    firstName: state.row?.firstName ? state.row?.firstName : "",
    lastName: state.row?.lastName ? state.row?.lastName : "",
    email: state.row?.email ? state.row?.email : "",
    password: state.row?.password ? state.row?.password : "",
    userType: state.row?.userType ? state.row?.userType : "",
  };

  const validationSchemaUser = yup.object().shape({
    firstName: yup
      .string()
      .required("Please enter first name")
      // .min(3, "First name must be at least 3 characters long")
      .max(100, "First name must be at most 16 characters long"),
    lastName: yup
      .string()
      .required("Please enter last name")
      // .min(3, "Last name must be at least 3 characters long")
      .max(100, "Last name must be at most 16 characters long"),
    email: yup
      .string()
      .email("Please enter valid email")
      .matches(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        "Please enter valid email"
      )
      .required("Please enter email"),
    password: state?.row?._id
      ? yup.string().notRequired()
      : yup
          .string()
          .required("Please enter password")
          .max(16, "Password must be at most 16 characters long")
          .matches(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#@$!%*?&])[A-Za-z\d#@$!%*?&]{8,}$/,
            "Must contain 8 characters, one uppercase, one lowercase, one number and one special case characters."
          ),
    userType: yup.string().required("Please select user type"),
  });

  return (
    <>
      <Index.Box className="profile-main">
        <Index.Header></Index.Header>
        <Index.Overlay></Index.Overlay>
        <Index.Box className="dashboard-wrapper">
          <Index.Box
            className={`main-content-sec profile-content-sec ${
              displaySettings?.UtilityBar == "left"
                ? "main-content-sec-right"
                : ""
            } ${twoSideArrowZoom && "hide-main-content-margin"}`}
          >
            <Formik
              validationSchema={validationSchemaUser}
              initialValues={initialValues}
              onSubmit={addEditUser}
            >
              {({
                handleSubmit,
                values,
                errors,
                handleBlur,
                handleChange,
                touched,
              }) => (
                <Index.Box
                  className="profile-wrapper"
                  component="form"
                  onSubmit={handleSubmit}
                >
                  <Index.Typography
                    className="profile-title"
                    variant="h1"
                    component="h1"
                  >
                    {state.row._id ? "Edit User" : "Add User"}
                  </Index.Typography>
                  <Index.Box className="profile-input-wrapper">
                    <Index.Box className="cus-input-grp">
                      <Index.Typography
                        className="cus-lable light-font"
                        variant="p"
                        component="p"
                      >
                        First Name
                      </Index.Typography>
                      <Index.Box className="form-group">
                        <Index.TextField
                          fullWidth
                          name="firstName"
                          value={values.firstName}
                          className="form-control"
                          placeholder="Enter first name"
                          autoComplete="off"
                          onChange={(e) => {
                            let value = e.target.value;
                            if (
                              (/^[A-Za-z]+$/.test(value) || value === "") &&
                              value?.length <= 20
                            )
                              handleChange(e);
                          }}
                          onKeyDown={(e) => {
                            if (e.key === " " && e.target.value.trim() === "") {
                              e.preventDefault();
                            }
                          }}
                          error={
                            errors.firstName && touched.firstName ? true : false
                          }
                          helperText={
                            errors.firstName && touched.firstName
                              ? errors.firstName
                              : null
                          }
                          // onBlur={handleBlur}
                          onBlur={(e) => {
                            handleBlur(e);
                            dispatch(activeShortCut(true));
                          }}
                          onFocus={(e) => {
                            dispatch(activeShortCut(false));
                          }}
                        />
                      </Index.Box>
                    </Index.Box>
                    <Index.Box className="cus-input-grp">
                      <Index.Typography
                        className="cus-lable light-font"
                        variant="p"
                        component="p"
                      >
                        Last Name
                      </Index.Typography>
                      <Index.Box className="form-group">
                        <Index.TextField
                          name="lastName"
                          fullWidth
                          className="form-control"
                          placeholder="Enter last name"
                          value={values.lastName}
                          autoComplete="off"
                          onChange={(e) => {
                            let value = e.target.value;
                            if (
                              (/^[A-Za-z]+$/.test(value) || value === "") &&
                              value?.length <= 20
                            )
                              handleChange(e);
                          }}
                          onKeyDown={(e) => {
                            if (e.key === " " && e.target.value.trim() === "") {
                              e.preventDefault();
                            }
                          }}
                          error={
                            errors.lastName && touched.lastName ? true : false
                          }
                          helperText={
                            errors.lastName && touched.lastName
                              ? errors.lastName
                              : null
                          }
                          // onBlur={handleBlur}
                          onBlur={(e) => {
                            handleBlur(e);
                            dispatch(activeShortCut(true));
                          }}
                          onFocus={(e) => {
                            dispatch(activeShortCut(false));
                          }}
                        />
                      </Index.Box>
                    </Index.Box>
                    <Index.Box className="cus-input-grp">
                      <Index.Typography
                        className="cus-lable light-font"
                        variant="p"
                        component="p"
                      >
                        Email
                      </Index.Typography>
                      <Index.Box className="form-group">
                        <Index.TextField
                          name="email"
                          fullWidth
                          className="form-control"
                          placeholder="Enter email"
                          value={values.email}
                          onKeyDown={(e) => {
                            if (e.key === " " && e.target.value.trim() === "") {
                              e.preventDefault();
                            }
                          }}
                          autoComplete="off"
                          onChange={handleChange}
                          error={errors.email && touched.email ? true : false}
                          helperText={
                            errors.email && touched.email ? errors.email : null
                          }
                          // onBlur={handleBlur}
                          onBlur={(e) => {
                            handleBlur(e);
                            dispatch(activeShortCut(true));
                          }}
                          onFocus={(e) => {
                            dispatch(activeShortCut(false));
                          }}
                        />
                      </Index.Box>
                    </Index.Box>
                    {state.row._id ? (
                      <></>
                    ) : (
                      <>
                        <Index.Box className="cus-input-grp register-password">
                          <Index.Typography
                            className="cus-lable light-font"
                            variant="p"
                            component="p"
                          >
                            Password
                          </Index.Typography>
                          <Index.Box className="form-group">
                            <Index.OutlinedInput
                              className="form-control custom_form_control"
                              id="outlined-adornment-password"
                              sx={{ background: "red" }}
                              name="password"
                              fullWidth
                              value={values.password}
                              // onBlur={handleBlur}
                              onBlur={(e) => {
                                handleBlur(e);
                                dispatch(activeShortCut(true));
                              }}
                              onFocus={(e) => {
                                dispatch(activeShortCut(false));
                              }}
                              // onFocus={handleFocus}
                              onChange={handleChange}
                              helperText={touched.password && errors.password}
                              error={Boolean(
                                errors.password && touched.password
                              )}
                              placeholder="Enter password"
                              type={showPassword ? "text" : "password"}
                              inputProps={{ autoComplete: "new-password" }}
                              endAdornment={
                                <Index.InputAdornment
                                  position="end"
                                  className="pass_position"
                                >
                                  <Index.IconButton
                                    className="icon_end_icon"
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                  >
                                    {showPassword ? (
                                      <Index.VisibilityOff />
                                    ) : (
                                      <Index.Visibility />
                                    )}
                                  </Index.IconButton>
                                </Index.InputAdornment>
                              }
                            />

                          </Index.Box>
                            <Index.FormHelperText error className="error-text">
                              {errors.password && touched.password
                                ? errors.password
                                : null}
                            </Index.FormHelperText>
                        </Index.Box>
                      </>
                    )}

                    <Index.Box className="cus-input-grp index-select">
                      <Index.Typography
                        className="cus-lable light-font"
                        variant="p"
                        component="p"
                      >
                        User Type
                      </Index.Typography>
                      <Index.Box className="form-group">
                        <Index.FormControl className="form-control-user drop-form-control custom-select-box">
                          <Index.Select
                            fullWidth
                            name="userType"
                            className="form-control"
                            displayEmpty
                            value={values.userType}
                            placeholder="User Type"
                            onChange={(e) => {
                              handleChange(e);
                            }}
                          >
                            <Index.MenuItem
                              disabled
                              value=""
                              className="form-control"
                            >
                              User Type
                            </Index.MenuItem>
                            <Index.MenuItem
                              value="user"
                              name="user"
                              className="drop-menuitem game-item"
                            >
                              User
                            </Index.MenuItem>
                            <Index.MenuItem
                              value="admin"
                              name="admin"
                              className="drop-menuitem game-item"
                            >
                              Admin
                            </Index.MenuItem>
                          </Index.Select>
                        </Index.FormControl>
                        <Index.FormHelperText error className="error-text">
                          {errors.userType && touched.userType
                            ? errors.userType
                            : null}
                        </Index.FormHelperText>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>

                  <Index.PrimaryButton
                    className="primary-btn save-changes-btn"
                    btnLabel="Save"
                    type="submit"
                  />
                </Index.Box>
              )}
            </Formik>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </>
  );
};

export default AddEditUser;

import { useState, useEffect } from "react";
import Index from "../../Index";

const useCurrentInterval = (interval, chartLiveData, isMCXMarket = false) => {
  const [currentInterval, setCurrentInterval] = useState(null);
  const [nextInterval, setNextInterval] = useState(null);

  useEffect(() => {
    const currentTime = Index.moment();
    const time = isMCXMarket ? "09:00:00" : "09:15:00";
    const baseTime = Index.moment(time, "HH:mm:ss").valueOf();
    const elapsedTime = currentTime.valueOf() - baseTime;
    const currentIntervalNumber = Math.floor(
      elapsedTime / (interval * 60 * 1000)
    );
    const currentIntervalStart =
      baseTime + currentIntervalNumber * interval * 60 * 1000;

    const nextIntervalStart = currentIntervalStart + interval * 60 * 1000;

    setCurrentInterval(
      Index.moment(currentIntervalStart).startOf("minute").valueOf()
    );
    setNextInterval(
      Index.moment(nextIntervalStart).startOf("minute").valueOf()
    );
  }, [interval, chartLiveData]);

  return [currentInterval, nextInterval];
};

export default useCurrentInterval;

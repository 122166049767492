import React, { useState, useEffect, useRef } from "react";
import Index from "../../Index";
import { useDispatch, useSelector } from "react-redux";
import * as htmlToImage from "html-to-image";
import { jsPDF } from "jspdf";
import {
  manageDownloadChartData,
  manageTrackingActiveChartHoverData,
} from "../../../redux/user/action";
import CustomLineChartSingle from "../../../component/custom-chart/customLineChartSingle/CustomLineChartSingle";
import CustomAreaChartSingle from "../../../component/custom-chart/custom-single-charts/CustomAreaChartSingle";
import CustomDashedChartSingle from "../../../component/custom-chart/custom-single-charts/CustomDashedChartSingle";
import CustomCendalStickChartSingle from "../../../component/custom-chart/custom-single-charts/CustomCendalStickChartSingle";
import Constants from "../../../component/common/constants";
import usePreviousDateCalculator from "../../../component/common/previous-date-calculator/usePreviousDateCalculator";

const setDatefun = (data) => {
  let d = new Date(data);
  let h = d.getHours() + 5;
  let m = d.getMinutes() + 30;
  d.setHours(h, m, 0, 0);
  return d.getTime();
};

const OneColumnTrackingWindow = (props) => {
  const {
    index,
    item,
    length,
    active,
    selectedId,
    setOpenModel,
    openModel,
    deleteLine,
    handleClose,
    updateOrder,
    setUpdateOrder,
    editOrderData,
    setEditOrderData,
    removeStockTag,
    placeOrderData,
    setPlaceOrderData,
    currenChart,
    setCurrenChart,
    setTargetOrTopLoss,
    orderCurrentChart,
    setOrderCurrentChart,
    setDrawingChart,
    setAllTagsData,
    scriptData,
    setScriptData,
    fromDate,
    live,
    handleUpdateStock,
    handleUpdateAnnotation,
    openDrawingChartModal,
  } = props;

  const dispatch = useDispatch();

  const {
    token,
    graphType,
    chartDuration,
    chartView,
    gridView,
    gridBorder,
    gridColor,
    downloadChartToken,
    activeTrackingChartHover,
    theme,
  } = useSelector((state) => state.UserReducer);

  const themeTitle = localStorage.getItem("default-theme");

  const [headMenu, setHeadMenu] = useState(false);
  const [tags, setTags] = useState("");

  const [chartData, setChartData] = useState([]);
  const abortControllers = useRef([]);
  const [previousClosePrice, setPreviousClosePrice] = useState(null);
  const [chartLiveData, setChartLiveData] = useState();

  useEffect(() => {
    if (props?.socket) {
      if (item?.token && chartView == "Intraday") {
        props.socket.emit("joinRoom", `token_${JSON.parse(item?.token)}`);

        props.socket.on(`token_${JSON.parse(item?.token)}`, (data) => {
          setChartLiveData(data);
        });
      }

      return () => {
        props.socket.emit("leaveRoom", `token_${item?.token}`);
      };
    }
  }, [props.socket, chartView]);

  function getGrid() {
    if (length < 3) {
      return {
        grid: 12 / gridView?.row || 1,
        height: gridView?.column,
        class: "main-chart-box",
      };
    } else if (length < 6) {
      return {
        grid: 12 / gridView?.row || 4,
        height: gridView?.column,
        class: "small-chart-box",
      };
    } else if (length < 9) {
      return {
        grid: 12 / gridView?.row || 6,
        height: gridView?.column,
        class: "hide-chart-header",
      };
    } else if (length >= 9) {
      return {
        grid: 12 / gridView?.row || 9,
        height: gridView?.column,
        class: "hide-chart-header",
      };
    }
  }

  const previousDate = usePreviousDateCalculator(1);

  const getAllHistoryData = async () => {
    setChartData([]);
    const controller = new AbortController();
    abortControllers.current.push(controller);
    const signal = { signal: controller.signal };
    const intervalData = Constants.chartInterval[chartDuration[chartView]?.interval];
    const minutesToLess = intervalData?.value * 60000;

    const formData = {
      tokens: [{ token: props?.token, type: props?.marketType }],
      interval: intervalData?.interval,
      fromDate,
      toDate:
        Index.moment()
          .format("YYYY-MM-DD"),
      offset: intervalData?.offset,
    };
    const formDataIntraday = {
      tokens: [{ token: props?.token, type: props?.marketType }],
      interval: intervalData?.interval,
      fromDate:
        Index.moment()
          .format("YYYY-MM-DD"),
      toDate:
        Index.moment()
          .format("YYYY-MM-DD"),
      offset: intervalData?.offset,
      previousDate,
    };
    try {
      const apiCalls = live
        ? [
            Index.DataService(token).post(
              Index.Api.user.getIntradayData,
              formDataIntraday,
              signal
            ),
          ]
        : [
            Index.DataService(token).post(
              Index.Api.user.getIntradayData,
              formDataIntraday,
              signal
            ),
            Index.DataService(token).post(
              Index.Api.user.getAllHistoricalData,
              formData,
              signal
            ),
          ];
      const [intradayData, historicalData] = await Promise.all(apiCalls);

      const previousDateData = intradayData?.data?.data?.prevCloseData;
      if (previousDateData && previousDateData[props?.token]) {
        setPreviousClosePrice(previousDateData[props?.token]);
      }

      const combinedData = [
        ...(historicalData?.data?.data || []),
        ...(intradayData?.data?.data?.stockData || []),
      ]?.reduce((acc, item) => {
        const key = Object.keys(item)[0];
        if (!acc[key]) {
          acc[key] = item[key];
        } else {
          acc[key] = [...acc[key], ...item[key]];
        }
        return acc;
      }, {});

      const formattedCombinedData = Object.keys(combinedData)?.map((key) => ({
        [key]: combinedData[key],
      }));

      if (
        formattedCombinedData.length &&
        formattedCombinedData[0]?.[props?.token] &&
        formattedCombinedData[0][props?.token]?.length
      ) {
        const format = formattedCombinedData[0][props?.token].map((el) => {
          const timestamp =
            Index.moment(el[0]).startOf("minute").valueOf() - minutesToLess;
          return [timestamp, +el[1], +el[2], +el[3], +el[4], +el[4]];
        });
        setChartData(format);
      }
    } catch (error) {}
  };

  useEffect(() => {
    getAllHistoryData();
  }, [props?.token, chartView]);

  useEffect(() => {
    return () => {
      abortControllers.current.forEach((controller) => controller.abort());
    };
  }, [chartDuration, chartView]);

  const handleHoverActiveDeactive = (data) => {
    let index = activeTrackingChartHover?.some((ele) => +ele === +data?.token);
    let allToken;
    if (!index) {
      allToken = [...activeTrackingChartHover, JSON.parse(data?.token)];
    } else {
      const removeExistData = activeTrackingChartHover?.filter(
        (ele) => +ele !== +data?.token
      );
      allToken = removeExistData;
    }

    dispatch(manageTrackingActiveChartHoverData(allToken));
  };

  return (
    <Index.Grid
      item
      key={`${item?.token}_${item?.item?._id}`}
      xs={getGrid()?.grid}
      className={`grid-main ${gridView?.row > 3 ? "grid-small" : ""}`}
    >
      <Index.Box
        className={`anychart-download ${
          downloadChartToken.includes(item?.item?._id) &&
          getGrid()?.class === "hide-chart-header"
            ? "small-chart-box"
            : getGrid()?.class
        } ${active ? "active-chart" : ""}`}
        id={`${selectedId !== null ? "active-chart-" + selectedId : ""}`}
        data={item?.token}
        sx={{
          border: `${active ? +gridBorder + "px solid " + gridColor : 0}`,
        }}
        onDoubleClick={() => handleHoverActiveDeactive(item)}
      >
        <Index.Box
          className={`snapshot-chart-box ${
            downloadChartToken.includes(item?.item?._id) &&
            "download-chart-header"
          }`}
          id={`print-${JSON.parse(item?.token)}-${item?.item?._id}`}
        >
          <Index.OrderChartHeader
            live={live}
            entryPrice={item?.entryPrice || "-"}
            defaultTag={item?.tag || ""}
            token={item?.token}
            stockData={chartData}
            isStopLossHit={
              item?.topLossAt?.completed
                ? "Stop loss"
                : item?.targetAt?.completed
                ? "Target"
                : null
            }
            headerData={item?.item}
            stockTag={item?.tag}
            setOpenModel={setOpenModel}
            openModel={openModel}
            deleteLine={deleteLine}
            handleClose={handleClose}
            updateOrder={updateOrder}
            setUpdateOrder={setUpdateOrder}
            placeOrderData={placeOrderData}
            exchange_timestamp={item?.timeStamp}
            setScriptData={setScriptData}
            scriptData={scriptData}
            editOrderData={editOrderData}
            setEditOrderData={setEditOrderData}
            orderCurrentChart={orderCurrentChart}
            setAllTagsData={setAllTagsData}
            removeStockTag={removeStockTag}
            activeChart={active}
            socket={props?.socket}
            livePrice={chartLiveData?.tick || []}
            handleUpdateStock={handleUpdateStock}
          />
          <Index.Box className="chart-body">
            {graphType[theme][chartView] === 0 && (
              <Index.Box className="line-chart-body">
                <CustomLineChartSingle
                  headerData={item?.item}
                  loopIndex={index}
                  isMarketOpen={true}
                  draw={true}
                  isOpenOrderChart={true}
                  liveData={chartLiveData?.tick || chartData[chartData?.length - 1]}
                  index={item?.token}
                  stockToken={JSON.parse(item?.token)}
                  currentPrice={
                    true ? item?.lastTradedPrice : item?.lastTradedPrice
                  }
                  exchange_timestamp={item?.timeStamp}
                  stockTag={item?.tag}
                  targetAt={item?.targetAt}
                  topLossAt={item?.topLossAt}
                  setCurrenChart={setCurrenChart}
                  setUpdateOrder={setUpdateOrder}
                  updateOrder={updateOrder}
                  openModel={openModel}
                  setOpenModel={setOpenModel}
                  setPlaceOrderData={setPlaceOrderData}
                  markerData={{
                    timeStamp: +item?.timeStamp,
                    price: +item?.entryPrice,
                    tag: item?.tag,
                  }}
                  editOrderData={editOrderData}
                  setEditOrderData={setEditOrderData}
                  setOrderCurrentChart={setOrderCurrentChart}
                  setTargetOrTopLoss={setTargetOrTopLoss}
                  activeChart={active}
                  isStopLossHit={
                    item?.topLossAt?.completed ||
                    item?.targetAt?.completed ||
                    false
                  }
                  socket={props?.socket}
                  stockData={chartData}
                  chartLiveData={chartLiveData?.tick || []}
                  live={live}
                  DivideHeight={getGrid()?.height}
                  setDrawingChart={setDrawingChart}
                  isMCXMarket={item?.exchange_type === "MCX"}
                  handleUpdateAnnotation={handleUpdateAnnotation}
                  openDrawingChartModal={openDrawingChartModal}
                />
                {getGrid()?.class === "hide-chart-header" && (
                  <HoverSection
                    chartLiveData={chartLiveData}
                    headMenu={headMenu}
                    setHeadMenu={setHeadMenu}
                    headerData={item?.item}
                    chartData={chartData}
                    live={live}
                    tags={item?.tag}
                    setTags={setTags}
                    allTags={Constants.allTags}
                    index={JSON.parse(item?.token)}
                    downloadsType={Constants.downloadsType}
                    currenChart={currenChart}
                    fromDate={props?.fromDate}
                    previousClosePrice={previousClosePrice}
                    removeStockTag={removeStockTag}
                    setAllTagsData={setAllTagsData}
                    socket={props?.socket}
                    handleUpdateStock={handleUpdateStock}
                  />
                )}
              </Index.Box>
            )}

            {graphType[theme][chartView] === 1 && (
              <Index.Box className="line-chart-body">
                <CustomAreaChartSingle
                  headerData={item?.item}
                  loopIndex={index}
                  isMarketOpen={true}
                  draw={true}
                  isOpenOrderChart={true}
                  liveData={chartLiveData?.tick || chartData[chartData?.length - 1]}
                  index={item?.token}
                  stockToken={JSON.parse(item?.token)}
                  currentPrice={
                    true ? item?.lastTradedPrice : item?.lastTradedPrice
                  }
                  exchange_timestamp={item?.timeStamp}
                  stockTag={item?.tag}
                  targetAt={item?.targetAt}
                  topLossAt={item?.topLossAt}
                  setCurrenChart={setCurrenChart}
                  setUpdateOrder={setUpdateOrder}
                  updateOrder={updateOrder}
                  openModel={openModel}
                  setOpenModel={setOpenModel}
                  setPlaceOrderData={setPlaceOrderData}
                  markerData={{
                    timeStamp: +item?.timeStamp,
                    price: +item?.entryPrice,
                    tag: item?.tag,
                  }}
                  editOrderData={editOrderData}
                  setEditOrderData={setEditOrderData}
                  setOrderCurrentChart={setOrderCurrentChart}
                  setTargetOrTopLoss={setTargetOrTopLoss}
                  activeChart={active}
                  isStopLossHit={
                    item?.topLossAt?.completed ||
                    item?.targetAt?.completed ||
                    false
                  }
                  socket={props?.socket}
                  stockData={chartData}
                  chartLiveData={chartLiveData?.tick}
                  live={live}
                  DivideHeight={getGrid()?.height}
                  setDrawingChart={setDrawingChart}
                  isMCXMarket={item?.exchange_type === "MCX"}
                  handleUpdateAnnotation={handleUpdateAnnotation}
                  openDrawingChartModal={openDrawingChartModal}
                />
                {getGrid()?.class === "hide-chart-header" && (
                  <HoverSection
                    chartLiveData={chartLiveData}
                    headMenu={headMenu}
                    setHeadMenu={setHeadMenu}
                    headerData={item?.item}
                    chartData={chartData}
                    live={live}
                    tags={item?.tag}
                    setTags={setTags}
                    allTags={Constants.allTags}
                    index={JSON.parse(item?.token)}
                    downloadsType={Constants.downloadsType}
                    currenChart={currenChart}
                    fromDate={props?.fromDate}
                    previousClosePrice={previousClosePrice}
                    removeStockTag={removeStockTag}
                    setAllTagsData={setAllTagsData}
                    socket={props?.socket}
                    handleUpdateStock={handleUpdateStock}
                  />
                )}
              </Index.Box>
            )}
            {graphType[theme][chartView] === 2 && (
              <Index.Box className="line-chart-body">
                <CustomDashedChartSingle
                  headerData={item?.item}
                  loopIndex={index}
                  isMarketOpen={true}
                  draw={true}
                  isOpenOrderChart={true}
                  liveData={chartLiveData?.tick || chartData[chartData?.length - 1]}
                  index={item?.token}
                  stockToken={JSON.parse(item?.token)}
                  currentPrice={
                    true ? item?.lastTradedPrice : item?.lastTradedPrice
                  }
                  exchange_timestamp={item?.timeStamp}
                  stockTag={item?.tag}
                  targetAt={item?.targetAt}
                  topLossAt={item?.topLossAt}
                  setCurrenChart={setCurrenChart}
                  setUpdateOrder={setUpdateOrder}
                  updateOrder={updateOrder}
                  openModel={openModel}
                  setOpenModel={setOpenModel}
                  setPlaceOrderData={setPlaceOrderData}
                  markerData={{
                    timeStamp: +item?.timeStamp,
                    price: +item?.entryPrice,
                    tag: item?.tag,
                  }}
                  editOrderData={editOrderData}
                  setEditOrderData={setEditOrderData}
                  setOrderCurrentChart={setOrderCurrentChart}
                  setTargetOrTopLoss={setTargetOrTopLoss}
                  activeChart={active}
                  isStopLossHit={
                    item?.topLossAt?.completed ||
                    item?.targetAt?.completed ||
                    false
                  }
                  socket={props?.socket}
                  stockData={chartData}
                  chartLiveData={chartLiveData?.tick}
                  live={live}
                  DivideHeight={getGrid()?.height}
                  setDrawingChart={setDrawingChart}
                  isMCXMarket={item?.exchange_type === "MCX"}
                  handleUpdateAnnotation={handleUpdateAnnotation}
                  openDrawingChartModal={openDrawingChartModal}
                />
                {getGrid()?.class === "hide-chart-header" && (
                  <HoverSection
                    chartLiveData={chartLiveData}
                    headMenu={headMenu}
                    setHeadMenu={setHeadMenu}
                    headerData={item?.item}
                    chartData={chartData}
                    live={live}
                    tags={item?.tag}
                    setTags={setTags}
                    allTags={Constants.allTags}
                    index={JSON.parse(item?.token)}
                    downloadsType={Constants.downloadsType}
                    currenChart={currenChart}
                    fromDate={props?.fromDate}
                    previousClosePrice={previousClosePrice}
                    removeStockTag={removeStockTag}
                    setAllTagsData={setAllTagsData}
                    socket={props?.socket}
                    handleUpdateStock={handleUpdateStock}
                  />
                )}
              </Index.Box>
            )}

            {graphType[theme][chartView] === 3 && (
              <Index.Box className="line-chart-body">
                <CustomCendalStickChartSingle
                  headerData={item?.item}
                  loopIndex={index}
                  isMarketOpen={true}
                  draw={true}
                  isOpenOrderChart={true}
                  liveData={chartLiveData?.tick || chartData[chartData?.length - 1]}
                  index={item?.token}
                  stockToken={JSON.parse(item?.token)}
                  currentPrice={
                    true ? item?.lastTradedPrice : item?.lastTradedPrice
                  }
                  exchange_timestamp={item?.timeStamp}
                  stockTag={item?.tag}
                  targetAt={item?.targetAt}
                  topLossAt={item?.topLossAt}
                  setCurrenChart={setCurrenChart}
                  setUpdateOrder={setUpdateOrder}
                  updateOrder={updateOrder}
                  openModel={openModel}
                  setOpenModel={setOpenModel}
                  setPlaceOrderData={setPlaceOrderData}
                  markerData={{
                    timeStamp: +item?.timeStamp,
                    price: +item?.entryPrice,
                    tag: item?.tag,
                  }}
                  editOrderData={editOrderData}
                  setEditOrderData={setEditOrderData}
                  setOrderCurrentChart={setOrderCurrentChart}
                  setTargetOrTopLoss={setTargetOrTopLoss}
                  activeChart={active}
                  isStopLossHit={
                    item?.topLossAt?.completed ||
                    item?.targetAt?.completed ||
                    false
                  }
                  socket={props?.socket}
                  stockData={chartData}
                  chartLiveData={chartLiveData?.tick}
                  live={live}
                  DivideHeight={getGrid()?.height}
                  setDrawingChart={setDrawingChart}
                  isMCXMarket={item?.exchange_type === "MCX"}
                  handleUpdateAnnotation={handleUpdateAnnotation}
                  openDrawingChartModal={openDrawingChartModal}
                />
                {getGrid()?.class === "hide-chart-header" && (
                  <HoverSection
                    chartLiveData={chartLiveData}
                    headMenu={headMenu}
                    setHeadMenu={setHeadMenu}
                    headerData={item?.item}
                    chartData={chartData}
                    live={live}
                    tags={item?.tag}
                    setTags={setTags}
                    allTags={Constants.allTags}
                    index={JSON.parse(item?.token)}
                    downloadsType={Constants.downloadsType}
                    currenChart={currenChart}
                    fromDate={props?.fromDate}
                    previousClosePrice={previousClosePrice}
                    removeStockTag={removeStockTag}
                    setAllTagsData={setAllTagsData}
                    socket={props?.socket}
                    handleUpdateStock={handleUpdateStock}
                  />
                )}
              </Index.Box>
            )}
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </Index.Grid>
  );
};

export default OneColumnTrackingWindow;

const HoverSection = (props) => {
  const dispatch = Index.useDispatch();
  const {
    headMenu,
    setHeadMenu,
    headerData,
    chartData,
    tags,
    setTags,
    index,
    downloadsType,
    chartLiveData,
    previousClosePrice,
    removeStockTag,
    setAllTagsData,
    handleUpdateStock,
  } = props;

  const currentPrice =
    chartData?.[chartData?.length - 1] && chartData?.[chartData?.length - 1][5];
  const {
    chartDuration,
    chartView,
    token: userToken,
    timeFrame,
    activeTrackingChartHover,
    gridView,
    displaySettings,
    selectedScript,
    userLoginDetail,
  } = Index.useSelector((state) => state.UserReducer);

  let isWeekendDay = [6, 7]?.includes(Index.moment().isoWeekday());
  let prevCloseDate = usePreviousDateCalculator(isWeekendDay ? 2 : 1);

  const lastDateData =
    chartData?.length > 0 &&
    chartData.filter(
      (data) =>
        Index.moment(new Date(data[0])).format("YYYY-MM-DD") ==
        Index.moment(prevCloseDate).format("YYYY-MM-DD")
    );

  const prevClosePrice =
    lastDateData.length > 0 ? lastDateData[lastDateData.length - 1][4] : null;

  let lastTradePrice =
    props?.live && chartLiveData
      ? chartLiveData?.tick[5]
      : chartData?.[0] && +chartData?.[chartData?.length - 1][4];

  let closePrice = props?.live
    ? chartLiveData
      ? chartLiveData?.tick[6]
      : previousClosePrice
    : prevClosePrice;

  let updatedDateAndTime =
    props?.live && chartLiveData
      ? Index.moment(+chartLiveData?.timestamp).format("DD-MM-YYYY HH:mm:ss")
      : chartData?.length &&
        Index.moment(new Date(+chartData?.[chartData?.length - 1][0]))
          .subtract(5, "hours")
          .subtract(30, "minutes")
          .format("DD-MM-YYYY HH:mm:ss");

  useEffect(() => {
    if (chartView === "Intraday") {
      lastTradePrice = chartLiveData?.tick[5];
      closePrice = chartLiveData?.tick[6];
      updatedDateAndTime = Index.moment(chartLiveData?.timestamp).format(
        "DD-MM-YYYY HH:mm:ss"
      );
    }
  }, [chartLiveData?.tick]);

  const changePointPercent = Math.abs(
    (((lastTradePrice - closePrice) / closePrice) * 100)?.toFixed(2)
  );

  const changePointValue = (lastTradePrice - closePrice)?.toFixed(2);
  const [downloadMenu, setDownloadMenu] = useState(false);
  const menuRef = useRef(null);
  // modal auto close code of download start
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setDownloadMenu(false);
      }
    };
    if (downloadMenu) {
      window.addEventListener("mousedown", handleOutsideClick);
    }
    return () => {
      window.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [downloadMenu]);
  // modal auto close code of download end

  async function updateTokenTags(t, tagName) {
    let formattedDate = Index.moment().startOf("minute").valueOf();
    try {
      await Index.DataService(userToken).post(
        `/user/stock/stockTagAddAndUpdate`,
        {
          tag: t,
          previousTag: tags,
          script: headerData?.script?.toString(),
          stock_name: headerData?.stock_name || headerData?.symbol,
          lastTradedPrice: currentPrice,
          target: 12,
          stopLoss: 12,
          entryPrice: lastTradePrice,
          // exitPrice: 3,
          token: index,
          tradeType: headerData?.tradeType,
          timeFrame: headerData?.timeFrame,
          timeStamp: setDatefun(formattedDate),
          chartView,
          positionalTimeFrame: headerData?.positionalTimeFrame,
          interval: headerData?.interval,
          intervalValue: headerData?.intervalValue,
        }
      );
    } catch (error) {}

    if (props?.socket) {
      props?.socket.emit("tagging_action", userLoginDetail?._id);
    }

    let payload = {
      token: headerData?.token,
      tag: tagName,
      lastTradedPrice: currentPrice,
      entryPrice: currentPrice,
      chartView: chartView,
      interval: headerData?.interval,
      timeStamp: setDatefun(formattedDate),
    }

    handleUpdateStock(payload)
  }

  // async function removeStockTag(t) {
  //   try {
  //     let data = {
  //       token: JSON.parse(index),
  //       tag: t,
  //       lastTradedPrice: lastTradePrice,
  //     };
  //     await Index.DataService(userToken).post(Index.Api.user.removeStock, data);
  //     // toast.success("Tag removed successfully");
  //   } catch (error) {
  //     // toast.success("Tag updated");
  //   }
  // }

  //Download chart
  const printImage = (ext) => {
    const filter = (node) => {
      const exclusionClasses = ["anychart-credits"];
      return !exclusionClasses.some((classname) =>
        node.classList?.contains(classname)
      );
    };

    const downloadId = `print-${JSON.parse(index)}-${headerData?._id}`;
    console.log(downloadId,"downloadId")

    if (ext?.toLowerCase() === "pdf") {
      const element = document.getElementById(downloadId);
      htmlToImage
        .toPng(element, {
          filter: filter,
        })
        .then((dataUrl) => {
          const doc = new jsPDF({
            orientation: "portrait",
            unit: "in",
            format: "a4",
          });
          const scaleFactor = doc.internal.pageSize.width / element.offsetWidth;
          const x =
            (doc.internal.pageSize.width - element.offsetWidth * scaleFactor) /
            2;
          const y =
            (doc.internal.pageSize.height -
              element.offsetHeight * scaleFactor) /
            2;
          doc.addImage(
            dataUrl,
            "PNG",
            x,
            y,
            element.offsetWidth * scaleFactor,
            element.offsetHeight * scaleFactor
          );

          doc.save(
            `${headerData?.stock_name || headerData?.name}-${JSON.parse(
              index
            )}.${ext?.toLowerCase()}`
          );
          dispatch(manageDownloadChartData([]));
        })
        .catch((error) => {
          dispatch(manageDownloadChartData([]));
          console.error("Error converting element to image:", error);
        });
    } else {
      let toImageFn;
      switch (ext?.toLowerCase()) {
        case "svg":
          toImageFn = htmlToImage.toSvg;
          break;
        case "png":
          toImageFn = htmlToImage.toPng;
          break;
        case "jpg":
          toImageFn = htmlToImage.toJpeg;
          break;
        default:
          console.error("Invalid file format");
          return;
      }

      let element = document.getElementById(downloadId);
      toImageFn(document.getElementById(downloadId), {
        filter: filter,
      })
        .then(function (dataUrl) {
          var link = document.createElement("a");
          link.download = `${
            headerData?.stock_name || headerData?.name
          }-${JSON.parse(index)}.${ext?.toLowerCase()}`;
          link.href = dataUrl;
          link.click();
          dispatch(manageDownloadChartData([]));
        })
        .catch((e) => {
          console.error(e, "DownloadChart error");
          dispatch(manageDownloadChartData([]));
        });
    }
  };

  const getColorClassByTag = () => {
    switch (tags.toLowerCase()) {
      case "buy":
        return "green-btn";
      case "sell":
        return "red-btn";
      case "exit":
        return "red-btn";
      case "watch":
        return "blue-btn";
      default:
        return "";
    }
  };

  return (
    <Index.Box
      // className="chart-hover-sec"
      className={`chart-hover-sec ${
        activeTrackingChartHover?.includes(headerData?.token) &&
        "active-chart-hover"
      }`}
    >
      <Index.Box className="watch-dot-btn-sec">
        <Index.Box className="dots-btn-sec">
          {tags && (
            <Index.Box className="buy-min-btn-sec">
              <Index.RedButton
                className={`buy-sell-btn ${
                  tags?.toLowerCase() === "buy"
                    ? "green-btn buy-btn"
                    : tags?.toLowerCase() === "sell" ||
                      tags?.toLowerCase() === "exit"
                    ? "red-btn buy-btn"
                    : tags?.toLowerCase() === "watch"
                    ? "blue-btn buy-btn"
                    : "blue-btn buy-btn"
                }`}
                btnLabel={tags}
              />
              {headerData?.timeFrame && (
                <Index.RedButton
                  className={`buy-btn min-btn ${getColorClassByTag()}`}
                  btnLabel={headerData?.timeFrame}
                />
              )}
            </Index.Box>
          )}
          <img
            className="white-dots-img"
            onClick={() => setHeadMenu(!headMenu)}
            alt="threeDotsWhite"
            src={Index.Svg.threeDotsWhite}
          />
          {headMenu && (
            <Index.Box
              className="head-menu"
              sx={{ display: "inherit !important" }}
            >
              <Index.List className="ul">
                {Constants.allTags
                  ?.filter((ele) => {
                    if (!tags) {
                      return !["exit", "reverse", "remove"]?.includes(
                        ele?.toLowerCase()
                      );
                    } else if (tags?.toLowerCase() === "exit") {
                      return !["watch", "reverse", "remove"]?.includes(
                        ele?.toLowerCase()
                      );
                    } else if (tags?.toLowerCase() === "watch") {
                      return !["exit", "reverse"]?.includes(ele?.toLowerCase());
                    } else if (tags?.toLowerCase() === "exit") {
                      return !["watch", "remove"]?.includes(ele?.toLowerCase());
                    } else if (tags?.toLowerCase() === "buy") {
                      return !["sell", "watch", "remove"]?.includes(
                        ele?.toLowerCase()
                      );
                    } else if (tags?.toLowerCase() === "sell") {
                      return !["buy", "watch", "remove"]?.includes(
                        ele?.toLowerCase()
                      );
                    }
                    return ele;
                  })
                  ?.map((e) => {
                    return (
                      <Index.ListItem
                        className={`li ${e === tags ? "active" : ""}`}
                        onClick={() => {
                          const removeData = {
                            token: JSON.parse(headerData?.token),
                            timeFrame : headerData?.positionalTimeFrame,
                            interval: headerData?.interval
                          }
                          if (e === "Reverse") {
                            setTags(tags === "Sell" ? "Buy" : "Sell");
                            updateTokenTags(
                              "Reverse",
                              tags === "Sell" ? "Buy" : "Sell"
                            );
                          } else if (e === "Remove") {
                            setTags("");
                            removeStockTag(
                              "Watch",
                              removeData
                            );
                          } else if (e === "Exit") {
                            setTags("");
                            removeStockTag(tags, removeData);
                          } else {
                            setTags(e);
                            updateTokenTags(e, e);
                          }
                          setHeadMenu((e) => !e);
                        }}
                      >
                        <Index.Typography
                          className="mint-text"
                          variant="p"
                          component="p"
                        >
                          {e}
                        </Index.Typography>
                      </Index.ListItem>
                    );
                  })}
              </Index.List>
            </Index.Box>
          )}
        </Index.Box>
      </Index.Box>
      <Index.Box className="hover-detail-sec">
        <Index.Box className="chart-header-left">
          <Index.Typography
            className="chart-title chart-text"
            variant="p"
            component="p"
            sx={{
              fontSize: `${20.5 - gridView?.column}px !important`,
            }}
          >
            {headerData?.stock_name || headerData?.symbol}
          </Index.Typography>

          <Index.Box className="d-flex">
            {isFinite(changePointValue) && (
              <>
                <Index.Box
                  sx={{
                    fontSize: `${
                      19 - gridView?.column
                    }px !important`,
                  }}
                  className={`chart-text ${
                    +closePrice > +lastTradePrice ? "red-color" : "green-color"
                  }`}
                >
                  ₹{lastTradePrice || closePrice}{" "}
                  <img
                    className="red-down-aroow"
                    alt="downRedArrow"
                    src={
                      +closePrice > +lastTradePrice
                        ? Index.Svg.downRedArrow
                        : Index.Svg.upGreenArrow
                    }
                  />
                </Index.Box>
                {/* <Index.Typography
                    sx={{
                      fontSize: `${
                        19 - gridView?.column
                      }px !important`,
                    }}
                    className={`chart-text ${
                      +closePrice > +lastTradePrice ? "red-color" : "green-color"
                    }`}
                    variant="p"
                    component="p"
                  >
                    {displaySettings?.change === "Rs" ? (
                      isFinite(changePointValue) ? (
                        changePointValue
                      ) : (
                        ""
                      )
                    ) : (
                      <span
                        sx={{
                          fontSize: `${
                            19 - gridView?.column
                          }px !important`,
                        }}
                        className={`${
                          +closePrice > +lastTradePrice
                            ? "red-color"
                            : "green-color"
                        }`}
                      >
                        {isFinite(changePointPercent)
                          ? `(${changePointPercent}%)`
                          : ""}
                      </span>
                    )}
                  </Index.Typography> */}
              </>
            )}
          </Index.Box>

          <Index.Box className="time-entry-sec">
            <Index.Box className="time-entry-left">
              <img className="calender-img" alt="" src={Index.Svg.calender} />
              <Index.Typography
                className="time-entry-text color-blue tracking-hover-text"
                variant="p"
                component="p"
              >
                {headerData?.timeFrame || ""}
              </Index.Typography>
              <Index.Typography
                className="time-entry-text tracking-hover-text"
                variant="p"
                component="p"
              >
                Entry:{" "}
                <span className="color-blue tracking-hover-text">
                  &nbsp;₹{headerData?.entryPrice}
                </span>
              </Index.Typography>
            </Index.Box>
          </Index.Box>
          {displaySettings?.LastUpdatedTime == "on" && (
            <Index.Box className="chart-header-time">
              <Index.Typography
                className="chart-text chart-updated-time-text"
                variant="p"
                component="p"
              >
                {updatedDateAndTime ? updatedDateAndTime : null}
              </Index.Typography>
            </Index.Box>
          )}
        </Index.Box>
        <Index.RoundButton
          className="round-btn download-btn hov-dow-btn mr-0"
          onClick={() => setDownloadMenu((prev) => !prev)}
          imgsrc={Index.Svg.download}
        />

        {/* <Index.Checkbox className="round-btn download-btn hov-dow-btn" /> */}
      </Index.Box>
      <Index.Box
        className={downloadMenu ? "head-menu active" : "head-menu"}
        ref={menuRef}
      >
        <Index.List className="ul">
          {downloadsType?.map((e, i) => {
            return (
              <Index.ListItem
                className={`li`}
                key={`0${i + 1}${e.type}`}
                onClick={() => {
                  dispatch(
                    manageDownloadChartData([JSON.parse(headerData?.token)])
                  );
                  // currenChart[e.saveAs]({
                  //   paperSize: "A4",
                  //   landscape: false,
                  //   filename: `My Chart - ${
                  //     headerData?.stock_name || headerData?.name
                  //   } ${e.type}`,
                  // });
                  setTimeout(() => {
                    printImage(e.type);
                  }, 500);
                  setDownloadMenu(false);
                }}
              >
                <Index.Typography
                  className={`mint-text`}
                  variant="p"
                  component="p"
                >
                  {e.type}
                </Index.Typography>
              </Index.ListItem>
            );
          })}
        </Index.List>
      </Index.Box>
    </Index.Box>
  );
};

import React, { memo, useState, useEffect, useCallback, useMemo } from "react";
import Index from "../../../Index";
import { useDispatch, useSelector } from "react-redux";

import { useNavigate } from "react-router-dom";
import { activeShortCut } from "../../../../redux/user/action";
import Constants from "../../../../component/common/constants";

const IndexStocksList = () => {
  const navigate = useNavigate();
  const dispatch = Index.useDispatch();
  const location = Index.useLocation();
  const stockDetails = location?.state?.row;
  const {
    displaySettings,
    rowsPerPage: listRowsPerPage,
    token: authToken,
    twoSideArrowZoom,
    sorting,
  } = Index.useSelector((state) => state.UserReducer);
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: listRowsPerPage,
  });
  const [search, setSearch] = useState("");
  const [selectedStocks, setSelectedStocks] = useState([]);
  const [contractList, setContractList] = useState([]);
  const [loader, setLoader] = useState(true);
  const [dataTableHeight, setDataTableHeight] = useState(0);

  const handleSelectStocks = (stockToken) => {
    setSelectedStocks((prev) => {
      if (prev?.some((item) => item === stockToken)) {
        return prev.filter((item) => item !== stockToken);
      } else {
        return [...prev, stockToken];
      }
    });
  };

  //handle search
  const handleSearch = (item) => {
    return search
      ? item?.name?.toLowerCase()?.includes(search?.toLowerCase())
      : item;
  };

  const row = useMemo(() => {
    return (
      contractList?.length &&
      contractList?.filter(handleSearch)?.map((item, index) => {
        return {
          id: index + 1,
          sNo: index + 1,
          name: item?.name,
          symbol: item?.symbol,
          exch_seg: item?.exch_seg,
          token: item?.token,
          formatted_ins_name: item?.formatted_ins_name,
        };
      })
    );
  }, [contractList, search]);

  const columns = [
    {
      field: "select",
      headerName: `${selectedStocks?.length} - ${parseInt(
        stockDetails?.stockCount
      )}`,
      width: 150,
      editable: false,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        const isSelected = params.row.isSelected;
        const token = params.row.token;
        const rowData = params.row;
        return (
          <Index.Box className="index-list-checkbox-main">
            <Index.Checkbox
              className={`list-checkbox ${
                selectedStocks?.length >= parseInt(stockDetails?.stockCount) &&
                !selectedStocks?.some(
                  (item) => JSON.parse(item) == JSON.parse(token)
                ) &&
                "checkbox-disabled"
              }`}
              checked={selectedStocks?.some(
                (item) => JSON.parse(item) == JSON.parse(token)
              )}
              disabled={
                selectedStocks?.length >= parseInt(stockDetails?.stockCount) &&
                !selectedStocks?.some(
                  (item) => JSON.parse(item) == JSON.parse(token)
                )
              }
              onChange={(e) => {
                handleSelectStocks(JSON.parse(rowData?.token));
              }}
            />
          </Index.Box>
        );
      },
    },
    {
      field: "sNo",
      headerName: "S.No",
      width: 120,
      sortable: false,
      filterable: false,
      hideable: false,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => <>{params.value}</>,
    },
    {
      field: "name",
      headerName: "Stock Name",
      width: 300,
      editable: false,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "symbol",
      headerName: "Stock Symbol",
      width: 300,
      editable: false,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "exch_seg",
      headerName: "Category",
      width: 200,
      editable: false,
      align: "center",
      headerAlign: "center",
    },
  ];

  //Get all contracts
  const addStocks = async (id, tokens) => {
    try {
      const response = await Index.DataService(authToken).post(
        Index.Api.admin.addIndexStocks,
        { id, tokens, category: stockDetails?.category }
      );
      // Index.toast.success(response.data.message);
      navigate("/user/index-management");
    } catch (error) {}
  };

  //Get single index
  const getSingleIndex = async () => {
    try {
      const response = await Index.DataService(authToken).get(
        Index.Api.admin.getSingleIndex,
        {
          params: {
            id: stockDetails?._id,
          },
        }
      );
      const tokens = response?.data?.data?.stocks?.map((item)=> JSON.parse(item?.token))
      setSelectedStocks(tokens);
    } catch (error) {}
  };

  useEffect(() => {
    if (stockDetails) {
      getSingleIndex();
    }
  }, [stockDetails]);

  //Get all contracts
  const getAllContracts = async () => {
    try {
      const {reset, order} = sorting[Constants.sortingPath[location?.pathname]];
      const response = await Index.DataService(authToken).get(
        Index.Api.admin.getAllContracts,
        {
          params: {
            exchange: stockDetails?.category,
            sortDate: reset ? reset : '',
            sort: order ? order : '',
          },
        }
      );
      setContractList(response.data.data);
      setLoader(false);
    } catch (error) {
      setLoader(false);
    }
  };

  useEffect(() => {
    getAllContracts();
  }, [sorting[Constants.sortingPath[location?.pathname]]]);

  useEffect(() => {
    setPaginationModel({
      page: 0,
      pageSize: listRowsPerPage,
    });
  }, [listRowsPerPage]);

  //Data table height
  let mainContentElement = document.querySelector(".main-content-sec");
  let topSecElement = document.querySelector(".stocks-list-heading-wrapper");

  useEffect(() => {
    if (mainContentElement && topSecElement) {
      const mainHeight = mainContentElement.clientHeight;
      const topSecHeight = topSecElement.clientHeight;
      setDataTableHeight(mainHeight - topSecHeight - 65);
    }
  }, [mainContentElement, topSecElement, twoSideArrowZoom]);
  return (
    <>
      <Index.Box className="dashboard-wrapper stocks-list-wrapper">
        <Index.Header></Index.Header>
        <Index.Overlay></Index.Overlay>
        <Index.Box
          className={`main-content-sec ${
            displaySettings?.UtilityBar == "left"
              ? "main-content-sec-right"
              : ""
          } ${twoSideArrowZoom && "hide-main-content-margin"}`}
        >
          <Index.Box className="stocks-list-heading-wrapper">
            <Index.Box className="list-heading-main index-list-heading-main">
              <Index.Typography className="list-heading-text">
                {stockDetails?.indexName}
              </Index.Typography>
            </Index.Box>
            <Index.Box className="d-flex stocks-list-flex">
              <Index.Box className="cus-input-grp add-index-input-main">
                <Index.Box className="form-group">
                  <Index.TextField
                    fullWidth
                    className="form-control"
                    placeholder="Search..."
                    onChange={(event) => {
                      const value = event.target.value;
                      setSearch(value);
                    }}
                    onBlur={(e) => {
                      dispatch(activeShortCut(true));
                    }}
                    onFocus={(e) => {
                      dispatch(activeShortCut(false));
                    }}
                  />
                </Index.Box>
              </Index.Box>
              {selectedStocks?.length > 0 && (
                <Index.PrimaryButton
                  className="primary-btn index-btn add-stocks"
                  btnLabel="Add Stocks"
                  onClick={() => addStocks(stockDetails?._id, selectedStocks)}
                />
              )}
            </Index.Box>
          </Index.Box>
          <Index.Box className="table-sec">
            {!loader ? (
              row && row.length > 0 ? (
                <Index.Box sx={{ width: "100%", height: dataTableHeight }}>
                  <Index.DataGrid
                    rows={row}
                    columns={columns}
                    initialState={{
                      pagination: {
                        paginationModel: {
                          pageSize: 10,
                        },
                      },
                    }}
                    pageSizeOptions={[1000]}
                    paginationModel={paginationModel}
                    onPaginationModelChange={setPaginationModel}
                    // getRowId={getRowId}
                    disableRowSelectionOnClick
                    // localeText={{
                    //   MuiTablePagination: {
                    //     labelDisplayedRows: ({ from, to, count, estimated }) => {
                    //       if (!estimated) {
                    //         return `${from} – ${to} of ${
                    //           count !== -1 ? count : `${to}`
                    //         }`;
                    //       }
                    //       return `${from} – ${to} of ${
                    //         count !== -1
                    //           ? count
                    //           : `${estimated > to ? estimated : to}`
                    //       }`;
                    //     },
                    //   },
                    // }}
                  />
                </Index.Box>
              ) : (
                <Index.Box
                  sx={{ textAlign: "center", padding: "20px" }}
                  className="text-data"
                >
                  No data found
                </Index.Box>
              )
            ) : (
              <Index.TableLoader />
            )}
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </>
  );
};

export default IndexStocksList;

/* eslint-disable react-hooks/rules-of-hooks */
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  GetGraphTypeAction,
  manageDisplaySettings,
  manageUserSetting,
  setChartDuration,
  setChartView,
  setTimeFrame,
  showDefaultSettingModal,
  showHeaderSearchBar,
} from "../../../redux/user/action";
import Index from "../../Index";
import useKeyboardShortcut from "./useKeyboardShortcut";

// function useKeyPress(keys, onPress, data, obj) {
function useKeyPress(shortcut, callback) {
  const { isActiveShortCut, isUserLogin, chartView, chartDuration } =
    useSelector((state) => state.UserReducer);
  const handleKeyDown = useCallback(
    (event) => {
      const keys = shortcut.split("+").map((key) => key.trim().toLowerCase());
      const pressedKeys = keys.filter(
        (key) => event.getModifierState(key) || event.key.toLowerCase() === key
      );
      if (pressedKeys.length === keys.length) {
        event.preventDefault();
        callback();
      }
    },
    [shortcut, callback]
  );

  useEffect(() => {
    if (isActiveShortCut && isUserLogin) {
      document.addEventListener("keydown", handleKeyDown);
      return () => {
        document.removeEventListener("keydown", handleKeyDown);
      };
    }
  }, [isActiveShortCut, isUserLogin, handleKeyDown]);
}

const UseKeyPressEvent = ({ children }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    isActiveShortCut,
    isUserLogin,
    chartView,
    timeFrame,
    openDefaultSettingModal,
    displaySettings,
    chartDuration,
    graphType,
    token,
    theme,
  } = useSelector((state) => state.UserReducer);

  const themeTitle = localStorage.getItem("default-theme");

  const handleManageSetting = (keyPath, value) =>{
    dispatch(manageUserSetting(token, keyPath, value));
  };

  const handleManageTimeFrameInterval = (payload) =>{
    dispatch(
      setChartDuration({
        ...chartDuration,
        [chartView]: payload,
      })
    );
    dispatch(manageUserSetting(token, `chartDuration.${chartView}`, payload));
  }

  const headerSearch = useCallback(() => {
    dispatch(showHeaderSearchBar());
  }, [dispatch]);

  const handleBackspacePressed = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const handleSpacePressed = useCallback(() => {
    navigate("/user/dashboard");
  }, [navigate]);

  const DefaultSettingModalOpen = useCallback(() => {
    dispatch(showDefaultSettingModal(!openDefaultSettingModal));
  }, [dispatch, openDefaultSettingModal]);

  const handleHideChartPriceRange = useCallback(() => {
    const value = displaySettings?.PriceScale === "on" ? "off" : "on"
    dispatch(
      manageDisplaySettings({
        ...displaySettings,
        PriceScale: value,
      })
    );
    handleManageSetting("displaySetting.PriceScale", value);
  }, [dispatch, displaySettings]);

  const handleHideChartTimeScale = useCallback(() => {
    const value = displaySettings?.TimeScale === "on" ? "off" : "on"
    dispatch(
      manageDisplaySettings({
        ...displaySettings,
        TimeScale: value,
      })
    );
    handleManageSetting("displaySetting.TimeScale", value);
  }, [dispatch, displaySettings]);

  const handleLineChart = useCallback(() => {
    dispatch(
      GetGraphTypeAction({
        ...graphType,
        [theme]: {...graphType[theme],[chartView]:0},
      })
    );
    handleManageSetting(`graphType.${theme}.${chartView}`, 0);
  }, [dispatch, chartView]);

  const handleAreaChart = useCallback(() => {
    dispatch(
      GetGraphTypeAction({
        ...graphType,
        [theme]: {...graphType[theme],[chartView]:1},
      })
    );
    handleManageSetting(`graphType.${theme}.${chartView}`, 1);
  }, [dispatch, chartView]);
  
  const handleDashedChart = useCallback(() => {
    dispatch(
      GetGraphTypeAction({
        ...graphType,
        [theme]: {...graphType[theme],[chartView]:2},
      })
    );
    handleManageSetting(`graphType.${theme}.${chartView}`, 2);
  }, [dispatch, chartView]);
  
  const handleCandlestickChart = useCallback(() => {
    dispatch(
      GetGraphTypeAction({
        ...graphType,
        [theme]: {...graphType[theme],[chartView]:3},
      })
    );
    handleManageSetting(`graphType.${theme}.${chartView}`, 3);
  }, [dispatch, chartView]);

  const positionalViewOpen = useCallback(() => {
    dispatch(setChartView("Positional"));
    handleManageSetting("chartView", "Positional");
  }, [dispatch]);
  
  const intradayViewOpen = useCallback(() => {
    dispatch(setChartView("Intraday"));
    handleManageSetting("chartView", "Intraday");
  }, [dispatch]);

  const handleOneMinInterval = useCallback(() => {
    if (chartView === "Intraday") {
      handleManageTimeFrameInterval({ interval: "ONE_MINUTE" })
    }
  }, [dispatch, chartView]);
  
  const handleTwoMinInterval = useCallback(() => {
    if (chartView === "Intraday") {
      handleManageTimeFrameInterval({ interval: "TWO_MINUTE" })
    }
  }, [dispatch, chartView]);

  const handleThreeMinInterval = useCallback(() => {
    if (chartView === "Intraday") {
      handleManageTimeFrameInterval({ interval: "THREE_MINUTE" })
    }
  }, [dispatch, chartView]);

  const handleFourMinInterval = useCallback(() => {
    if (chartView === "Intraday") {
      handleManageTimeFrameInterval({ interval: "FOUR_MINUTE" })
    }
  }, [dispatch, chartView]);
  
  const handleFiveMinInterval = useCallback(() => {
    if (chartView === "Intraday") {
      handleManageTimeFrameInterval({ interval: "FIVE_MINUTE" })
    }
  }, [dispatch, chartView]);
  
  const handleTenMinInterval = useCallback(() => {
    if (chartView === "Intraday") {
      handleManageTimeFrameInterval({ interval: "TEN_MINUTE" })
    }
  }, [dispatch, chartView]);

  const handleFifteenMinInterval = useCallback(() => {
    if (chartView === "Intraday") {
      handleManageTimeFrameInterval({ interval: "FIFTEEN_MINUTE" })
    }
  }, [dispatch, chartView]);
  
  const handleThirtyMinInterval = useCallback(() => {
    if (chartView === "Intraday") {;
      handleManageTimeFrameInterval({ interval: "THIRTY_MINUTE" })
    }
  }, [dispatch, chartView]);
  
  // Positional
  // 5 working day chart
  const handleFiveWorkingDay = useCallback(() => {
    if (chartView === "Positional") {
      handleManageTimeFrameInterval({ timeFrame: 5, interval: "FIFTEEN_MINUTE" })
    }
  }, [dispatch, chartView]);
  
  // 15 working day chart
  const handleFifteenWorkingDay = useCallback(() => {
    if (chartView === "Positional") {
      handleManageTimeFrameInterval({ timeFrame: 15, interval: "THIRTY_MINUTE" })
    }
  }, [dispatch, chartView]);
  
  // 1 month chart
  const handleOneMonthChart = useCallback(() => {
    if (chartView === "Positional") {
      handleManageTimeFrameInterval({ timeFrame: 30, interval: "FOUR_HOUR" })
    }
  }, [dispatch, chartView]);

  // 3 month chart
  const handleThreeMonthChart = useCallback(() => {
    if (chartView === "Positional") {
      handleManageTimeFrameInterval({ timeFrame: 90, interval: "FOUR_HOUR" })
    }
  }, [dispatch, chartView]);

  // 6 month chart
  const handleSixMonthChart = useCallback(() => {
    if (chartView === "Positional") {
      handleManageTimeFrameInterval({ timeFrame: 180, interval: "ONE_DAY" })
    }
  }, [dispatch, chartView]);
  
  // 1 year chart
  const handleOneYearChart = useCallback(() => {
    if (chartView === "Positional") {
      handleManageTimeFrameInterval({ timeFrame: 365, interval: "ONE_DAY" })
    }
  }, [dispatch, chartView]);

  // 2 year chart
  const handleTwoYearChart = useCallback(() => {
    if (chartView === "Positional") {
      handleManageTimeFrameInterval({ timeFrame: 730, interval: "ONE_WEEK" })
    }
  }, [dispatch, chartView]);

  // 5 year chart
  const handleFiveYearChart = useCallback(() => {
    if (chartView === "Positional") {
      dispatch(
        setChartDuration({
          ...chartDuration,
          [chartView]: { timeFrame: 1825, interval: "ONE_DAY" },
        })
      );
      // handleManageTimeFrameInterval({ timeFrame: 1825, interval: "ONE_DAY" })
    }
  }, [dispatch, chartView]);
  
  //5 Minute
  const handleFiveMinute = useCallback(
    (day, interval) => {
      if (chartView === "Positional" && day) {
        handleManageTimeFrameInterval({ timeFrame: day, interval })
      }
    },
    [dispatch, chartView, timeFrame]
  );

  //15 Minute
  const handleFifteenMinute = useCallback(
    (day, interval) => {
      if (chartView === "Positional" && day) {
        handleManageTimeFrameInterval({ timeFrame: day, interval })
      }
    },
    [dispatch, chartView, timeFrame]
  );
  
  //30 Minute
  const handleThirtyMinute = useCallback(
    (day, interval) => {
      if (chartView === "Positional" && day) {
        handleManageTimeFrameInterval({ timeFrame: day, interval })
      }
    },
    [dispatch, chartView, timeFrame]
  );

  //1 Hour
  const handleOneHour = useCallback(
    (day, interval) => {
      if (chartView === "Positional" && day) {
        handleManageTimeFrameInterval({ timeFrame: day, interval })
      }
    },
    [dispatch, chartView, timeFrame]
  );
  
  //4 Hour
  const handleFourHour = useCallback(
    (day, interval) => {
      if (chartView === "Positional" && day) {
        handleManageTimeFrameInterval({ timeFrame: day, interval })
      }
    },
    [dispatch, chartView, timeFrame]
  );

  //1 Day
  const handleOneDay = useCallback(
    (day, interval) => {
      if (chartView === "Positional" && day) {
        handleManageTimeFrameInterval({ timeFrame: day, interval })
      }
    },
    [dispatch, chartView, timeFrame]
  );

  //2 Day
  const handleTwoDay = useCallback(
    (day, interval) => {
      if (chartView === "Positional" && day) {
        handleManageTimeFrameInterval({ timeFrame: day, interval })
      }
    },
    [dispatch, chartView, timeFrame]
  );

  //1 Day
  const handleThreeDay = useCallback(
    (day, interval) => {
      if (chartView === "Positional" && day) {
        handleManageTimeFrameInterval({ timeFrame: day, interval })
      }
    },
    [dispatch, chartView, timeFrame]
  );
  
  //1 Week
  const handleOneWeek = useCallback(
    (day, interval) => {
      if (chartView === "Positional" && day) {
        handleManageTimeFrameInterval({ timeFrame: day, interval })
      }
    },
    [dispatch, chartView, timeFrame]
  );
  
  //2 Week
  const handleTwoWeek = useCallback(
    (day, interval) => {
      if (chartView === "Positional" && day) {
        handleManageTimeFrameInterval({ timeFrame: day, interval })
      }
    },
    [dispatch, chartView, timeFrame]
  );
  
  //3 Week
  const handleThreeWeek = useCallback(
    (day, interval) => {
      if (chartView === "Positional" && day) {
        handleManageTimeFrameInterval({ timeFrame: day, interval })
      }
    },
    [dispatch, chartView, timeFrame]
  );

  const { handleKeyPress, handleKeyUp } = useKeyboardShortcut({
    headerSearch,
    handleBackspacePressed,
    handleSpacePressed,
    DefaultSettingModalOpen,
    handleLineChart,
    handleAreaChart,
    handleDashedChart,
    handleCandlestickChart,
    positionalViewOpen,
    intradayViewOpen,
    handleOneMinInterval,
    handleTwoMinInterval,
    handleThreeMinInterval,
    handleFourMinInterval,
    handleFiveMinInterval,
    handleTenMinInterval,
    handleFifteenMinInterval,
    handleThirtyMinInterval,
    handleFiveMinute,
    handleFifteenMinute,
    handleThirtyMinute,
    handleOneHour,
    handleFourHour,
    handleOneDay,
    handleTwoDay,
    handleThreeDay,
    handleOneWeek,
    handleTwoWeek,
    handleThreeWeek,
    handleFiveWorkingDay,
    handleFifteenWorkingDay,
    handleOneMonthChart,
    handleThreeMonthChart,
    handleSixMonthChart,
    handleOneYearChart,
    handleTwoYearChart,
    handleFiveYearChart,
    handleHideChartPriceRange,
    handleHideChartTimeScale,
  });

  return <>{children}</>;
};

export default UseKeyPressEvent;

//   const { children } = props;
//   const { openDefaultSettingModal} = useSelector((state) => state.UserReducer);
//   const timeFrame = useSelector((state) => state.UserReducer.timeFrame);
// console.log(timeFrame,"sadfnasfjtimeFrame")
//   const dispatch = useDispatch();
//   const navigate = Index.useNavigate();

//   const { getAllShortcuts, chartView } = Index.useSelector(
//     (state) => state.UserReducer
//   );

//   // const onPressSingle = () => {
//   // };
//   const headerSearch = () => {
//     // document.getElementById("fullWidth-searchbar").disabled = false;
//     dispatch(showHeaderSearchBar());
//   };
//   const handleSpacePressed = () => {
//     navigate("/user/dashboard");
//   };
//   const DefaultSettingModalOpen = () => {
//     dispatch(showDefaultSettingModal(!openDefaultSettingModal));
//   };
//   const handleLineChart = () => {
//     dispatch(GetGraphTypeAction(0));
//   };
//   const handleAreaChart = () => {
//     dispatch(GetGraphTypeAction(1));
//   };
//   const handleDashedChart = () => {
//     dispatch(GetGraphTypeAction(2));
//   };
//   const handleCandlestickChart = () => {
//     dispatch(GetGraphTypeAction(3));
//   };

//   const positionalViewOpen = () => {
//     dispatch(setChartDuration("FIFTEEN_MINUTE"));
//     dispatch(setTimeFrame({ day: 5, interval: "FIFTEEN_MINUTE" }));
//     dispatch(setChartView("Positional"));
//   };
//   const intradayViewOpen = () => {
//     dispatch(setChartDuration("FIVE_MINUTE"));
//     dispatch(setChartView("Intraday"));
//   };

//   const oneMinInterval = () => {
//     if (chartView === "Intraday") {
//       dispatch(setChartDuration("ONE_MINUTE"));
//     }
//   };
//   const fiveMinInterval = () => {
//     if (chartView === "Intraday") {
//       dispatch(setChartDuration("FIVE_MINUTE"));
//     }
//   };
//   const fifteenMinInterval = () => {
//     if (chartView == "Intraday") {
//       dispatch(setChartDuration("FIFTEEN_MINUTE"));
//     }
//   };

//   //Positional
//   //5 working day chart >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
//   const handleFiveWorkingDay = () => {
//     if (chartView === "Positional") {
//       dispatch(setTimeFrame({ day: 5, interval: "FIVE_MINUTE" }));
//       dispatch(setChartDuration("FIVE_MINUTE"));
//     }
//   };

//   //15 working day chart
//   const handleFifteenWorkingDay = () => {
//     if (chartView === "Positional") {
//       dispatch(setTimeFrame({ day: 15, interval: "FIFTEEN_MINUTE" }));
//       dispatch(setChartDuration("FIFTEEN_MINUTE"));
//     }
//   };
//   //1 month chart
//   const handleOneMonthChart = () => {
//     if (chartView === "Positional") {
//       dispatch(setTimeFrame({ day: 30, interval: "THIRTY_MINUTE" }));
//       dispatch(setChartDuration("THIRTY_MINUTE"));
//     }
//   };
//   //3 month chart
//   const handleThreeMonthChart = () => {
//     if (chartView === "Positional") {
//       dispatch(setTimeFrame({ day: 90, interval: "THIRTY_MINUTE" }));
//       dispatch(setChartDuration("THIRTY_MINUTE"));
//     }
//   };

//   //6 month chart
//   const handleSixMonthChart = () => {
//     if (chartView === "Positional") {
//       dispatch(setTimeFrame({ day: 180, interval: "ONE_HOUR" }));
//       dispatch(setChartDuration("ONE_HOUR"));
//     }
//   };

//   //1 year chart
//   const handleOneYearChart = () => {
//     if (chartView === "Positional") {
//       dispatch(setTimeFrame({ day: 365, interval: "ONE_HOUR" }));
//       dispatch(setChartDuration("ONE_HOUR"));
//     }
//   };

//   //2 year chart
//   const handleTwoYearChart = () => {
//     if (chartView === "Positional") {
//       dispatch(setTimeFrame({ day: 730, interval: "ONE_HOUR" }));
//       dispatch(setChartDuration("ONE_HOUR"));
//     }
//   };

//   //5 year chart
//   const handleFiveYearChart = () => {
//     if (chartView === "Positional") {
//       dispatch(setTimeFrame({ day: 1825, interval: "ONE_HOUR" }));
//       dispatch(setChartDuration("ONE_HOUR"));
//     }
//   };

//   //5 min
//   const handleFiveMinute = () => {
//       if (chartView === "Positional" && timeFrame?.day == 5 ) {
//         dispatch(setTimeFrame({ day: 5, interval: "FIVE_MINUTE" }));
//         dispatch(setChartDuration("FIVE_MINUTE"));
//       }
//   };
//   //15 min
//   const handleFifteenMinute = () => {
//     if (chartView === "Positional") {
//       dispatch(setTimeFrame({ day: 5, interval: "FIFTEEN_MINUTE" }));
//       dispatch(setChartDuration("FIFTEEN_MINUTE"));
//     }
//   };

//   //30 min
//   const handleThirtyMinute = () => {
//     if (chartView === "Positional") {
//       dispatch(setTimeFrame({ day: 5, interval: "THIRTY_MINUTE" }));
//       dispatch(setChartDuration("THIRTY_MINUTE"));
//     }
//   };

//   // const fiveWorkingDayPositionalChart = () => {
//   //   dispatch(setTimeFrame(5));
//   // };
//   // const oneMonthPositionalChart = () => {
//   //   dispatch(setTimeFrame(30));
//   // };
//   // const threeMonthPositionalChart = () => {
//   //   dispatch(setTimeFrame(90));
//   // };
//   // const sixMonthPositionalChart = () => {
//   //   dispatch(setTimeFrame(180));
//   // };
//   // const oneyearMonthPositionalChart = () => {
//   //   dispatch(setTimeFrame(365));
//   // };

//   // Definbe ShortCurts Keys

//   // getAllShortcuts.forEach( (item) =>{
//   //     return (
//   //       UseKeyPress("s",item?.fixedName?.replace(/'/g, ""))
//   //     )
//   //   })
//   // var search = getAllShortcuts[12]?.fixedName?.replace(/'/g, "")

//   // getAllShortcuts.map((item,index) => {
//   //   useKeyPress("s", headerSearch);
//   // })

//   const obj = {
//     // onpressSingle: getAllShortcuts,
//     SearchAnyScript: headerSearch,
//     DefaultSettingModalOpen: DefaultSettingModalOpen,
//     handleLineChart,
//     handleAreaChart,
//     handleDashedChart,
//     handleCandlestickChart,
//   };

//   // for (let i = 0; i < getAllShortcuts?.length; i++) {
//   // useKeyPress(getAllShortcuts[i].shortCutKeys.join(), obj[getAllShortcuts[i].fixedName]);
//   // useKeyPress(getAllShortcuts,obj)
//   // }

//   // useKeyPress("m", onPressSingle);
//   useKeyPress("s", headerSearch, getAllShortcuts, obj);
//   useKeyPress(" ", handleSpacePressed);
//   // useKeyPress("h d", DefaultSettingModalOpen);
//   useKeyPress("l", handleLineChart);
//   useKeyPress("a", handleAreaChart);
//   useKeyPress("d", handleDashedChart);
//   useKeyPress("c", handleCandlestickChart);
//   useKeyPress("p", positionalViewOpen);
//   useKeyPress("i", intradayViewOpen);

//   // if (chartView === "Intraday") {
//   useKeyPress("1", oneMinInterval);
//   useKeyPress("2", fiveMinInterval);
//   useKeyPress("3", fifteenMinInterval);
//   // } else if (chartView === "Positional") {
//   useKeyPress("1", handleFiveWorkingDay);
//   useKeyPress("2", handleFifteenWorkingDay);
//   useKeyPress("3", handleOneMonthChart);
//   useKeyPress("4", handleThreeMonthChart);
//   useKeyPress("5", handleSixMonthChart);
//   useKeyPress("6", handleOneYearChart);
//   useKeyPress("7", handleTwoYearChart);
//   useKeyPress("8", handleFiveYearChart);

//   useKeyPress("Alt+1", handleFiveMinute);
//   useKeyPress("Alt+2", handleFifteenMinute);
//   useKeyPress("Alt+3", handleThirtyMinute);
//   // }

//   // useKeyPress("p 5", fiveWorkingDayPositionalChart);
//   // useKeyPress("p m", oneMonthPositionalChart);
//   // useKeyPress("p q", threeMonthPositionalChart);
//   // useKeyPress("p h", sixMonthPositionalChart);
//   // useKeyPress("p y", oneyearMonthPositionalChart);

//   return <>{children}</>;

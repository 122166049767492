import React, { useState } from "react";
import Draggable from "react-draggable";
import Index from "../Index";
import Modal from "./Modal";
import DrawChartModal from "./DrawChartModal";
import DrawingToolBox from "./DrawingToolBox";
import { manageDrawingToolActiveDeactive } from "../../redux/user/action";

const DrawChartButton = ({ newChart, token, setNewChart, interval, isDrawingToolOpen, isModalTool }) => {
  const dispatch = Index.useDispatch()
  const [isActive, SetIsActive] = useState(true);
  const [isSliderActive, setIsSliderActive] = useState(true);
  const [opacity, setOpacity] = useState(1);

  const handleClose = () => {
    // SetIsActive(true);
    // setShow(!show);
  };

  const handleOverlayClick = (e) => {
    if (e.target === e.currentTarget) {
      handleClose();
    }
  };
  return (
    <>
      <div
        style={{
          zIndex: 9999,
        }}
        onClick={handleOverlayClick}
      >
        <Draggable defaultPosition={{ x: 10, y: 100 }} cancel=".test">
          <Index.Box className="handle-draw-chart-main">
            <Index.Box className={``} sx={{ display: "flex" }}>
              <Index.Box
                className="draw-chart-menu-wrapper"
                // onClick={() => SetIsActive(!isActive)}
                onClick={() => {
                  let payload = {}
                  if(isModalTool){
                    payload['type'] = 'Modal'
                  }
                  dispatch(manageDrawingToolActiveDeactive(payload))
                }}
              >
                {/* <Index.Box className="draw-chart-menu-wrapper-inner"></Index.Box> */}
                <Index.Box className="model-header-left cus-vertical-center">
                  <img
                    className="setting-ic tool-icon"
                    alt="settingsolid"
                    width={20}
                    src={Index.Svg.toolBox}
                  />
                </Index.Box>
              </Index.Box>
              {isDrawingToolOpen && (
                <Index.Box className={``} sx={{ marginLeft: "20px" }}>
                  <DrawingToolBox
                    opacity={opacity}
                    setOpacity={setOpacity}
                    newChart={newChart}
                    token={token}
                    setNewChart={setNewChart}
                    interval={interval}
                  />
                </Index.Box>
              )}
            </Index.Box>
          </Index.Box>
        </Draggable>
      </div>
    </>
  );
};

export default DrawChartButton;

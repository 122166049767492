import React, { memo } from "react";
import AnyChart from "anychart-react";
import anychart from "anychart";
import Index from "../../Index";
import AutoWidthHeight from "../../common/AutoWidthHeight";
import moment from "moment";
import { useState } from "react";
import { useEffect } from "react";
import {
  manageChartDrawingData,
  manageChartInstance,
  manageDrawingChartToken,
} from "../../../redux/user/action";
import { useDispatch } from "react-redux";
import DrawChartButton from "../../modal/DrawChartButton";
import { DataService } from "../../../config/DataService";
import useCurrentInterval from "../../common/current-interval/useCurrentInterval";
import Constants from "../../common/constants";

var dataTableArea = {};
const CustomAreaChart = (props) => {
  // Const declaration region
  const themeTitle = localStorage.getItem("default-theme");
  const {
    token: userToken,
    angelListData,
    liveData,
    chartView,
    otherSettings,
    chartDrawingData,
    displaySettings,
    gridSpacing,
    gridBorder,
    isFlip,
    chartDuration,
    graphType,
    twoSideArrowZoom,
    drawingStockToken,
    theme,
  } = Index.useSelector((state) => state.UserReducer);
  const {
    index,
    DivideHeight,
    DivideWidth,
    shareData,
    borderRadiusClass,
    setCurrenChart,
    setDrawingChart,
    activeChart,
    chartLiveData,
    isMCXMarket,
    openDrawingChartModal,
    handleUpdateAnnotation,
  } = props;
  const { height, width } = AutoWidthHeight();
  
  const areaGraph = Index.useSelector(
    (store) => store.UserReducer.GraphStyle[theme].areaGraph
  );
  const dispatch = useDispatch();
  // State declaration region
  const [data1, setData1] = useState([]);
  const [date, setDate] = useState(moment().format("DD-MM-YYYY hh:mm A"));
  const [open, setOpen] = useState(0);
  const [high, setHigh] = useState(0);
  const [low, setLow] = useState(0);
  const [close, setClose] = useState(0);
  const [newChart, setNewChart] = useState(null);
  const [parentWidth, setParentWidth] = useState(null);
  const [currentInterval, nextInterval] = useCurrentInterval(
    +Constants.chartInterval[chartDuration[chartView]?.interval]?.value,
    chartLiveData,
    isMCXMarket
  );
  const [loading, setLoading] = useState(true);
  const [chartHeight, setChartHeight] = useState(null);

  // Hooks declaration region
  useEffect(() => {
    const chart = charts();
    setCurrenChart(chart);
  }, [
    data1,
    areaGraph,
    otherSettings?.[theme]?.crosshair?.color,
    otherSettings?.[theme]?.crosshair?.thickness,
    otherSettings?.[theme]?.crosshair?.lineType,
    otherSettings?.[theme]?.daySplitter,
    otherSettings?.[theme]?.scale,
    displaySettings?.TimeScale,
    displaySettings?.PriceScale,
    displaySettings?.PriceIndicator,
    displaySettings?.DaySplitter,
    document.getElementsByClassName("chart-header-main")?.[0]?.clientHeight,
    gridSpacing,
    gridBorder,
    isFlip,
    activeChart,
    graphType[theme][chartView],
    loading,
    twoSideArrowZoom,
  ]);

  useEffect(()=>{
    if(!openDrawingChartModal && drawingStockToken == index){
      charts()
      dispatch(manageDrawingChartToken(null))
    }
  },[openDrawingChartModal])

  useEffect(() => {
    // if (chartView === "Positional") {
    setData1(shareData);
    // }
  }, [angelListData, shareData]);
  useEffect(() => {
    if (chartView === "Intraday") {
      addPoint();
    }
  }, [chartLiveData?.tick]);

  useEffect(() => {
    if (shareData?.length > 0) {
      setTimeout(() => {
        setLoading(false);
      }, 500);
    } else {
      setLoading(true);
    }
  }, [shareData]);

  const addPoint = () => {
    // append row of random values to data set
    if (shareData?.length > 0 && chartLiveData?.tick) {
      dataTableArea[index].addData(shareData);
      let data = data1;
      const liveTimestamp = Index.moment(chartLiveData.tick[0])
        .subtract(5, "hours")
        .subtract(30, "minutes")
        .valueOf();

      if (liveTimestamp >= currentInterval && liveTimestamp < nextInterval) {
        data.pop();
        data.push([
          Index.moment(currentInterval)
            .add(5, "hours")
            .add(30, "minutes")
            .valueOf(),
          chartLiveData.tick[1],
          chartLiveData.tick[2],
          chartLiveData.tick[3],
          chartLiveData.tick[4],
          chartLiveData.tick[4],
        ]);
      } else {
        data?.push(chartLiveData.tick);
      }
      // data?.push(chartLiveData.tick);
      const t = chartLiveData.tick;
      // setDate(moment(t[0])?.format("DD-MM-YYYY hh:mm A"));
      // setOpen(t[1]);
      // setHigh(t[2]);
      // setLow(t[3]);
      // setClose(t[4]);
      setData1(data);
    }
  };

  // Function declaration region
  // Chart drawing function declaration start
  const charts = () => {
    const container = document.getElementById(`area_chart${index}`);
    container?.firstElementChild?.remove();

    var chart = anychart.stock();
    chart.top(13);
    chart.left(-1);
    chart.right(displaySettings?.PriceScale === "on" ? 60 : 0);
    dataTableArea[index] = anychart.data.table();
    dataTableArea[index].addData(data1);
    // create a line series and set the data
    let series2 = dataTableArea[index].mapAs({
      open: 1,
      high: 2,
      low: 3,
      close: 4,
      value: chartView === "Positional" ? 4 : 5,
    });
    var plot = chart.plot();
    plot.xAxis().enabled(displaySettings?.TimeScale === "on");

    if (props?.live) {
      plot
        .xAxis()
        .labels()
        .format((e) => {
          // console.log(e,"fasdfsadfsadf")
          return "";
        });
    }

    plot.yAxis().enabled(true);
    plot.annotations(true);

    plot.yScale().inverted(isFlip);

    var extraYAxis = plot.yAxis();
    extraYAxis.orientation("right");

    chart.plot(0).yAxis().enabled(true);
    chart
      .plot(0)
      .yAxis()
      .stroke(
        otherSettings?.[theme]?.scale?.color,
        otherSettings?.[theme]?.scale?.thickness,
        otherSettings?.[theme]?.scale?.lineType
      );
    chart
      .plot(0)
      .yAxis(0)
      .ticks()
      .stroke(otherSettings?.[theme]?.scale?.color, otherSettings?.[theme]?.scale?.thickness);

    theme == "Light"
      ? plot.xAxis().background("#fff")
      : theme == "Dark"
      ? plot.xAxis().background("#212121")
      : plot.xAxis().background("#283142");

    // var extraYAxis = plot.yAxis();
    // extraYAxis
    //   .labels()
    //   .position("right")
    //   .format(function () {
    //     if (this.value) {
    //       return this.value + " ddsd";
    //     }
    //   });
    // extraYAxis.orientation("right");

    // Enable mouse wheel zoom.
    var interactivity = chart.interactivity();
    interactivity.zoomOnMouseWheel(true);

    //Price Indicator
    if (props?.live && displaySettings?.PriceIndicator === "on") {
      let indicator = plot.priceIndicator();
      indicator.value("last-visible");
      indicator.stroke(areaGraph.color, 0.5, "4 2");
      indicator.label().background().fill(areaGraph.color);
      indicator.label().fontColor("inherit");
    }

    // create a line series
    var crosshair = chart.crosshair();
    crosshair.xLabel().format(function (e) {
      const date = Index.moment(e.tickValue)
        .subtract(5, "hours")
        .subtract(30, "minutes");
      return date.hour() === 0 && date.minute() === 0
        ? date.format("DD MMM 'YY")
        : date.format("ddd DD MMM 'YY   HH:mm");
      // return anychart.format.dateTime(e["tickValue"], "YYYY MMM dd hh:mm");
    });

    crosshair.yLabel().format(function (e) {
      return this?.tickValue ? Number(this?.tickValue?.toFixed(2)) : 0
    });
    
    crosshair.enabled(true);
    // crosshair.displayMode("float");
    if (theme == "Light") {
      crosshair.xStroke(
        otherSettings?.[theme]?.crosshair?.color || "#212121",
        otherSettings?.[theme]?.crosshair?.thickness || 1,
        otherSettings?.[theme]?.crosshair?.lineType || "4 3",
        "round"
      );
      crosshair.yStroke(
        otherSettings?.[theme]?.crosshair?.color || "#212121",
        otherSettings?.[theme]?.crosshair?.thickness || 1,
        otherSettings?.[theme]?.crosshair?.lineType || "4 3",
        "round"
      );
    } else {
      crosshair.xStroke(
        otherSettings?.[theme]?.crosshair?.color || "#ffffff",
        otherSettings?.[theme]?.crosshair?.thickness || 1,
        otherSettings?.[theme]?.crosshair?.lineType || "4 3",
        "round"
      );
      crosshair.yStroke(
        otherSettings?.[theme]?.crosshair?.color || "#ffffff",
        otherSettings?.[theme]?.crosshair?.thickness || 1,
        otherSettings?.[theme]?.crosshair?.lineType || "4 3",
        "round"
      );
    }
    // crosshair.xLabel().enabled(true);
    // crosshair.yLabel().enabled(!props?.live);

    var tooltip = chart.tooltip();
    tooltip.width(0);
    tooltip.height(0);
    tooltip.separator(false);
    // set chart padding
    chart.padding([0, 0, 0, 0]);
    // set chart background
    chart.background().fill({
      src: "",
      mode: "fit",
    });
    var background = chart.background();
    background.cornerType("round");
    background.fill(
      theme == "Light"
        ? "#fff"
        : theme == "Dark"
        ? "#212121"
        : "#283142"
    );
    plot.xGrid().enabled(false);
    plot.xGrid().stroke("#ffffff05");
    plot.yGrid().stroke("#ffffff05");
    theme == "Light"
      ? plot.xGrid().palette(["#fff"])
      : theme == "Dark"
      ? plot.xGrid().palette(["#212121"])
      : plot.xGrid().palette(["#283142"]);
    plot.yGrid().enabled(false);
    theme == "Light"
      ? plot.yGrid().palette(["#fff"])
      : theme == "Dark"
      ? plot.yGrid().palette(["#212121"])
      : plot.yGrid().palette(["#283142"]);
    chart.scroller().enabled(false);
    var lineSeries = plot.area(series2);
    lineSeries.stroke(function () {
      return {
        keys: [areaGraph.thicknessColor],
        thickness: areaGraph.thickness,
      };
    });
    lineSeries.fill({
      keys: [areaGraph.color],
      fill: 2,
    });

    let mainGridHeight = document.getElementsByClassName(
      "dashboard-chart-main"
    )?.[0]?.clientHeight;
    let singleStockMainHeight =
      document.getElementsByClassName("single-stock-view")?.[0]?.clientHeight;
    let chartHeaderHeight =
      document.getElementsByClassName("chart-header-main")?.[0]?.clientHeight;

    setChartHeight(
      (mainGridHeight -
        chartHeaderHeight * DivideHeight -
        (gridSpacing * 8 * (DivideHeight - 1) + 8) -
        (activeChart ? parseInt(gridBorder) * 4 : 0)) /
        DivideHeight
    );

    var stage = anychart.graphics.create(
      `area_chart${index}`,
      "100%",
      singleStockMainHeight
        ? singleStockMainHeight - chartHeaderHeight - 50
        : (mainGridHeight -
            chartHeaderHeight * DivideHeight -
            (gridSpacing * 8 * (DivideHeight - 1) + 8) -
            (activeChart ? parseInt(gridBorder) * 4 : 0)) /
            DivideHeight
    );

    let chartBodySize =
      document.getElementsByClassName("chart-body")?.[0]?.clientWidth;

    setParentWidth(chartBodySize);

    // var stage = anychart.graphics.create(
    //   `area_chart${index}`,
    //   "100%",
    //   (height - 242) / DivideHeight
    // );

    plot.legend(false);
    tooltip.format(function (e) {
      // save hovered date to variable
      if (data1 && e?.index && data1[e.index]) {
        // setDate(moment(data1[e.index][0]).format("DD-MM-YYYY hh:mm A"));
        // setOpen(data1[e.index][1]);
        // setHigh(data1[e.index][2]);
        // setLow(data1[e.index][3]);
        setClose(data1[e.index][4]);
        return chartView === "Positional"
          ? data1[e.index][1]
          : data1[e.index][5];
      }
    });

    chart.listen("click", function (e) {
      // props?.setDrawingChart && setDrawingChart((prev)=> prev !== chart && chart );
      props?.setDrawingChart && setDrawingChart({ chart, token: props?.token });
    });

    if(activeChart){
      props?.setDrawingChart && setDrawingChart({ chart, token: props?.token });
    }

    if (!loading) {
      chart.container(stage).draw();
      setNewChart(chart);
      drawAnnotationsWithData(chart);
      drawingAnnotationLogic(chart);
      handleDrawDaySplitter(plot, shareData);
      return chart;
    }
  };
  useEffect(() => {
    setCurrenChart(charts());
  }, [DivideHeight, data1, index]);

  const handleDrawDaySplitter = (plot, stockData) => {
    if (
      chartView === "Positional" &&
      shareData?.length &&
      displaySettings?.DaySplitter === "on"
    ) {
      const groupedData = stockData.reduce((acc, curr) => {
        const date = Index.moment(curr[0]).startOf("day").valueOf();
        if (!acc[date]) {
          acc[date] = [];
        }
        acc[date].push(curr[0]);
        return acc;
      }, {});

      const filteredData = Object.values(groupedData).map(
        (group) => group[group.length - 1]
      );

      filteredData?.forEach((item, index) => {
        var marker = plot.lineMarker(index);
        marker.axis(plot.xAxis());
        marker.value(item);
        marker.stroke({
          thickness: otherSettings?.[theme]?.daySplitter?.thickness,
          color: otherSettings?.[theme]?.daySplitter?.color,
          dash: otherSettings?.[theme]?.daySplitter?.lineType,
        });
      });
    }
  };

  const drawingAnnotationLogic = (chart) => {
    if (chart) {
      //<<<<<<<<<<<<<<<<<<< Annotation Unselect >>>>>>>>>>>>>>>>>
      chart.listen("annotationSelect", function (event) {
        let selectedAnnotation = event?.annotation;
      });

      // //<<<<<<<<<<<<<<<<<<< Annotation Unselect >>>>>>>>>>>>>>>>>
      // chart.listen("annotationUnselect", function (event) {
      //   var unselectAnnotation = event.annotation;

      // });

      //<<<<<<<<<<<<<<<<<<< Annotation Change >>>>>>>>>>>>>>>>>
      chart.listen("annotationChange", function (event) {
        let changeAnnotation = event.annotation;
      });

      //<<<<<<<<<<<<<<<<<<< Annotation Change Start >>>>>>>>>>>>>>>>>
      chart.listen("annotationChangeStart", function (event) {
        let changeStartAnnotation = event.annotation;
      });

      // //<<<<<<<<<<<<<<<<<<< Annotation Change Finish >>>>>>>>>>>>>>>>>
      chart.listen("annotationChangeFinish", function (event) {
        let finishAnnotationChange = event.annotation;

        let myData = chart.plot(0).annotations().toJson();
        let dataToStore = myData?.annotationsList;

        if (props?.token) {
          let annotationData = {
            token: JSON.parse(props?.token),
            data: dataToStore,
            chartType: chartView,
            interval: Constants.annotationTimeFrames[chartDuration[chartView]?.interval],
          };
          handleUpdateAnnotation(annotationData);
        }
      });

      // //<<<<<<<<<<<<<<<<<<< Annotation Drawing Finish >>>>>>>>>>>>>>>>>
      chart.listen("annotationDrawingFinish", function (event) {
        let finishAnnotationDrawing = event.annotation;

        let myData = chart.plot(0).annotations().toJson();
        let dataToStore = myData?.annotationsList;
        if (props?.token) {
          let annotationData = {
            token: +props?.token,
            data: dataToStore,
            chartType: chartView,
            interval: Constants.annotationTimeFrames[chartDuration[chartView]?.interval],
          };

          handleUpdateAnnotation(annotationData);
        }
      });
    }
  };
  //Draw saved data
  function drawAnnotationsWithData(chart) {
    if (chart) {
      let savedData;

      if (chartDrawingData?.length > 0) {
        savedData = chartDrawingData?.find(
          (item) => item.token == +props?.token
        );
      }

      const controller = chart.plot(0).annotations();

      if (
        savedData &&
        savedData?.data[Constants.annotationTimeFrames[chartDuration[chartView]?.interval]]
          ?.length > 0
      ) {
        savedData?.data[
          Constants.annotationTimeFrames[chartDuration[chartView]?.interval]
        ]?.forEach((annotationData) => {
          if (annotationData?.type == "ellipse") {
            controller.ellipse(annotationData);
          }
          if (annotationData?.type == "triangle") {
            controller.triangle(annotationData);
          }
          if (annotationData?.type == "trend-channel") {
            controller.trendChannel(annotationData);
          }
          if (annotationData?.type == "line") {
            controller.line(annotationData);
          }
          if (annotationData?.type == "andrews-pitchfork") {
            controller.andrewsPitchfork(annotationData);
          }
          if (annotationData?.type == "horizontal-line") {
            controller.horizontalLine(annotationData);
          }
          if (annotationData?.type == "fibonacci-fan") {
            controller.fibonacciFan(annotationData);
          }
          if (annotationData?.type == "vertical-line") {
            controller.verticalLine(annotationData);
          }
          if (annotationData?.type == "fibonacci-arc") {
            controller.fibonacciArc(annotationData);
          }
          if (annotationData?.type == "infinite-line") {
            controller.infiniteLine(annotationData);
          }
          if (annotationData?.type == "fibonacci-retracement") {
            controller.fibonacciRetracement(annotationData);
          }
          if (annotationData?.type == "ray") {
            controller.ray(annotationData);
          }
          if (annotationData?.type == "fibonacci-timezones") {
            controller.fibonacciTimezones(annotationData);
          }
          if (annotationData?.type == "marker") {
            controller.marker(annotationData);
          }
          if (annotationData?.type == "rectangle") {
            controller.rectangle(annotationData);
          }
        });
      }
    }
  }
  return (
    <>
      {/* <DrawChartButton newChart={newChart} token={+props?.token} /> */}
      <Index.Box className="custom_area_chart">
        <Index.Stack direction="row" className="chart_ohcl">
          {/* <Index.Typography sx={{ fontSize: parentWidth * 0.03 }} variant="caption" color="#5A76B4">
            O : {open}; &nbsp;
          </Index.Typography>
          <Index.Typography sx={{ fontSize: parentWidth * 0.03 }} variant="caption" color="#00FF00">
            H : {high}; &nbsp;
          </Index.Typography>
          <Index.Typography sx={{ fontSize: parentWidth * 0.03 }} variant="caption" color="#FF0000">
            L : {low}; &nbsp;
          </Index.Typography> */}
          <Index.Typography sx={{ fontSize: parentWidth * 0.03 }} variant="caption" color="#5A76B4">
            C : {close}
          </Index.Typography>
        </Index.Stack>
        {/* <Index.Stack direction="row" className="chart_ohcl chart_date">
          <Index.Typography variant="caption" color="#42506F">
            Date : {date};
          </Index.Typography>
        </Index.Stack> */}
        {loading ? (
          <Index.Skeleton
            animation="wave"
            sx={{ bgcolor: "grey.1000" }}
            variant="rectangular"
            width={"100%"}
            height={chartHeight}
          />
        ) : (
          <AnyChart
            id={`area_chart${index}`}
            data={data1}
            className={`${borderRadiusClass}`}
          />
        )}
      </Index.Box>
    </>
  );
};

export default memo(CustomAreaChart);

import React, { useEffect, useRef, useState } from "react";
import Index from "../Index";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { activeShortCut } from "../../redux/user/action";

const setDatefun = (data) => {
  let d = new Date(data);
  let h = d.getHours() + 5;
  let m = d.getMinutes() + 30;
  d.setHours(h, m, 0, 0);
  return d.getTime();
};

const EditTargetLoss = (props) => {
  const dispatch = Index.useDispatch();
  const { token: userLoginToken, chartView, chartDuration } = Index.useSelector((state) => state.UserReducer);
  const formRef = useRef();
  // const [editOrderData, setEditOrderData] = useState({});
  const validationSchemaEditOrder = Yup.object().shape({
    target: Yup.string().when("isTarget", {
      is: true,
      then: () => Yup.string().required("Target is required"),
      otherwise: () => Yup.string(),
    }),
    topLoss: Yup.string().when("isTopLoss", {
      is: true,
      then: () => Yup.string().required("Toploss is required"),
      otherwise: () => Yup.string(),
    }),
  });
  let initialValues;

  if (Object.values(props?.editOrderData).length > 0) {
    initialValues = {
      target: props?.editOrderData?.targetAt?.price
        ? props?.editOrderData?.targetAt?.price
        : "",
      isTarget: props?.editOrderData?.targetAt ? true : false,
      topLoss: props?.editOrderData?.topLossAt?.price
        ? props?.editOrderData?.topLossAt?.price
        : "",
      isTopLoss: props?.editOrderData?.topLossAt ? true : false,
    };
  } else {
    initialValues = {
      target: "",
      isTarget: false,
      topLoss: "",
      isTopLoss: false,
    };
  }

  const handleSubmit = async (values) => {
    const urlencoded = new URLSearchParams();
    if (values?.target) {
      urlencoded.append("target", values?.target);
      let data = {
        targetTag: "targetAt",
        lastPrice: values?.target?.toString(),
        timeStamp: +setDatefun(+props?.exchange_timestamp),
        token: props?.token,
        position: props?.editOrderData?.targetAt?.position,
        color: props?.editOrderData?.targetAt?.color,
        id: props?.editOrderData?.targetAt?.id,
      };
      updateOrderCall(data);
      handleOrderUpdate(
        "targetAt",
        {
          color: data?.color,
          completed: false,
          position: data?.position,
          price: data?.lastPrice,
          timeStamp: data?.timeStamp,
          lineId: data?.id,
        },
        data?.token
      );
    }
    if (values?.topLoss) {
      urlencoded.append("topLoss", values?.topLoss);
      let data = {
        targetTag: "topLossAt",
        lastPrice: values?.topLoss?.toString(),
        timeStamp: +setDatefun(+props?.exchange_timestamp),
        token: props?.token,
        position: props?.editOrderData?.topLossAt?.position,
        color: props?.editOrderData?.topLossAt?.color,
        id: props?.editOrderData?.topLossAt?.id,
      };
      updateOrderCall(data);
      handleOrderUpdate(
        "topLossAt",
        {
          color: data?.color,
          completed: false,
          position: data?.position,
          price: data?.lastPrice,
          timeStamp: data?.timeStamp,
          lineId: data?.id,
        },
        data?.token
      );
    }

    props?.setShow && props?.setShow((e) => !e);
  };

  const updateOrderCall = async (data) => {
    try {
      const response = await Index.DataService(userLoginToken).post(
        "/user/stocks-tag-status",
        {
          targetTag: data?.targetTag,
          lastPrice: data?.lastPrice,
          timeStamp: +setDatefun(+props?.exchange_timestamp),
          token: data?.token,
          position: data?.position,
          color: data?.color,
          lineId: data?.id,
          chartView,
          timeFrame: chartDuration[chartView]?.timeFrame,
          interval: chartDuration[chartView]?.interval,

        }
      );

      if (props?.socket) {
        props?.socket.emit("stock_action", "Action taken");
      }
      // toast.success("status updated");

      if (response?.data?.status === 200) {
        const orderResponse = await Index.DataService(userLoginToken).get(
          `/user/get-stock-tag-details?token=${JSON.parse(props?.token)}`
        );
        props?.setUpdateOrder &&
          props?.setUpdateOrder({
            target: orderResponse.data.targetAt.price,
            stopLoss: orderResponse.data.topLossAt.price,
          });

        props?.setAllTagsData &&
          props?.setAllTagsData((prev) =>
            prev?.map((item) => {
              if (JSON.parse(item?.token) == JSON.parse(data?.token)) {
                return {
                  ...item,
                  [data?.targetTag]: orderResponse.data[data?.targetTag],
                };
              }
              return item;
            })
          );
      }
    } catch (error) {}
  };

  //handle Tag update
  const handleOrderUpdate = (status, data, token) => {
    props?.setAllTagsData &&
      props?.setAllTagsData((prev) =>
        prev?.map((item) => {
          if (JSON.parse(item?.token) == JSON.parse(token)) {
            return {
              ...item,
              [status]: data,
            };
          }
          return item;
        })
      );
  };

  const handleDeleteLine = async () => {
    try {
      const response = await Index.DataService(userLoginToken).post(
        "/user/stocks/remove-toploss-or-target",
        {
          token: JSON.parse(props?.token),
          targetTag:
            props?.editOrderData?.targetAt?.targetTag ||
            props?.editOrderData?.topLossAt?.targetTag,
          chartView,
          timeFrame: chartDuration[chartView]?.timeFrame,
          interval: chartDuration[chartView]?.interval,
        }
      );

      if (props?.socket) {
        props?.socket.emit("stock_action", "Action taken");
      }
      // toast.success(
      //   `${
      //     props?.editOrderData?.targetAt?.targetTag ||
      //     props?.editOrderData?.topLossAt?.targetTag
      //   } removed`
      // );
    } catch (error) {}
    props?.setShow && props?.setShow((e) => !e);

    props?.setAllTagsData &&
      props?.setAllTagsData((prev) =>
        prev?.map((item) => {
          if (JSON.parse(item?.token) == JSON.parse(props?.token)) {
            let obj = prev?.find(
              (token) => JSON.parse(token?.token) == JSON.parse(props?.token)
            );
            delete obj[props?.editOrderData?.targetTag];
            return obj;
          }
          return item;
        })
      );

    if (props?.orderCurrentChart) {
      let controller = props?.orderCurrentChart.plot(0).annotations();
      let selectedAnnotation = controller.getSelectedAnnotation();

      controller.removeAnnotation(selectedAnnotation);
    }
  };

  const getTagData = () => {
    Index.DataService(userLoginToken)
      .get(`/user/get-stock-tag-details?token=${JSON.parse(props?.token)}`)
      .then(({ data }) => {
        // setUpdateOrder({
        //   target: data.data.targetAt.price,
        //   stopLoss: data.data.topLossAt.price,
        // });
      });
  };

  useEffect(() => {
    getTagData();
  }, []);

  return (
    <>
      <Index.Box className="">
        <Formik
          validationSchema={validationSchemaEditOrder}
          initialValues={initialValues}
          onSubmit={handleSubmit}
        >
          {({
            values,
            errors,
            handleBlur,
            handleChange,
            touched,
            setFieldValue,
          }) => (
            <Form>
              <Index.Box className="edit-target-loss-wrapper">
                {props?.editOrderData?.targetAt && (
                  <Index.Box className="cus-input-grp">
                    <Index.Typography
                      className="cus-lable light-font"
                      variant="p"
                      component="p"
                    >
                      Target
                    </Index.Typography>
                    <Index.Box className="form-group-flex">
                      <Index.Box className="form-group">
                        <Index.TextField
                          fullWidth
                          name="target"
                          type="text"
                          className="form-control"
                          placeholder="Target"
                          autoComplete="off"
                          value={values?.target}
                          autoFocus
                          onChange={(e) => {
                            let value = e?.target?.value?.replace(
                              /[^\d.]/g,
                              ""
                            );
                            setFieldValue("target", value);
                          }}
                          // onBlur={handleBlur}
                          onBlur={(e) => {
                            handleBlur(e);
                            dispatch(activeShortCut(true));
                          }}
                          onFocus={(e) => {
                            dispatch(activeShortCut(false));
                          }}
                        />
                        <Index.FormHelperText error className="error-text">
                          {errors.target && touched.target
                            ? errors.target
                            : null}
                        </Index.FormHelperText>
                      </Index.Box>
                      {/* <Index.Box className="checkbox-form-group">
                        <Index.Checkbox
                          name="isTarget"
                          checked={values?.isTarget}
                          onChange={(e) => {
                            const value = e?.target?.checked;
                            setFieldValue("isTarget", value);
                          }}
                          className=""
                        />
                      </Index.Box> */}
                    </Index.Box>
                  </Index.Box>
                )}

                {props?.editOrderData?.topLossAt && (
                  <Index.Box className="cus-input-grp">
                    <Index.Typography
                      className="cus-lable light-font"
                      variant="p"
                      component="p"
                    >
                      Toploss
                    </Index.Typography>
                    <Index.Box className="form-group-flex">
                      <Index.Box className="form-group">
                        <Index.TextField
                          fullWidth
                          name="topLoss"
                          type="text"
                          className="form-control"
                          placeholder="Toploss"
                          autoComplete="off"
                          value={values?.topLoss}
                          autoFocus
                          onChange={(e) => {
                            let value = e?.target?.value?.replace(
                              /[^\d.]/g,
                              ""
                            );
                            setFieldValue("topLoss", value);
                          }}
                          // onBlur={handleBlur}
                          onBlur={(e) => {
                            handleBlur(e);
                            dispatch(activeShortCut(true));
                          }}
                          onFocus={(e) => {
                            dispatch(activeShortCut(false));
                          }}
                        />
                        <Index.FormHelperText error className="error-text">
                          {errors.topLoss && touched.topLoss
                            ? errors.topLoss
                            : null}
                        </Index.FormHelperText>
                      </Index.Box>
                      {/* <Index.Box className="checkbox-form-group">
                        <Index.Checkbox
                          className=""
                          name="isTopLoss"
                          checked={values?.isTopLoss}
                          onChange={(e) => {
                            const value = e?.target?.checked;
                            setFieldValue("isTopLoss", value);
                          }}
                        />
                      </Index.Box> */}
                    </Index.Box>
                  </Index.Box>
                )}
                <Index.Box className="target-loss-flex">
                  {/* <Index.PrimaryButton
                    className="primary-btn save-changes-btn"
                    btnLabel="Update"
                    type="submit"
                  /> */}
                  <Index.PrimaryButton
                    className="primary-btn save-changes-btn edit-order-delete-btn"
                    btnLabel={
                      <img
                        src={Index.Svg.deleteRed}
                        className="action-btn-img"
                      />
                    }
                    type="button"
                    onClick={handleDeleteLine}
                  />
                </Index.Box>
              </Index.Box>
            </Form>
          )}
        </Formik>
      </Index.Box>
    </>
  );
};

export default EditTargetLoss;

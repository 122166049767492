const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
const emailAuRegex =
  /^(?:(?!\.)(?![0-9])(?:\w+\.)*(?:\w+\+)*(?:\w+\!)*(?:\w+\#)*(?:\w+\$)*(?:\w+\&)*(?:\w+\%)*(?:\w+\-)*\w+@\w+\.\w{2,3}(?:\.[a-z]{2,3})?)$/;

// const emailRegex= /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
const passwordRegex =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#@$!%*?&])[A-Za-z\d@$#!%*?&]{8,}$/;
const onlyNumberRegex = /^[0-9]+$/;
const stateRegex = /^[A-Za-z]+[A-Za-z ]*$/;
const zipCodeRegex = /(^\d{6}$)|(^\d{9}$)/;
// login Validations

const loginValidations = (values) => {
  const error = {};
  //email validations
  if (!values.email) {
    error.email = "Please enter email";
  }
  // else if (!emailRegex.test(values.email)) {
  //   error.email = "Please enter email";
  // }
  else if (!emailAuRegex.test(values.email)) {
    error.email = "Please enter valid email";
  }
  //password validations
  if (!values.password) {
    error.password = "Please enter password";
  }
  // else if (!passwordRegex.test(values.password)) {
  //   error.password = "Please enter valid password";
  // } else if (values.password.length < 6) {
  //   error.password = "Password must be at least 6 characters";
  // }
  return error;
};

// forget Password Validations
const forgetPasswordValidations = (values) => {
  const error = {};
  // email validations
  if (!values.email) {
    error.email = "Please enter your email";
  }
  // else if (!emailRegex.test(values.email)) {
  //   error.email = "Please enter your valid email";
  // }
  else if (!emailAuRegex.test(values.email)) {
    error.email = "Please enter valid email";
  }
  return error;
};

// reset Password Validations

const resetPasswordValidations = (values) => {
  const error = {};
  //password validations
  if (!values.password) {
    error.password = "Please enter password";
  } else if (!passwordRegex.test(values.password)) {
    error.password = "Please enter valid password";
  } else if (values.password.length < 6) {
    error.password = "Password must be at least 6 characters";
  }
  //Confirm password
  if (!values.confirmPassword) {
    error.confirmPassword = "Please enter confirm password";
  } else if (!passwordRegex.test(values.password)) {
    error.password = "Please enter valid password";
  } else if (values.confirmPassword !== values.password) {
    error.confirmPassword = "Password and confirm password should be same";
  }

  return error;
};

// reset Password Validations

const createNewPasswordValidations = (values) => {
  const error = {};
  //old password validations
  if (!values.oldPassword) {
    error.oldPassword = "Please enter your old password";
  }
  //  else if (!passwordRegex.test(values.oldPassword)) {
  //   error.oldPassword = "Please enter valid old password";
  // } else if (values.oldPassword.length < 6) {
  //   error.oldPassword = "old password must be at least 6 characters";
  // }
  //new password validations
  if (!values.newPassword) {
    error.newPassword = "Please enter your new Password";
  } else if (!passwordRegex.test(values.newPassword)) {
    error.newPassword =
      "Must contain 8 characters, one uppercase, one lowercase, one number and one special case characters";
  } else if (values.newPassword.length < 8) {
    error.newPassword = "New Password must be at least 8 characters";
  }
  //Confirm password
  if (!values.confirmPassword) {
    error.confirmPassword = "Please enter your confirm password";
  }
  //  else if (!passwordRegex.test(values.confirmPassword)) {
  //   error.confirmPassword = "Please enter valid confirm password";
  // } else if (values.confirmPassword.length < 6) {
  //   error.confirmPassword = "Confirm Password must be at least 6 characters";
  // }
  else if (values.confirmPassword !== values.newPassword) {
    error.confirmPassword = "Confirm password should be same as password";
  }

  return error;
};

// profile Form Validations

const profileFormValidations = (values) => {
  const error = {};
  // fullname  validations
  if (!values.fullName) {
    error.fullName = "Please enter full name";
  }

  // email validations
  if (!values.email) {
    error.email = "Please enter email";
  } else if (!emailRegex.test(values.email)) {
    error.email = "Please enter valid email";
  }

  // Mobile number validations
  if (!values.mobileNumber) {
    error.mobileNumber = "Please enter mobile number";
  } else if (values.mobileNumber.length < 10) {
    error.mobileNumber = "Mobile number must be contained 10 digit";
  }
  // cit€y validations
  if (!values.city) {
    error.city = "Please enter city";
  }

  // state validations
  if (!values.state) {
    error.state = "Please enter state";
  } else if (!stateRegex.test(values.state)) {
    error.state = "Please enter valid state";
  }

  // zipCode validations
  if (!values.zipCode) {
    error.zipCode = "Please enter zip code";
  } else if (!zipCodeRegex.test(values.zipCode)) {
    error.zipCode = "Please enter valid zip code";
  }

  if (!values.profile) {
    error.profile = "Please upload image";
  } else if (values.profile instanceof File) {
    if (
      !["image/jpeg", "image/png", "image/jpg"].includes(values.profile.type)
    ) {
      error.profile = "Please upload only image file";
    }
  }

  return error;
};

const profileImageValidations = (value) => {
  let error = "";
  if (value instanceof File) {
    if (!["image/jpeg", "image/png", "image/jpg"].includes(value.type)) {
      error = "Please upload only image file";
    }
  }
  return error;
};

export {
  loginValidations,
  forgetPasswordValidations,
  resetPasswordValidations,
  createNewPasswordValidations,
  profileFormValidations,
  profileImageValidations,
};
